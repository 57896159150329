<template>
    <div>
        <div class="container">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Usuários</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item">Controle Permissões Perfil</li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4 col-lg-4" v-if="TipoPessoas != ''">
                                        <div class="form-group">
                                            <label>Tipo Pessoa</label>
                                            <select v-model="TpeId" class="form-select form-control" id="defaultSelect"
                                                @change="TipoPessoaSelected()">
                                                <option :value="null">Selecione</option>
                                                <option v-for="optionTipo in TipoPessoas" :key="optionTipo.tpeId"
                                                    :value="optionTipo.tpeId">{{ optionTipo.tpeNome }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="TipoUsuario != ''">
                                        <div class="form-group">
                                            <label>Perfil Usuário</label>
                                            <select v-model="TusId" class="form-select form-control" @change="PerfilUsuarioSelected()">
                                                <option v-for="optionTipo in TipoUsuario" :key="optionTipo.tusId"
                                                    :value="optionTipo.tusId">{{ optionTipo.tusNome }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="TpeId == 1">
                                        <div class="form-group">
                                            <label>Garantidora</label>
                                            <select v-model="PesId" class="form-select form-control" id="defaultSelect">
                                                <option value="">Selecione</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="TusId != null && TusId != ''">
                                        <div class="form-group">
                                            <label>Permissões</label>
                                            <div class="accordion" id="accordionExample">
                                                <div class="accordion-item" v-for="(grupo, index) in GrupoTelas"
                                                    :key="index">
                                                    <h2 class="accordion-header">
                                                        <!-- O nome do grupo permanece no topo -->
                                                        <button class="accordion-button" type="button"
                                                            @click="toggleGrupo(index)">
                                                            {{ grupo.grtNome }}
                                                        </button>
                                                    </h2>
                                                    <div v-show="activeIndex === index"
                                                        class="accordion-collapse collapse show">
                                                        <div class="accordion-body">
                                                            <!-- Checkbox "Marcar Todos" dentro da seção expandida -->
                                                            <div class="row">
                                                                <div>
                                                                <input type="checkbox" :id="'grupo-' + grupo.grtId + '-select-all'"
                                                                    :checked="isAllChecked(grupo.grtId)"
                                                                    @change="toggleAll(grupo.grtId, $event)">
                                                                <label :for="'grupo-' + grupo.grtId + '-select-all'" style="color:blue !important; padding-left: 5px;">Marcar Todos</label>
                                                            </div>
                                                            </div>

                                                            <!-- Lista de itens com checkboxes -->
                                                            <div class="row">
                                                                <div v-for="(item, i) in CadastroTelas.filter(x => x.admGrupoTelas.grtId === grupo.grtId)"
                                                                    :key="i">
                                                                    <input type="checkbox" :id="item.cdtId"
                                                                        :value="item.cdtId" v-model="selectedTelas">
                                                                    <label :for="item.cdtId" style="padding-left: 5px;">{{ item.cdtNome }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-action">
                    <button type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                        Salvar
                    </button>
                    <a href="#" @click="Get()" class="btn btn-link">Cancelar</a>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'

export default {
    name: 'ControlePermissoesPerfilView',
    components: {
        moment
    },
    data: () => ({
        PerfilUsuarios:[],
        TpeId: null,
        TusId: null,
        PesId: null,
        TipoUsuario: [],
        TipoPessoas: [],
        GrupoTelas: [],
        CadastroTelas: [],
        activeIndex: null, // Controla o grupo ativo
        selectedTelas: [],
    }),
    methods: {
        toggleGrupo(index) {
            // Alterna a exibição do grupo
            this.activeIndex = this.activeIndex === index ? null : index;
        },
        toggleAll(grtId, event) {
            const isChecked = event.target.checked;
            const grupoTelas = this.CadastroTelas.filter(x => x.admGrupoTelas.grtId === grtId);

            grupoTelas.forEach(item => {
                if (isChecked && !this.selectedTelas.includes(item.cdtId)) {
                    this.selectedTelas.push(item.cdtId);
                } else if (!isChecked) {
                    this.selectedTelas = this.selectedTelas.filter(id => id !== item.cdtId);
                }
            });
        },
        isAllChecked(grtId) {
            const grupoTelas = this.CadastroTelas.filter(x => x.admGrupoTelas.grtId === grtId);
            return grupoTelas.every(item => this.selectedTelas.includes(item.cdtId));
        },
        Add() {
            if (this.TusId == '' || this.TusId == null) {
                this.$mensagemAviso("Perfil Obrigatório.")
            }
            else {
                var _tpeId = this.TpeId == null ? 0 : this.TpeId;
                axios.delete(`/Process/UsrPerfilUsuarios/DeletebyPerfil/`  + _tpeId +'/' + this.TusId + "/0")
                    .then(response => {
                        if (response.status == 200 || response.status == 404) {
                            console.log('aqui');
                            let selectedPromise = Promise.all(this.selectedTelas.map(element => {
                                const Permissoes = {
                                    tusId: parseInt(this.TusId),
                                    cdtId: parseInt(element),
                                    garId: null,
                                    tpeId: this.TpeId
                                };
                                console.log(Permissoes)
                                return axios.post(`/Process/UsrPerfilUsuarios/Add`, Permissoes)
                                    .then(response => {
                                        if (response.data > 0) {
                                            return null;
                                        }
                                    });
                            }));

                            Promise.all([selectedPromise]).then(results => {
                                // Todas as chamadas assíncronas foram concluídas
                                this.$mensagemAviso("Permissões do usuário atualizadas.")
                            });
                        }
                    });
            }
        },
        TipoPessoaSelected() {
            this.TusId = null;
            this.TipoUsuario = this.TipoUsuarioGeral.filter(x => x.tpeId === this.TpeId);
        },
        async PerfilUsuarioSelected(){
            this.selectedTelas = [];
            var _tpeId = this.TpeId == null ? 0 : this.TpeId;
           await axios.get(`/Process/UsrPerfilUsuarios/GetByPerfil/` + _tpeId +'/' + this.TusId + "/0")
                  .then(response => {
                      this.PerfilUsuarios = response.data;
                      this.PerfilUsuarios.forEach(element => {
                        this.selectedTelas.push(element.cdtId)
                      });
                  });
        },
        async Get() {
            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
                console.log('this.User')
                console.log(this.User)
            }
            //geral
            axios.get(`/Process/AdmGrupoTelas/GetAll`)
                .then(response => {
                    this.GrupoTelas = response.data.filter(x => x.grtVisivel);
                    console.log('this.GrupoTelas')
                    console.log(this.GrupoTelas)
                });


            axios.get(`Process/AdmCadastroTelas/GetAll`)
                .then(response => {
                    this.CadastroTelas = response.data.filter(x => x.cdtVisivel && x.cdtId != 1 && x.cdtId != 2).sort((a, b) => {
                        const nomeA = a.cdtNome || "";
                        const nomeB = b.cdtNome || "";
                        return nomeA.localeCompare(nomeB);
                    });
                    console.log('this.CadastroTelas')
                    console.log(this.CadastroTelas)

                });

            axios.get(`/Process/UsrTipoPessoas/GetAll`)
                .then(response => {
                    this.TipoPessoas = response.data;
                });

            await axios.get(`/Process/AdmTipoUsuario/GetAll`)
                .then(response => {
                    this.TipoUsuarioGeral = response.data.filter(x => x.tusVisivel).sort((a, b) => {
                        const nomeA = a.tusNome || "";
                        const nomeB = b.tusNome || "";
                        return nomeA.localeCompare(nomeB);
                    });
                    this.TipoPessoaSelected();
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style>
.accordion-button {
    cursor: pointer;
}

.accordion-item {
    margin-bottom: 10px;
}
</style>
