<template>
    <div>
        <!--Edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Admin</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de formas de pagamento</a> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="FormaPagamento.cfpId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input v-model="FormaPagamento.cfpNome"
                                                type="text" class="form-control" placeholder="Nome" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Visivel</label>
                                            <select v-model="FormaPagamento.cfpVisivel" class="form-select form-control"
                                                id="defaultSelect">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Ativo</label>
                                            <select v-model="FormaPagamento.cfpAtivo" class="form-select form-control">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="TipoOperadoras != ''">
                                        <div class="form-group">
                                            <label>Tipo</label>
                                            <select  v-model="FormaPagamento.topId" class="form-select form-control">
                                                <option value="">Selecione</option>
                                                <option v-for="optionTipo in TipoOperadoras" :key="optionTipo.topId"
                                                    :value="optionTipo.topId">{{ optionTipo.topNome }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="FormaPagamento.cfpId != 0" type="button" class="btn btn-success"
                            style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()"  class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de formas de pagamento</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body" v-if="TipoOperadoras!=''">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="FormaPagamentos" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-cfpVisivel="item">
                                        <span>{{ item.cfpVisivel ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-cfpAtivo="item">
                                        <span>{{ item.cfpAtivo ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-topId="item">
                                        <span>{{ item.topId != null ? TipoOperadoras.filter(x=> x.topId == item.topId)[0].topNome : '' }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>

                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'CadastroFormaPagamentoView',
    components: {
        EasyDataTable
    },
    data: () => ({
        EditView: false,
        FormaPagamento: {
            cfpId: 0,
            cfpNome: '',
            cfpVisivel: '',
            cfpAtivo: '',
            topId: '',
        },
        FormaPagamentos: [],
        selectedVisivel: null,
        headers: [
            { text: "Id", value: "cfpId", sortable: true, },
            { text: "Nome", value: "cfpNome", sortable: true, },
            { text: "Visivel", value: "cfpVisivel", sortable: true, },
            { text: "Ativo", value: "cfpAtivo", sortable: true, },
            { text: "Tipo", value: "topId", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
        TipoOperadoras:[],
    }),
    methods: {
          //edit
          EditNew(){
            this.EditView = true;
            this.FormaPagamento.cfpId = 0;
            this.FormaPagamento.cfpNome= '';
            this.FormaPagamento.cfpVisivel= '';
            this.FormaPagamento.cfpAtivo= '';
            this.FormaPagamento.topId= '';
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.cfpId != ''){
            axios.get(`/Process/CadFormasPagamento/GetById/` + item.cfpId)
                .then(response => {
                    this.FormaPagamento = response.data;
                });
            }
        },
        Add() {
            if (this.FormaPagamento.cfpNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.FormaPagamento.cfpVisivel === '') {
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.FormaPagamento.cfpAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else if(this.FormaPagamento.topId === ''){
                this.$mensagemAviso("Operadora Obrigatório.")
            }
            else {
                axios.post(`/Process/CadFormasPagamento/Add`, this.FormaPagamento)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.FormaPagamento.cfpNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.FormaPagamento.cfpVisivel === '') {
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.FormaPagamento.cfpAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else if(this.FormaPagamento.topId === ''){
                this.$mensagemAviso("Operadora Obrigatório.")
            }
            else {
                console.log('this.FormaPagamento')
                console.log(this.FormaPagamento)
                axios.put(`/Process/CadFormasPagamento/Update`, this.FormaPagamento)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
          //edit
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cfpId;
                axios.delete(`/Process/CadFormasPagamento/Delete/${param1}`)
                .then(response => {
                    if (response.stacfp == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        Get() {
            this.EditView = false;
            axios.get(`/Process/CadFormasPagamento/GetAll`)
                .then(response => {
                    this.FormaPagamentos = response.data;
                });

            axios.get(`/Process/CadTipoOperadora/GetAll`)
                .then(response => {
                    this.TipoOperadoras = response.data;
                    console.log('TipoOperadoras')
                    console.log(this.TipoOperadoras)
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
