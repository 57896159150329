<template>
    <div>
        <!--edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Admin</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de Planos</a> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="PlanosGarantePay.cpgId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input v-model="PlanosGarantePay.cpgNome" :rules="nameRules" :counter="100"
                                                type="text" class="form-control" placeholder="Nome" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Ativo</label>
                                            <select v-model="PlanosGarantePay.cpgAtivo" class="form-select form-control">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Valor</label>
                                            <input
                                                v-model="formattedValue"
                                                @input="formatValue"
                                                type="text"
                                                class="form-control"
                                                placeholder="Valor"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Texto</label>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="PlanosGarantePay.cpgTexto" :config="$ckconfig"></ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="PlanosGarantePay.cpgId != 0" type="button" class="btn btn-success"
                            style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de planos</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="PlanosGarantePays" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-cpgAtivo="item">
                                        <span>{{ item.cpgAtivo ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-cpgvalor="item">
                                        <span>{{ item.cpgValor !== '' ? parseFloat(item.cpgValor).toFixed(2).replace('.', ',') : '0,00' }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="cpg">Editar</v-tooltip>
                                        </v-btn>

                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="cpg">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
    name: 'CadastroPlanosGarantePayView',
    components: {
        EasyDataTable
    },
    data: () => ({
        EditView: false,
        editor: DecoupledEditor,
        PlanosGarantePay: {
            cpgId: 0,
            cpgNome: '',
            cpgTexto: '',
            cpgAtivo: '',
            cpgValor:'',
        },
        PlanosGarantePays: [],
        selectedVisivel: null,
        headers: [
            { text: "Id", value: "cpgId", sortable: true, },
            { text: "Nome", value: "cpgNome", sortable: true, },
            { text: "Valor R$", value: "cpgValor", sortable: true, },
            { text: "Ativo", value: "cpgAtivo", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    computed: {
    formattedValue: {
      get() {
        // Formata o valor do campo para exibição (ponto para vírgula)
        return this.PlanosGarantePay.cpgValor
          ? this.PlanosGarantePay.cpgValor.toString().replace('.', ',')
          : '';
      },
      set(value) {
            // Remove caracteres não numéricos e limita a duas casas decimais
            let formattedValue = value.replace(/[^0-9,]/g, '');
            const parts = formattedValue.split(',');

            if (parts.length > 2) {
            // Se houver mais de uma vírgula, ignora as partes extras
            parts.splice(2);
            }

            // Se houver parte decimal, limita a duas casas
            if (parts[1]) {
            parts[1] = parts[1].substring(0, 2);
            }

            // Atualiza o valor formatado no formato correto para ser enviado ao backend (vírgula para ponto)
            this.PlanosGarantePay.cpgValor = parts.join('.').replace(',', '.');
        },
        },
    },
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formatValue(event) {
            let value = event.target.value;

            // Remove todos os caracteres não numéricos, exceto a vírgula
            value = value.replace(/[^0-9,]/g, '');

            // Garante que não haja mais de uma vírgula e limita a duas casas decimais
            const parts = value.split(',');
            if (parts.length > 2) {
                // Se houver mais de uma vírgula, ignora as partes extras
                parts.splice(2);
            }

            // Se houver parte decimal, limita a duas casas
            if (parts[1]) {
                parts[1] = parts[1].substring(0, 2);
            }

            // Recombina as partes do número
            this.PlanosGarantePay.cpgValor = parts.join(',');

            // Atualiza o valor do campo de entrada
            event.target.value = this.PlanosGarantePay.cpgValor;
            },
          //edit
          EditNew(){
            this.EditView = true;
            this.PlanosGarantePay.cpgId = 0;
            this.PlanosGarantePay.cpgNome= '';
            this.PlanosGarantePay.cpgTexto= '';
            this.PlanosGarantePay.cpgAtivo= '';
            this.PlanosGarantePay.cpgValor= '';
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.cpgId != ''){
            axios.get(`/Process/CadPlanosGarantePay/GetById/` + item.cpgId)
                .then(response => {
                    this.PlanosGarantePay = response.data;
                    this.PlanosGarantePay.cpgTexto= '';
                    this.PlanosGarantePay.cpgValor = this.PlanosGarantePay.cpgValor.replace('.', ',');
                });
            }
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        Add() {
            if (this.PlanosGarantePay.cpgNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.formattedValue === '') {
                this.$mensagemAviso("Valor Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else {
                this.PlanosGarantePay.cpgValor = parseFloat(this.formattedValue.replace(',', '.'));
                axios.post(`/Process/CadPlanosGarantePay/Add`, this.PlanosGarantePay)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.PlanosGarantePay.cpgNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.formattedValue === '') {
                this.$mensagemAviso("Valor Obrigatório.")
            }
            else if (this.PlanosGarantePay.cpgAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else {
                this.PlanosGarantePay.cpgValor = parseFloat(this.formattedValue.replace(',', '.'));
                axios.put(`/Process/CadPlanosGarantePay/Update`, this.PlanosGarantePay)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        //edit
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cpgId;
                axios.delete(`/Process/CadPlanosGarantePay/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        Get() {
            this.EditView = false;
            axios.get(`/Process/CadPlanosGarantePay/GetAll`)
                .then(response => {
                    this.PlanosGarantePays = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
