<template>
    <div>
        <!--Edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Imobiliarias</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de Imobiliarias</a>
                        </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#dadcdf">
                                    <v-tab value="dados" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Dados Imobiliaria</v-tab>
                                    <div v-if="Imobiliaria.imoId != 0"> 
                                        <v-tab value="endereco" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Endereço</v-tab>
                                    </div>
                                    <div v-if="Imobiliaria.imoId != 0">
                                        <v-tab value="contrato" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Contrato</v-tab>
                                    </div>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item value="dados">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Imobiliaria.imoId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="User.garid == 0">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <select  v-model="Imobiliaria.garId" class="form-select form-control" 
                                                             :disabled="User.tpeid === 1 || User.tpeid === 2 || User.tpeid === 3" @change="BuscaParceiros()">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in ListGarantidorasGeral" :key="optionTipo.garId"
                                                                    :value="optionTipo.garId">{{ optionTipo.garNomeEmpresa }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="User.garid == 0 || User.tpeid === 1 || User.tpeid === 2">
                                                        <div class="form-group">
                                                            <label>Parceiro</label>
                                                            <select  v-model="Imobiliaria.parId" class="form-select form-control" :disabled="User.tpeid === 2" >
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in ListParceiros" :key="optionTipo.parId"
                                                                    :value="optionTipo.parId">{{ optionTipo.parNomeEmpresa }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Tipo Pessoa</label>
                                                            <select  v-model="Imobiliaria.tpdId" class="form-select form-control" :disabled="User.tpeid == 3">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in TiposDocumentos" :key="optionTipo.tpdId"
                                                                    :value="optionTipo.tpdId">{{ optionTipo.tpdNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Nº {{ Imobiliaria.tpdId == 1 ? 'CPF' : 'CNPJ'}}</label>
                                                            <input v-model="Imobiliaria.imoDocumento" type="text" v-mask="documentoMask" :disabled="User.tpeid == 3"
                                                                class="form-control" :placeholder="Imobiliaria.tpdId == 1 ? 'CPF' : 'CNPJ'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Nome</label>
                                                            <input v-model="Imobiliaria.imoNomeEmpresa" type="text"
                                                                class="form-control" :placeholder="Imobiliaria.tpdId == 1 ? 'Nome' : 'Nome Fantasia'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Imobiliaria.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Nome Responsável</label>
                                                            <input v-model="Imobiliaria.imoResponsavel" type="text"
                                                                class="form-control" placeholder="Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Imobiliaria.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>CPF Responsável</label>
                                                            <input v-model="Imobiliaria.imoCampoExtra1" type="text" v-mask="'###.###.###-##'"
                                                                class="form-control" placeholder="CPF Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6" v-if="Imobiliaria.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Razão Social</label>
                                                            <input v-model="Imobiliaria.imoRazaoSocial" type="text"
                                                                class="form-control" placeholder="Razão Social" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Telefone</label>
                                                            <input v-model="Imobiliaria.imoTelefone" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Telefone" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Whatsapp</label>
                                                            <input v-model="Imobiliaria.imoWhatsapp" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Whatsapp" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5">
                                                        <div class="form-group">
                                                            <label>E-mail</label>
                                                            <input v-model="Imobiliaria.imoEmail" type="text" class="form-control" :disabled="User.tpeid == 3"
                                                                placeholder="E-mail" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Creci</label>
                                                            <input v-model="Imobiliaria.imoCreci" type="text" class="form-control"
                                                                placeholder="Creci" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5">
                                                        <div class="form-group">
                                                            <label>Site</label>
                                                            <input v-model="Imobiliaria.imoCampoExtra2" type="text" class="form-control"
                                                                placeholder="Site" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereço-->
                                    <v-window-item value="endereco">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Endereco.imeId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Imobiliaria</label>
                                                            <input v-model="Endereco.imoId" type="text"
                                                                class="form-control" placeholder="Id Imobiliaria" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>CEP</label>
                                                            <input v-model="Endereco.imeCep" v-mask="'#####-###'" type="text"
                                                                class="form-control" placeholder="CEP" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group" style="padding-top: 35px;">
                                                            <button type="button" class="btn btn-info" @click.prevent="Buscacep(Endereco.imeCep)" style="color:#ffffff;">
                                                                Verificar CEP
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Endereço</label>
                                                            <input v-model="Endereco.imeEndereco" type="text"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Número</label>
                                                            <input v-model="Endereco.imeNumero" type="number"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Complemento</label>
                                                            <input v-model="Endereco.imeComplemento" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Estado</label>
                                                            <input v-model="Endereco.imeEstado" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Cidade</label>
                                                            <input v-model="Endereco.imeCidade" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Bairro</label>
                                                            <input v-model="Endereco.imeBairro" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10" v-if="showend">
                                                        <div class="form-group">
                                                            <button v-if="Endereco.imeId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="EnderecoPut()">
                                                                Alterar Endereço
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="EnderecoAdd()">
                                                                Salvar endereço
                                                            </button>
                                                            <a href="#" @click="EnderecoCancel()" class="btn btn-link">Cancelar Endereço</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereco-->
                                   <!--Contratos-->
                                   <v-window-item value="contrato">
                                        <v-form>
                                            <v-container>
                                                <div class="row" v-if="User.garid == 0 || User.tpeid == 1">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Contrato.gctId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <input v-model="Contrato.garId" type="text"
                                                                class="form-control" placeholder="Id Garantidora" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Modelos de Contrato</label>
                                                            <select  v-model="Contrato.cmcId" class="form-select form-control">
                                                                <option value="">Selecione</option>
                                                                <option v-for="optionTipo in ListaContratos" :key="optionTipo.cmcId"
                                                                    :value="optionTipo.cmcId">{{ optionTipo.cmcNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label></label>
                                                            <div>
                                                                <button type="button" class="btn btn-sm btn-success" 
                                                                 style="color:#ffffff;margin-top:12px;" @click="ContratoAdd()">Adicionar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <v-form>
                                                        <v-container>
                                                            <v-row>
                                                                <v-table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Contrato
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Status
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Data Assinatura
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Actions
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="item in ListaMeusContratos"
                                                                            :key="item.ictId">
                                                                            <td>{{ ListaContratos.filter(x=> x.cmcId == item.cmcId)[0].cmcNome }}</td>
                                                                            <td>{{ item.ictStatusEnvelope == null ? 'Não enviado p/assinatura' :  item.ictDataAss == null ? 'Envelope encaminhado para coleta de assinaturas!' : 'Assinado' }}</td>
                                                                            <td>{{ item.ictDataAss }}</td>
                                                                            <td style="text-align:center;">
                                                                                <v-btn icon @click.prevent="VisualizarContrato(item)"
                                                                                    variant="text">
                                                                                    <v-icon>mdi-eye</v-icon>
                                                                                    <v-tooltip activator="parent"
                                                                                        location="top">Visualizar</v-tooltip>
                                                                                </v-btn>
                                                                                <v-btn v-if="item.ictStatusEnvelope == null" class="btn btn-sm btn-info" @click.prevent="EnviarAssinatura(item)" variant="text">
                                                                                     Enviar p/ Assinatura
                                                                                </v-btn>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </v-table>
                                                            </v-row>
                                                        </v-container>
                                                    </v-form>
                                                </div>
                                            </v-container>
                                        </v-form>
                                       </v-window-item>
                                         <!--Contratos-->
                                </v-window>
                            </div>
                        </div>
                    </div>
                    <div class="card-action" v-if="tab=='dados'">
                        <button v-if="Imobiliaria.imoId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                            @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                        <div  class="col-md-3 col-lg-3" v-if="User != '' && (User.garid == 0 || User.tpeid == 1)" style="float: right;">
                            <div class="form-group">
                                <button type="button" class="btn btn-sm btn-info" style="color:#ffffff;" @click="CriarUsuarioEmail()">Criar e Notificar Usuário de acesso</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de Imobiliaria</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="ListImobiliarias"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="imoent" location="top">Editar</v-tooltip>
                                        </v-btn>
                                        <v-btn icon @click="DeleteGrid(item)" variant="text" v-if="User!='' && User.garid == 0">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="imoent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'ImobiliariasView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        EditView: false,
        User:[],
        ListGarantidorasGeral:[],
        ListParceirosGeral:[],
        ListParceiros:[],
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Imobiliarias',
        InfoTela:'Lista de Imobiliarias',
        MostraGrid: true,
        headers: [],
        //dados
        Imobiliaria: {
            imoId: 0,
            imoVisivel: null,
            imoDataCadastro: null,
            imoNomeEmpresa: '',
            imoRazaoSocial: '',
            tpdId: null,
            imoDocumento: '',
            imoTelefone: '',
            imoWhatsapp: '',
            imoResponsavel: '',
            imoEmail: '',
            imoAtivo: null,
            imoDataAniversario: null,
            imoCampoExtra1: '',
            imoCampoExtra2: '',
            imoCampoExtra3: '',
            imoCampoExtra4: '',
            imoCampoExtra5: '',
            imoTexto1: '',
            imoTexto2: '',
            imoTexto3: '',
            imoData1: null,
            imoData2: null,
            imoCreci: '',
            garId: null,
            parId:null
        },
        ListImobiliarias: [],
        ListImobiliariasGeral:[],
        TiposDocumentos:[],
        //dados
        //imagens
        selectedFile: null,
        //endereco
        Endereco: {
            imeId: 0,
            grtId: 0,
            cdtId: 0,
            imeEndereco: '',
            imeNumero: 0,
            imeComplemento: '',
            imeObsEndereco: '',
            imeCep: '',
            imePais: '',
            imeEstado: '',
            imeCidade: '',
            imeBairro: '',
            imoId: null
        },
        ListaEnderecos:[],
        showend:false,
        Retornocep:null,
        //endereco
         //contrato
         Contrato: {
            ictId: 0,
            grtId: 0,
            cdtId: 0,
            imoId: null,
            cmcId: '',
            ictUrlContrato: null,
            ictIp: null,
            ictDataAss: null, // Data inicializada como null
            ictRetornoJson: null,
            ictIdenvelope: null,
            ictStatusEnvelope: null
        },
      ListaContratos:[],
      ListaMeusContratos:[],
      ListUser:[],
    //contrato
     //contrato
     Garantidora: {
            garId: 0,
            garVisivel: null,
            garDataCadastro: null,
            garNomeEmpresa: '',
            garRazaoSocial: '',
            tpdId: null,
            garDocumento: '',
            garTelefone: '',
            garWhatsapp: '',
            garResponsavel: '',
            garEmail: '',
            garAtivo: null,
            garDataAniversario: null,
            garCampoExtra1: '',
            garCampoExtra2: '',
            garCampoExtra3: '',
            garCampoExtra4: '',
            garCampoExtra5: '',
            garTexto1: '',
            garTexto2: '',
            garTexto3: '',
            garData1: null,
            garData2: null,
            garUrlLogo: ''
        },
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().imoentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListImobiliarias = this.ListImobiliariasGeral;
        },
        //imagens
        //imoantidora
         //edit
        EditNew(){
        this.EditView = true;
        this.Imobiliaria.imoId = 0;
        this.Imobiliaria.imoVisivel= null;
        this.Imobiliaria.imoDataCadastro= null;
        this.Imobiliaria.imoNomeEmpresa= '';
        this.Imobiliaria.imoRazaoSocial= '';
        this.Imobiliaria.tpdId= null;
        this.Imobiliaria.imoDocumento= '';
        this.Imobiliaria.imoTelefone= '';
        this.Imobiliaria.imoWhatsapp= '';
        this.Imobiliaria.imoResponsavel= '';
        this.Imobiliaria.imoEmail= '';
        this.Imobiliaria.imoAtivo= null;
        this.Imobiliaria.imoDataAniversario= null;
        this.Imobiliaria.imoCampoExtra1= '';
        this.Imobiliaria.imoCampoExtra2= '';
        this.Imobiliaria.imoCampoExtra3= '';
        this.Imobiliaria.imoCampoExtra4= '';
        this.Imobiliaria.imoCampoExtra5= '';
        this.Imobiliaria.imoTexto1= '';
        this.Imobiliaria.imoTexto2= '';
        this.Imobiliaria.imoTexto3= '';
        this.Imobiliaria.imoData1= null;
        this.Imobiliaria.imoData2= null;
        this.Imobiliaria.imoCreci= '';
        this.Imobiliaria.parId= null;
        this.Imobiliaria.garId= this.User.garid == 0 ? null : this.User.garid;
        //endereco
        this.Endereco.imeId = 0;
        this.Endereco.grtId = 0;
        this.Endereco.cdtId = 0;
        this.Endereco.imeEndereco = '';
        this.Endereco.imeNumero = 0;
        this.Endereco.imeComplemento = '';
        this.Endereco.imeObsEndereco = '';
        this.Endereco.imeCep = '';
        this.Endereco.imePais = '';
        this.Endereco.imeEstado = '';
        this.Endereco.imeCidade = '';
        this.Endereco.imeBairro = '';
        this.showend = false;
        this.Retornocep = null;
        //contrato
        this.Contrato.ictId = 0;
        this.Contrato.grtId = 0;
        this.Contrato.cdtId = 0;
        this.Contrato.cmcId = '';
        this.Contrato.ictUrlContrato = null;
        this.Contrato.ictIp = null;
        this.Contrato.ictDataAss = null; // Data inicializada como null
        this.Contrato.ictRetornoJson = null;
        this.Contrato.ictIdenvelope = null;
        this.Contrato.ictStatusEnvelope = null;
        this.ListaMeusContratos= [];
        this.tab = 'dados';
        this.BuscaParceiros();
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        EditGrid(item) {
            this.EditView = true;
            this.tab = 'dados';
            console.log('item')
            console.log(item)
            if (item.imoId != '') {
                // Lógica imoa editar o item
                 axios.get(`/Process/ImoImobiliarias/GetById/` + item.imoId) 
                    .then(response => {
                        this.Imobiliaria = response.data;

                        //carreimo endereços
                        this.CarregaEndereco(item.imoId);

                        //carrega parceiros
                        this.BuscaParceiros();

                        //carrega contratos
                        this.CarregaContratos(item.imoId)

                    });

              
            }
        },
        async Add() {
               if (this.Imobiliaria.garId === '' || this.Imobiliaria.garId === null) {
                    this.$mensagemAviso("Campo Garantidora Obrigatório.")
                }
                else if (this.Imobiliaria.tpdId === '' || this.Imobiliaria.tpdId === null) {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
                }
                else if (this.Imobiliaria.imoDocumento === '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
                else if (this.Imobiliaria.imoNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
                else if (this.Imobiliaria.imoTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Imobiliaria.imoWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Imobiliaria.imoEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Imobiliaria.imoResponsavel === '' && this.Imobiliaria.tpdId == 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Imobiliaria.imoCampoExtra1 === '' && this.Imobiliaria.tpdId == 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
                else {
                    if (this.Imobiliaria.imoVisivel === "" || this.Imobiliaria.imoVisivel === null) {
                        this.Imobiliaria.imoVisivel = true;
                    }
                    if (this.Imobiliaria.imoAtivo === "" || this.Imobiliaria.imoAtivo === null) {
                        this.Imobiliaria.imoAtivo = true;
                    }

                    if (this.Imobiliaria.parId === "" || this.Imobiliaria.parId === null) {
                        this.Imobiliaria.parId = null;
                    }
                    this.Imobiliaria.imoDataCadastro = this.$moment().format('YYYY-MM-DD')
                    axios.post(`/Process/ImoImobiliarias/Add`, this.Imobiliaria)
                        .then(response => {
                            if (response.data > 0) {
                                this.Imobiliaria.imoId = response.data;
                                //carreimo endereços
                                 this.CarregaEndereco(this.Imobiliaria.imoId);
                                //carrega contratos
                                this.CarregaContratos(this.Imobiliaria.imoId)
                                this.$mensagemAvisoSucesso("Registro Incluído. abas imoa informações adicionais liberadas.")
                            }
                        });
                
            }
        },
        Put() {
            if (this.Imobiliaria.garId === '' || this.Imobiliaria.garId === null) {
                    this.$mensagemAviso("Campo Garantidora Obrigatório.")
                }
                else if (this.Imobiliaria.tpdId === '' || this.Imobiliaria.tpdId === null) {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
                }
                else if (this.Imobiliaria.imoDocumento === '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
                else if (this.Imobiliaria.imoNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
                else if (this.Imobiliaria.imoTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Imobiliaria.imoWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Imobiliaria.imoEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Imobiliaria.imoResponsavel === '' && this.Imobiliaria.tpdId == 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Imobiliaria.imoCampoExtra1 === '' && this.Imobiliaria.tpdId == 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
            else {
                if (this.Imobiliaria.imoVisivel === "" || this.Imobiliaria.imoVisivel === null) {
                        this.Imobiliaria.imoVisivel = true;
                    }
                    if (this.Imobiliaria.imoAtivo === "" || this.Imobiliaria.imoAtivo === null) {
                        this.Imobiliaria.imoAtivo = true;
                    }
             
                axios.put(`/Process/ImoImobiliarias/Update`, this.Imobiliaria)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        CriarUsuarioEmail(){
            if (this.Imobiliaria.imoId == 0){
                this.$mensagemAviso("Imobiliaria precisa ser cadastrada.")
            }
            else if (this.ListaMeusContratos.length == 0){
                this.$mensagemAviso("Campo Contrato - Modelo de contrato Obrigatório ser adicionado.")
            }
            else if (this.ListaMeusContratos.filter(x=> x.gctIdEnvelope === null).length > 0){
                this.$mensagemAviso("Contrato precisar ser enviado para assinatura.")
            }
            else{
                axios.get(`/Process/UsrCadastroUsuarios/GetByEmail/` + this.Imobiliaria.imoEmail)
                .then(response => {
                    this.ListUser = response.data.filter(x=> x.tpeId == 3);
                  
                    if (this.ListUser.length == 0)
                    {
                            var _usuario = {
                                cusId: 0,
                                cusNome: this.Imobiliaria.imoNomeEmpresa,
                                cusEmail: this.Imobiliaria.imoEmail,
                                cusTelefone: this.Imobiliaria.imoTelefone,
                                cusWhatsapp: this.Imobiliaria.imoWhatsapp,
                                cusSenha: this.Imobiliaria.imoWhatsapp.slice(-4),
                                cusDtCadastro: this.$moment(),
                                tusId: 9,
                                cusAtivo: true,
                                cusUrlImagem: '',
                                cliId: 0,
                                admTipoUsuario: {tusId: 9},
                                tpeId:3,
                                garId: this.Imobiliaria.garId,
                                pesId: this.Imobiliaria.imoId,
                                }
                            axios.post(`/Process/UsrCadastroUsuarios/Add`, _usuario)
                                .then(response => {
                                    if (response.data > 0) {
                                        _usuario.cusId = response.data;
                                         this.EnviaEmailCadastro(_usuario);
                                         this.$mensagemAviso("Usuário criado e notificado.")
                                    }
                                });
                        }
                        else{
                            this.$mensagemAviso("Usuário já possui cadastro ativo.")
                        }
                });
            }
         
        },
        EnviaEmailCadastro(usuariocad){
              axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 6);
                            let _garNomeEmpresa = this.ListGarantidorasGeral.filter(x=> x.garId == this.Imobiliaria.garId)[0].garNomeEmpresa;
                            let _dadosEmail = {
                                            nomeDe: this.$GarantePayNome,
                                            nomePara: usuariocad.cusNome,
                                            assunto: 'Cadastro Imobiliaria - Sistema GarantePay',
                                            destinatario: usuariocad.cusEmail,
                                            emailResposta: this.$GarantePayEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomeImobiliaria]',this.Imobiliaria.imoNomeEmpresa)
                                                        .replace('[NomeUsuario]',this.Imobiliaria.imoResponsavel)
                                                        .replace('[NomeGarantidora]', _garNomeEmpresa)
                                                        .replace('[LinkAcesso]', this.$GarantePayLinkAcesso)
                                                        .replace('[UsuarioLogin]', usuariocad.cusEmail)
                                                        .replace('[SenhaAcesso]', usuariocad.cusSenha)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        //edit
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica imoa excluir o item
                const imoam1 = item.imoId;
                axios.delete(`/Process/ImoImobiliarias/Delete/${imoam1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "imoId", sortable: true, });
            this.headers.push({ text: "Nome", value: "imoNomeEmpresa", sortable: true, });
            this.headers.push({ text: "Documento", value: "imoDocumento", sortable: true, });
            this.headers.push({ text: "Whatsapp", value: "imoWhatsapp", sortable: true, });
            this.headers.push({ text: "Telefone", value: "imoTelefone", sortable: true, });
            this.headers.push({ text: "E-mail", value: "imoEmail", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        //imoantidora
        //endereco
        CarregaEndereco(_imoId){
            axios.get(`/Process/ImoImobiliariaEndereco/GetByImoId/` + _imoId) 
                .then(response => {
                    if (response.data.length > 0){
                        this.Endereco = response.data[0];
                        this.showend = true;
                    }
                    else{
                        this.Endereco.imoId = _imoId;
                    }                    
                });
        },
        Buscacep(value) {
            if (value == ""){
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else{
                fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                    if (this.Retornocep != null) {
                        this.Endereco.imeEndereco = this.Retornocep.logradouro;
                        this.Endereco.imeNumero = null;
                        this.Endereco.imeComplemento = null;
                        this.Endereco.imeEstado = this.Retornocep.uf;
                        this.Endereco.imeCidade = this.Retornocep.localidade;
                        this.Endereco.imeBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        this.$mensagemAviso("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }
        },
        EnderecoAdd(){
            if (this.Endereco.imeCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.imeEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.imeNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.imeEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.imeCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.imeBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                        axios.post(`/Process/ImoImobiliariaEndereco/Add`, this.Endereco)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Endereco.imeId = response.data;
                                    this.$mensagemAvisoSucesso("Endereço Incluído")
                                }
                            });
                }
        },
        EnderecoPut(){
            if (this.Endereco.imeCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.imeEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.imeNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.imeEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.imeCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.imeBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                    axios.put(`/Process/ImoImobiliariaEndereco/Update`, this.Endereco)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Endereço Alterado.")
                        }
                    });
                }
        },
        EnderecoCancel(){
            /*this.Endereco.grtId = 0;
            this.Endereco.cdtId = 0;
            this.Endereco.gaeEndereco = '';
            this.Endereco.gaeNumero = null;
            this.Endereco.gaeComplemento = '';
            this.Endereco.gaeObsEndereco = '';
            this.Endereco.gaeCep = '';
            this.Endereco.gaePais = '';
            this.Endereco.gaeEstado = '';
            this.Endereco.gaeCidade = '';
            this.Endereco.gaeBairro = '';
            this.showend = false;*/
            this.$router.go(0);
        },
        //endereco
         //contratos
         CarregaContratos(_imoId){
            axios.get(`/Process/ImoImobiliariaContrato/GetByImoId/` + _imoId) 
                .then(response => {
                    this.ListaMeusContratos = response.data;                        
                });
        },
        async ContratoAdd(){
               if (this.Contrato.cmcId === '') {
                    this.$mensagemAviso("Campo Modelo Contrato Obrigatório.")
                }
                else {
                    //dadosContratante = imobiliária e dadosContratada = garantidora
                    var _dadosContrato = {
                            TipoPessoa: 3,
                            CmcId: this.Contrato.cmcId,
                            IdContratante: this.Imobiliaria.imoId,
                            IdContratada: this.Imobiliaria.garId,
                            dadosContratacao: null
                        }

                        var _fileName = await this.Uploadpdf(_dadosContrato);

                        this.Contrato.imoId = this.Imobiliaria.imoId;
                        this.Contrato.ictUrlContrato = _fileName;

                        axios.post(`/Process/ImoImobiliariaContrato/Add`, this.Contrato)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Contrato.ictId = response.data;
                                    this.CarregaContratos(this.Contrato.imoId);
                                    this.$mensagemAvisoSucesso("Contrato Incluído")
                                }
                            });
                }
        },
        ContratosPut(){
                 if (this.Contrato.cmcId === '') {
                    this.$mensagemAviso("Campo Modelo Contrato Obrigatório.")
                }
                else {
                    axios.put(`/Process/ImoImobiliariaContrato/Update`, this.Contrato)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Contrato Alterado.")
                        }
                    });
                }
        },
        ContratosCancel(){
            /*this.Contrato.grtId = 0;
            this.Contrato.cdtId = 0;
            this.Contrato.cmcId = null;
            this.Contrato.gctUrlContrato = '';
            this.Contrato.gctIp = '';
            this.Contrato.gctDataAss = null;
            this.Contrato.gctRetornoJson = '';*/
            this.$router.go(0);
        },
        VisualizarContrato(_contrato){
            if (_contrato && _contrato.ictUrlContrato) {
            // Abre o PDF em uma nova aba
            window.open(this.$imgURL + _contrato.ictUrlContrato, '_blank');
            } else {
                console.error("URL do contrato não está disponível.");
            }
        },
        async Uploadpdf(dadosContrato) {
            const uploadResult = await axios.post(`/Process/UploadImg/uploadContratoPdf`, dadosContrato);
            return uploadResult.data.nameFile;
        },
        async EnviarAssinatura(item){
            axios.get(`/Process/GarGarantidoraAssinatura/GetByGarId/` + this.Imobiliaria.garId) 
                .then(response => {
                    if (response.data.length > 0){
                      this.Assinatura = response.data[0]; 
                      this.Garantidora = this.ListGarantidorasGeral.filter(x=> x.garId == this.Imobiliaria.garId)[0];
                      
                        let idContrato = item.ictId;
                        let tpeId = 3;
                        let DadosEnvelopeRequest = {
                            token: this.Assinatura.gasToken,
                            envelopeDescricao: this.ListaContratos.filter(x=> x.cmcId == item.cmcId)[0].cmcNome,
                            repositorioId: parseInt(this.Assinatura.gasIdRepositorio),
                            listaDocumentos: [{ UrlArquivo: item.ictUrlContrato}],
                            listaSignatariosEnvelopes: [{
                                Ordem: 1,
                                EmailSignatario: this.Imobiliaria.imoEmail,
                                NomeSignatario: this.Imobiliaria.imoNomeEmpresa,
                                TipoAssinatura: 1
                            },
                            {
                                Ordem: 2,
                                EmailSignatario: this.Garantidora.garEmail,
                                NomeSignatario: this.Garantidora.garNomeEmpresa,
                                TipoAssinatura: 1
                            }]
                        }      
                       
                        axios.post(`/Process/AssinaturasAsten/CreateEnvelope/` + idContrato + '/' + tpeId, DadosEnvelopeRequest)
                            .then(response => {
                                if (response.status == 200) {
                                    this.CarregaContratos(this.Imobiliaria.imoId);
                                    this.$mensagemAvisoSucesso("Assinatura enviada aos signatários");
                                }
                            })
                            .catch(error => {
                                if (error.response) {
                                    // Erros retornados pelo servidor
                                    this.$mensagemErro(
                                        `Erro ao enviar assinatura (Status ${error.response.status}): ${error.response.data}`
                                    );
                                    } else if (error.request) {
                                    // O servidor não respondeu
                                    this.$mensagemErro("Erro: Nenhuma resposta do servidor.", error.request);
                                    } else {
                                    // Erro ao configurar a requisição
                                    this.$mensagemErro("Erro ao configurar requisição:", error.message);
                                    }
                            });
                    }                 
                });
        },
        //contratos
        BuscaParceiros(){
          this.ListParceiros = this.ListParceirosGeral.filter(x=> x.garId == this.Imobiliaria.garId);
        },
        Get() {
            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }
            //console.log('this.User')
            //console.log(this.User)

            if (this.User.garid == 0){ //garantepay
                this.EditView = false;
                axios.get(`/Process/ImoImobiliarias/GetAll`)
                .then(response => {
                    this.ListImobiliariasGeral = response.data.filter(x=> x.imoAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else if (this.User.tpeid == 1){ //garantidora
                this.EditView = false;
                axios.get(`/Process/ImoImobiliarias/GetByGarIdParId/` + this.User.garid + `/0`)
                .then(response => {
                    this.ListImobiliariasGeral = response.data.filter(x=> x.imoAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else if (this.User.tpeid == 2){ //parceiros
                this.EditView = false;
                axios.get(`/Process/ImoImobiliarias/GetByGarIdParId/0/` + this.User.pesid)
                .then(response => {
                    this.ListImobiliariasGeral = response.data.filter(x=> x.imoAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else if (this.User.tpeid == 3){ //imobiliaria mesmo
                var _item = {imoId : this.User.pesid}
                this.EditGrid(_item);
            }    

            axios.get(`/Process/CadTipoDocumento/GetAll`) 
                .then(response => {
                    this.TiposDocumentos = response.data.filter(x=> x.tpdAtivo);                       
                });

            axios.get(`/Process/CadModelosContrato/GetAll`)
                .then(response => {
                    this.ListaContratos = response.data.filter(x=> x.tpeId == 3 && x.cmcAtivo);
                });

            axios.get(`/Process/GarGarantidoras/GetAll`)
                .then(response => {
                    this.ListGarantidorasGeral = response.data.filter(x=> x.garAtivo);
                });

                axios.get(`/Process/ParParceiros/GetAll`)
                .then(response => {
                    this.ListParceirosGeral = response.data.filter(x=> x.parAtivo);
                });
        }
    },
    created() {
        this.Get();
    },
    computed: {
        documentoMask() {
        return this.Imobiliaria.tpdId === 1 ? '###.###.###-##' : '##.###.###/####-##';
        }
   }
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
