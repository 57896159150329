<template>
  <footer class="footer" style="text-align: center;">
      <div class="container-fluid d-flex">
        <div class="copyright">
          <p>Desenvolvido por <img src="/images/logogarantepay.jpg" style="width: 120px;height: auto;" alt="Logo" /></p>
        </div>
      </div>
  </footer>
</template>

<script>
import axios from 'axios';
export default {
  name: 'FooterPage',
  components: {

  },
  data: () => ({
  }),
  methods: {
  },
  created () {
  }
}
</script>

<style scoped>
</style>
