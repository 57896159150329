<template>
  <div>
    <!--Edit-->
    <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Produtos</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de Segmentos Produtos</a> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="SegmentoProd.psgId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="ListaSegmentosPai != ''">
                                        <div class="form-group">
                                            <label>Hierarquia</label>
                                            <select  v-model="SegmentoProd.psgIdPai" class="form-select form-control">
                                                <option value="">Selecione</option>
                                                <option v-for="optionTipo in ListaSegmentosPai" :key="optionTipo.psgId"
                                                    :value="optionTipo.psgId">{{ optionTipo.psgNomeSegmento }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Nome Segmento</label>
                                            <input v-model="SegmentoProd.psgNomeSegmento"
                                                type="text" class="form-control" placeholder="Segmento" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Ordem</label>
                                            <input v-model="SegmentoProd.psgOrdem"
                                                type="number" class="form-control" placeholder="Ordem" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Visível</label>
                                            <select v-model="SegmentoProd.psgVisivel" class="form-select form-control"
                                                id="defaultSelect">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="SegmentoProd.psgId != 0" type="button" class="btn btn-success"
                            style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()"  class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de Segmentos Produtos</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="preprocessData(ListSegmentosProd)" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-psgVisivel="item">
                                        <span>{{ item.psgVisivel ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-psgOrdem="item">
                                        <span>{{ parseInt(item.psgOrdem) }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>

                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';

export default {
    name: 'SegmentosProdView',
    components: {
        EasyDataTable
    },
    data: () => ({
        EditView: false,
        SegmentoProd: {
            psgId: 0,
            grtId: 0,
            cdtId: 0,
            psgNomeSegmento: '',
            psgVisivel: '',
            psgDestaque: '',
            psgOrdem: '',
            psgResumo: '',
            psgDescricao1: '',
            psgDescricao2: '',
            psgIdPai:0,
        },
        headers: [],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
        ListSegmentosProd: [],
        ListSegmentosProdGeral:[],
        ListaSegmentosPai:[],
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                psgOrdem: parseInt(item.psgOrdem)
            };
            });
        },
        CarregaLista(){
            axios.get(`/Process/ProSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosProdGeral = response.data;
                                    this.montaGrid();
                                    this.ListaSegmentosPai = this.ListSegmentosProdGeral.filter(x=> x.psgVisivel);
           this.ListSegmentosProd = this.ListSegmentosProdGeral;
           //console.log('this.ListSegmentosProdGeral')
           //console.log(this.ListSegmentosProdGeral)
           //console.log('this.ListaSegmentosPai')
           //console.log(this.ListaSegmentosPai)
           //console.log('this.ListSegmentosProd')
           //console.log(this.ListSegmentosProd)
        //------ carrega select psgIdPai com hierarquia
        function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
            const segmentosFilhos = segmentos.filter(segmento => segmento.psgIdPai === segmentoIdPai);
            const segmentosComCaminho = [];

            for (const segmentoFilho of segmentosFilhos) {
                const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.psgNomeSegmento : `${caminhoAtual} > ${segmentoFilho.psgNomeSegmento}`;

                segmentosComCaminho.push({ psgId: segmentoFilho.psgId, psgNomeSegmento: caminhoCompleto });
                
                const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.psgId, caminhoCompleto);
                segmentosComCaminho.push(...segmentosNetos);
            }

            return segmentosComCaminho;
            }
        
            const segmentosComCaminho = [];
            for (const segmento of this.ListaSegmentosPai.filter(x=> x.psgIdPai== 0)) {
            segmentosComCaminho.push({ psgId: segmento.psgId, psgNomeSegmento: segmento.psgNomeSegmento });
            
            const segmentosFilhos = buildHierarchy(this.ListaSegmentosPai, segmento.psgId, segmento.psgNomeSegmento);
            segmentosComCaminho.push(...segmentosFilhos);
            }
            this.ListaSegmentosPai = segmentosComCaminho;   
            this.ListSegmentosProd =  this.itensComHierarquia(this.ListSegmentosProd);  
        //------
        });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "psgId", sortable: true, });
            this.headers.push({ text: "Hierarquia", value: "Hierarquia", sortable: true});
            this.headers.push({ text: "Segmentos", value: "psgNomeSegmento", sortable: true, });
            this.headers.push({ text: "Ordem", value: "psgOrdem", sortable: true, });
            this.headers.push({ text: "Visível", value: "psgVisivel", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        itensComHierarquia(itens) {
        // Adicione a lógica para adicionar a coluna "Hierarquia" ao array de dados
        const itensComHierarquia = itens.map((item) => {
            const hierarquia = this.ListaSegmentosPai.filter(
            (x) => x.psgId === item.psgIdPai
            )[0]?.psgNomeSegmento || "";

            return { ...item, Hierarquia: hierarquia };
        });

          return itensComHierarquia;
        },
        //edit
        EditNew(){
        this.EditView = true;
        this.SegmentoProd.psgId = 0;
            this.SegmentoProd.psgNomeSegmento = '';
            this.SegmentoProd.psgOrdem = '';
            this.SegmentoProd.psgVisivel = '';
            this.SegmentoProd.psgDestaque = '';
            this.SegmentoProd.psgResumo = '';
            this.SegmentoProd.psgDescricao1 = '';
            this.SegmentoProd.psgDescricao2 = '';
            this.SegmentoProd.psgIdPai = '';
            this.ListSegmentosProd = [];
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.psgId != ''){
            axios.get(`/Process/ProSegmentos/GetById/` + item.psgId)
                .then(response => {
                    this.SegmentoProd = response.data;
                });
            }
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        Add() {
            if (this.SegmentoProd.psgNomeSegmento == '') {
                this.$mensagemAviso("Campo Nome Segmento Obrigatório.")
            }
            else if (this.SegmentoProd.psgOrdem === ''){
                this.$mensagemAviso("Campo Ordem Obrigatório.")
            }
            else if (this.SegmentoProd.psgVisivel === ''){
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else {
                if (this.SegmentoProd.psgVisivel === "" || this.SegmentoProd.psgVisivel === null) {
                    this.SegmentoProd.psgVisivel = true;
                }
                if (this.SegmentoProd.psgDestaque === "" || this.SegmentoProd.psgDestaque === null) {
                        this.SegmentoProd.psgDestaque = false;
                }
                if (this.SegmentoProd.psgOrdem === "" || this.SegmentoProd.psgOrdem === null) {
                    this.SegmentoProd.psgOrdem = 0;
                }
                else {
                    this.SegmentoProd.psgOrdem = parseInt(this.SegmentoProd.psgOrdem);
                }
                this.SegmentoProd.admGrupoTelas = { GrtId: this.SegmentoProd.grtId }
                this.SegmentoProd.admCadastroTelas = { CdtId: this.SegmentoProd.cdtId }

                if (this.SegmentoProd.psgIdPai!='' && this.SegmentoProd.psgIdPai!=null)
                {
                    this.SegmentoProd.psgSegmentosPai = {PsgId: this.SegmentoProd.psgIdPai}
                }
                else{
                    this.SegmentoProd.psgSegmentosPai = null;
                    this.SegmentoProd.psgIdPai = 0;
                }
                
                console.log('this.SegmentoProd')
                console.log(this.SegmentoProd)

                axios.post(`/Process/ProSegmentos/Add`, this.SegmentoProd)
                    .then(response => {
                        if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.SegmentoProd.psgNomeSegmento == '') {
                this.$mensagemAviso("Campo Nome Segmento Obrigatório.")
            }
            else if (this.SegmentoProd.psgOrdem === ''){
                this.$mensagemAviso("Campo Ordem Obrigatório.")
            }
            else if (this.SegmentoProd.psgVisivel === ''){
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else {
                if (this.SegmentoProd.psgVisivel === "" || this.SegmentoProd.psgVisivel === null) {
                    this.SegmentoProd.psgVisivel = true;
                }
                if (this.SegmentoProd.psgDestaque === "" || this.SegmentoProd.psgDestaque === null) {
                        this.SegmentoProd.psgDestaque = false;
                }
                if (this.SegmentoProd.psgOrdem === "" || this.SegmentoProd.psgOrdem === null) {
                    this.SegmentoProd.psgOrdem = 0;
                }
                else {
                    this.SegmentoProd.psgOrdem = parseInt(this.SegmentoProd.psgOrdem);
                }
                this.SegmentoProd.admGrupoTelas = { GrtId: this.SegmentoProd.grtId }
                this.SegmentoProd.admCadastroTelas = { CdtId: this.SegmentoProd.cdtId }

                if (this.SegmentoProd.psgIdPai!='' && this.SegmentoProd.psgIdPai!=null)
                {
                    this.SegmentoProd.psgSegmentosPai = {PsgId: this.SegmentoProd.psgIdPai}
                }
                else{
                    this.SegmentoProd.psgSegmentosPai = null;
                    this.SegmentoProd.psgIdPai = 0;
                }
                axios.put(`/Process/ProSegmentos/Update`, this.SegmentoProd)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        //edit
        DeleteGrid(item) {
           
            var validaFilhos = this.ListSegmentosProd.filter(x=> x.psgIdPai == item.psgId).length;

            if (validaFilhos > 0){
                this.$mensagemAviso("Exclusão negada, esse item possui filhos.")
            }
            else{

                axios.get(`/Process/ProProdutosSegmentos/GetAll`)
                    .then(response => {
                        this.ValidaSeg = response.data.filter(x=> x.insSegmentos!= null && x.insSegmentos.psgId == item.psgId)

                        if (this.ValidaSeg.length > 0){
                            this.$mensagemAviso("Exclusão negada, registro em uso em outras telas.")
                        }
                        else{
                            this.$mensagemConfirma('Tem certeza que deseja excluir?')
                            .then((result) => {
                            if (result.isConfirmed) {
                                // Lógica para excluir o item
                                const param1 = item.psgId;
                                axios.delete(`/Process/ProSegmentos/Delete/${param1}`)
                                .then(response => {
                                    if (response.status == 200) {
                                        this.$mensagemAvisoSucesso("Registro Excluido.")
                                        this.Get();
                                    }
                                });
                }
                });
                        }
                    });             
            }
           
        },
        Get() {
            this.EditView = false;
           this.CarregaLista();
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
