<template>
    <div>
        <!--edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Admin</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Grupo de Telas</a> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input type="text" v-model="GrupoTela.grtId" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-6">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input type="text" v-model="GrupoTela.grtNome" class="form-control"
                                                placeholder="Nome" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Ordem</label>
                                            <input type="number" v-model="GrupoTela.grtOrdem" class="form-control"
                                                placeholder="Ordem" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Visivel</label>
                                            <select v-model="GrupoTela.grtVisivel" class="form-select form-control" id="defaultSelect">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Ícone</label>
                                            <input v-model="GrupoTela.grtIcon" type="text" class="form-control"
                                                placeholder="Ícone" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="GrupoTela.grtId != 0" type="button" class="btn btn-success" style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Grupo de Telas</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="preprocessData(GrupoTelas)"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-grtVisivel="item">
                                        <span>{{ item.grtVisivel ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-grtOrdem="item">
                                        <span>{{ parseInt(item.grtOrdem) }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>
                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'GrupoTelasView',
    components: {
        EasyDataTable
    },
    data: () => ({
        EditView: false,
        GrupoTela: {
            grtId: 0,
            grtNome: '',
            grtOrdem: '',
            grtVisivel: '',
            grtIcon: '',
        },
        GrupoTelas: [],
        selectedVisivel: null,
        Status: [{ nome: "Sim", grtVisivel: true }, { nome: "Não", grtVisivel: false }],
        nameRules: [
            value => {
                if (value?.length <= 200) return true
                return 'Nome pode conter até 200 caracteres.'
            },
        ],

        headers: [
            { text: "Id", value: "grtId", sortable: true, },
            { text: "Nome", value: "grtNome", sortable: true, },
            { text: "Ordem", value: "grtOrdem", sortable: true, },
            { text: "Visivel", value: "grtVisivel", sortable: true, },
            { text: "Icone", value: "grtIcon" },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                grtOrdem: parseInt(item.grtOrdem),
            };
            });
        },
          //edit
          EditNew(){
            this.EditView = true;
            this.GrupoTela.grtId = 0;
            this.GrupoTela.grtNome = '';
            this.GrupoTela.grtOrdem = '';
            this.GrupoTela.grtVisivel = '';
            this.GrupoTela.grtIcon = '';
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.grtId != ''){
                axios.get(`/Process/AdmGrupoTelas/GetById/` + item.grtId)
                .then(response => {
                    this.GrupoTela = response.data;
                });
            }
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        Add() {
            if (this.GrupoTela.grtNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.GrupoTela.grtOrdem === '') {
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.GrupoTela.grtVisivel === '') {
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else {
                this.GrupoTela.grtOrdem = parseInt(this.GrupoTela.grtOrdem)
                axios.post(`/Process/AdmGrupoTelas/Add`, this.GrupoTela)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.GrupoTela.grtNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.GrupoTela.grtOrdem === '') {
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.GrupoTela.grtVisivel === '') {
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else {
                this.GrupoTela.grtOrdem = parseInt(this.GrupoTela.grtOrdem)
                axios.put(`/Process/AdmGrupoTelas/Update`, this.GrupoTela)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        //edit
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.grtId;
                axios.delete(`/Process/AdmGrupoTelas/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        Get() {
            this.EditView = false;
            axios.get(`/Process/AdmGrupoTelas/GetAll`)
                .then(response => {
                    this.GrupoTelas = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
