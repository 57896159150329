<template>
  <div style="background-color: #cccccc; min-height: 100vh; display: flex; align-items: center; justify-content: center;">
    <div class="card" style="width: 400px; padding: 20px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
      <div class="card-body">
        <div class="row text-center mb-3">
          <div class="col-md-12">
            <img src="/images/logogarantepay.jpg" style="width: 100%;" alt="Logo" />
          </div>
        </div>
        
        <!-- Alerta -->
        <v-alert v-if="showAlert" density="compact" :type="alertType" style="margin-bottom: 15px;">
          <template v-slot:title>
            {{ alertTitle }}
          </template>
          {{ alertText }}
        </v-alert>

        <!-- Formulário de login -->
        <div class="form-group">
          <label for="user">Usuário</label>
          <div class="input-icon">
            <input v-model="login" type="text" class="form-control" @keyup.enter.prevent="validaUser()" />
            <span class="input-icon-addon">
              <i class="fa fa-user"></i>
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="senha">Senha</label>
          <div class="input-icon">
            <input v-model="senha" type="password" class="form-control" @keyup.enter.prevent="validaUser()" />
            <span class="input-icon-addon">
              <i class="fa fa-lock"></i>
            </span>
          </div>
        </div>
        
        <div class="row mb-3">
          <div class="col-md-12 text-center">
            <button class="btn btn-primary btn-block" style="width: 100%;" @click="validaUser()">Acessar</button>
          </div>
        </div>
        
        <div class="row mb-3">
          <div class="col-md-12 text-right">
            <a href="#" class="btn btn-link" @click="dialog = true;">Esqueci minha senha</a>
          </div>
        </div>
        
        <div class="row mb-3" v-show="false">
          <div class="col-md-12 text-center">
            <label for="user">Ainda não possui cadastro?</label>
            <button class="btn btn-success" style="width: 100%;">Cadastre-se</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Reset Senha -->
    <v-dialog v-model="dialog" persistent width="600">
      <v-card style="padding: 20px;">
        <v-card-title>
          <span class="text-h6">Reset Senha</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <div class="row">
                <div class="col-md-12 col-lg-12">
                      <label for="senha">Digite seu E-mail</label>
                      <div class="input-icon">
                        <input v-model="resetEmail" type="text" class="form-control"/>
                        <span class="input-icon-addon">
                          <i class="fa fa-envelope"></i>
                        </span>
                      </div>
                </div>
                <div class="col-md-12 col-lg-12" v-if="UserReset!='' && UserReset.length > 1" style="padding-top: 20px;">
                     <label>Para qual usuário deseja Reset de senha?</label>
                    <select  v-model="userSelectecd" class="form-select form-control">
                        <option :value=null>Selecione</option>
                        <option v-for="optionTipo in UserReset" :key="optionTipo.tpdId" 
                            :value="optionTipo">{{ optionTipo.cusNome }}</option>
                    </select>
                </div>
             </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
             <v-btn color="blue-darken-1" variant="text" @click="Cancel()" style="padding-right: 20px; text-transform:none !important;">Cancelar</v-btn>
             <a class="btn btn-primary btn-lg btn-block" style="width: 100px; padding-right: 10px;" href="#" @click="EnviaReset();">Enviar</a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { SHA256 } from 'crypto-js';


export default {
  name: 'LoginView',
  components: {
    moment,
  },
  data: () => ({
    dialog: false,
    login: '',
    senha: '',
    User: {},
    resetEmail:'',
    UserReset:[],
    userSelectecd:null,
    templateEmail:[],
    TelasUser:[],
    DadosGarantidora: [],
    ListaMeusContratos:[],
    libera:[],
    showAlert: false, // Controle de visibilidade do alerta
    alertTitle: "", // Título do alerta
    alertText: "", // Texto do alerta
    alertType: "", // Tipo do alerta (success, warning, error, etc.)
  }),
  methods: {
    validaUser() {
      if (this.login == '' || this.login == null) {
        this.$mensagemErro("Usuário obrigatório.")
      }
      else if (this.senha == '' || this.senha == null) {
        this.$mensagemErro("Senha obrigatória.")
      }
      else {
        const param1 = this.login;
        const param2 = this.senha;
        const param3 = "0";

        axios.get(`/Process/UsrCadastroUsuarios/GetByPassword/${param1}/${param2}/${param3}`)
          .then(response => {
              this.User = response.data; 
             // console.log('this.User')
             // console.log(this.User)
              if (this.User != null){
                const param4 = this.User.cusId;
                const param5 = this.User.admTipoUsuario.tusId;
                const param6 = this.User.GarId == null ? 0 : this.User.GarId;
                axios.get(`/Process/UsrControlePermissoes/GetAllByUserId/${param4}/${param5}/${param6}`)
                    .then(response => {
                        this.TelasUser = response.data;
                        let _dadoUser = {
                          nome: this.User.cusNome,
                          urlimage: this.User.cusUrlImagem,
                          tipo:this.User.admTipoUsuario.tusId,
                          garid:this.User.garId == null ? 0 : this.User.garId,
                          tpeid:this.User.tpeId == null ? 0 : this.User.tpeId,
                          pesid:this.User.pesId == null ? 0 : this.User.pesId,
                          cusid:this.User.cusId,
                          menu: this.TelasUser
                        }

                        if (_dadoUser.garid != 0 && _dadoUser.tpeid == 1){

                            axios.get(`/Process/GarGarantidoraContrato/GetByGarId/` + _dadoUser.garid) 
                                .then(response => {
                                    this.ListaMeusContratos = response.data; 

                                    this.libera = this.ListaMeusContratos.filter(x=> x.gctDataAss === null); 
                                    //console.log('this.libera')
                                    //console.log(this.libera)
                                    if (this.libera.length != 0)
                                    {
                                      this.alertTitle = "Assinatura Pendente";
                                      this.alertText = "Identificamos assinatura de contrato pendente, verifique seu e-mail ou entre em contato com suporte GarantePay.";
                                      this.alertType = "warning";
                                      this.showAlert = true;
                                    }   
                                    else{
                                            this.$Cookies.set('_vusavgar', this.$CookieUsuario, this.$CookiesDefaults);
                                            localStorage.setItem('_useravgar', JSON.stringify(_dadoUser));
                                            this.$router.push('/Home')
                                          }                   
                                });
                            }
                            else if (_dadoUser.garid != 0 && _dadoUser.tpeid == 3)
                            {
                                axios.get(`/Process/ImoImobiliariaContrato/GetByImoId/` + + _dadoUser.pesid) 
                                      .then(response => {
                                          this.ListaMeusContratos = response.data;       
                                          this.libera = this.ListaMeusContratos.filter(x=> x.ictDataAss === null); 
                                            if (this.libera.length != 0)
                                            {
                                              this.alertTitle = "Assinatura Pendente";
                                              this.alertText = "Identificamos assinatura de contrato pendente, verifique seu e-mail ou entre em contato com suporte da sua Garantidora.";
                                              this.alertType = "warning";
                                              this.showAlert = true;
                                            } 
                                            else{
                                              this.$Cookies.set('_vusavgar', this.$CookieUsuario, this.$CookiesDefaults);
                                              localStorage.setItem('_useravgar', JSON.stringify(_dadoUser));
                                              this.$router.push('/Home')
                                            }                                 
                                      });
                            }
                            else{
                              this.$Cookies.set('_vusavgar', this.$CookieUsuario, this.$CookiesDefaults);
                              localStorage.setItem('_useravgar', JSON.stringify(_dadoUser));
                              this.$router.push('/Home')
                            }

                  });
              }
              else{
                this.$mensagemErro("Usuário Inválido.")
              }
            
          });
      }
    },
    EnviaReset() {
      if (this.resetEmail == '' || this.resetEmail == null) {
        this.$mensagemErro("Digite e-mail cadastrado.")
      }
      else {
          const param1 = this.resetEmail;
          axios.get(`/Process/UsrCadastroUsuarios/GetByEmail/${param1}`).then(response => {
                this.UserReset = response.data;  
                console.log('this.UserReset')
                console.log(this.UserReset)
                //verficar se selected do tipo de pessoa esta preenchido e filtrar o UserReset para ficar com length == 1
                if (this.UserReset.length == 1) {
                  console.log('aqui')
                  console.log(this.UserReset)
                  this.EnviaEmail(this.UserReset[0].cusNome, this.UserReset[0].cusEmail, this.UserReset[0].cusId);
                }
                else if (this.UserReset.length > 1 && this.userSelectecd != null){
                  console.log('aqui2')
                  console.log(this.userSelectecd)
                  this.EnviaEmail(this.userSelectecd.cusNome, this.userSelectecd.cusEmail, this.userSelectecd.cusId);
                }
                if (this.UserReset.length == 0) {
                  this.$mensagemErro("E-mail não cadastrado")
                }
          });
      }
    },
    Cancel(){
            //window.location.reload();
            this.$router.go(0);
    },
    EnviaEmail(_nomePara, _destinatario, _id){
              axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 2);
                               const encrypted = SHA256(_destinatario).toString();
                               let _dadosEmail = {
                                            nomeDe: this.$GarantePayNome,
                                            nomePara: _nomePara,
                                            assunto: 'Reset de senha - Sistema GarantePay',
                                            destinatario: _destinatario,
                                            emailResposta: this.$GarantePayEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomeUsuario]',_nomePara)
                                                        .replace('[SenhaReset]', this.$GarantePayLinkAcesso + 'resetsenha/' + _id + '/' + encrypted)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail)
                                  .then(response => {
                                        if (response.data == 1){
                                          this.$mensagemSucesso("Link para reset de senha enviado para o seu e-mail! ");
                                          this.resetEmail = "";
                                          this.dialog = false;
                                        }
                                 });
                      });
    },
 },
  created() {

      //dados da Garantidora
    /*  axios.get('/Process/InsInstitucional/institucional/34/informacoes')
                .then(response => {
                    this.DadosGarantidora = response.data;
                   // console.log('this.DadosGarantidora')
                   // console.log(this.DadosGarantidora)
                })
                .catch(error => {
                    console.error(error);
                });*/
   
  }
}
</script>

<style>

.v-card-actions {
  align-items: center;
  display: flex;
  flex: none;
  min-height: 52px;
  padding: 0.5rem;
  padding-right: 20px !important;
}

.vh-100 {
  height: 100vh; /* Define a altura como 100% da viewport */
}

.d-flex {
  display: flex; /* Ativa o Flexbox */
}

.justify-content-center {
  justify-content: center; /* Centraliza horizontalmente */
}

.align-items-center {
  align-items: center; /* Centraliza verticalmente */
}

.card {
  border: none; /* Remove a borda do card */
  border-radius: 10px; /* Bordas arredondadas */
  background-color: #fff; /* Cor de fundo branca */
}

.btn-primary {
  background-color: #28a745; /* Cor verde personalizada */
  border: none;
  padding: 10px;
  font-size: 16px;
}

.btn-primary:hover {
  background-color: #218838; /* Tom mais escuro ao passar o mouse */
}

.btn-link {
  color: #007bff;
  font-size: 14px;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.form-group label {
  font-weight: bold;
}

.form-control {
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

img {
  border-radius: 10px;
}

.text-center {
  text-align: center;
}
</style>
