<template>
    <div>
        <!--Edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Parceiros</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de parceiros</a>
                        </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#dadcdf">
                                    <v-tab value="dados" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Dados Parceiro</v-tab>
                                    <div v-if="Parceiro.parId != 0">
                                        <v-tab value="endereco" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Endereço</v-tab>
                                    </div>
                                    <div v-if="Parceiro.parId != 0">
                                        <v-tab value="contrato" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Contrato</v-tab>
                                    </div>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item value="dados">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Parceiro.parId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="User.garid == 0">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <select  v-model="Parceiro.garId" class="form-select form-control" :disabled="User.tpeid === 1 || User.tpeid === 2">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in ListGarantidorasGeral" :key="optionTipo.garId"
                                                                    :value="optionTipo.garId">{{ optionTipo.garNomeEmpresa }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Tipo Pessoa</label>
                                                            <select  v-model="Parceiro.tpdId" class="form-select form-control" :disabled="User.tpeid == 2">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in TiposDocumentos" :key="optionTipo.tpdId"
                                                                    :value="optionTipo.tpdId">{{ optionTipo.tpdNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Nº {{ Parceiro.tpdId == 1 ? 'CPF' : 'CNPJ'}}</label>
                                                            <input v-model="Parceiro.parDocumento" v-mask="documentoMask" type="text" :disabled="User.tpeid == 2"
                                                                class="form-control" :placeholder="Parceiro.tpdId == 1 ? 'CPF' : 'CNPJ'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Nome</label>
                                                            <input v-model="Parceiro.parNomeEmpresa" type="text"
                                                                class="form-control" :placeholder="Parceiro.tpdId == 1 ? 'Nome' : 'Nome Fantasia'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Parceiro.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Nome Responsável</label>
                                                            <input v-model="Parceiro.parResponsavel" type="text"
                                                                class="form-control" placeholder="Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Parceiro.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>CPF Responsável</label>
                                                            <input v-model="Parceiro.parCampoExtra1" type="text" v-mask="'###.###.###-##'"
                                                                class="form-control" placeholder="CPF Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5" v-if="Parceiro.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Razão Social</label>
                                                            <input v-model="Parceiro.parRazaoSocial" type="text"
                                                                class="form-control" placeholder="Razão Social" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Telefone</label>
                                                            <input v-model="Parceiro.parTelefone" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Telefone" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Whatsapp</label>
                                                            <input v-model="Parceiro.parWhatsapp" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Whatsapp" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>E-mail</label>
                                                            <input v-model="Parceiro.parEmail" type="text" class="form-control" :disabled="User.tpeid == 2"
                                                                placeholder="E-mail" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Creci</label>
                                                            <input v-model="Parceiro.parCreci" type="text" class="form-control"
                                                                placeholder="Creci" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereço-->
                                    <v-window-item value="endereco">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Endereco.paeId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Parceiro</label>
                                                            <input v-model="Endereco.parId" type="text"
                                                                class="form-control" placeholder="Id Parceiro" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>CEP</label>
                                                            <input v-model="Endereco.paeCep" v-mask="'#####-###'" type="text"
                                                                class="form-control" placeholder="CEP" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group" style="padding-top: 35px;">
                                                            <button type="button" class="btn btn-info" @click.prevent="Buscacep(Endereco.paeCep)" style="color:#ffffff;">
                                                                Verificar CEP
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Endereço</label>
                                                            <input v-model="Endereco.paeEndereco" type="text"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Número</label>
                                                            <input v-model="Endereco.paeNumero" type="number"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Complemento</label>
                                                            <input v-model="Endereco.paeComplemento" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Estado</label>
                                                            <input v-model="Endereco.paeEstado" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Cidade</label>
                                                            <input v-model="Endereco.paeCidade" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Bairro</label>
                                                            <input v-model="Endereco.paeBairro" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10" v-if="showend">
                                                        <div class="form-group">
                                                            <button v-if="Endereco.paeId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="EnderecoPut()">
                                                                Alterar Endereço
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="EnderecoAdd()">
                                                                Salvar endereço
                                                            </button>
                                                            <a href="#" @click="EnderecoCancel()" class="btn btn-link">Cancelar Endereço</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereco-->
                                    <!--Contratos-->
                                    <v-window-item value="contrato">
                                        <v-form>
                                            <v-container>
                                                <div class="row" v-if="User.garid == 0 || User.tpeid == 1">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Contrato.gctId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Modelos de Contrato</label>
                                                            <select  v-model="Contrato.cmcId" class="form-select form-control">
                                                                <option value="">Selecione</option>
                                                                <option v-for="optionTipo in ListaContratos" :key="optionTipo.cmcId"
                                                                    :value="optionTipo.cmcId">{{ optionTipo.cmcNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label></label>
                                                            <div>
                                                                <button type="button" class="btn btn-sm btn-success" 
                                                                 style="color:#ffffff;margin-top:12px;" @click="ContratoAdd()">Adicionar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <v-form>
                                                        <v-container>
                                                            <v-row>
                                                                <v-table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Contrato
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Status
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Data Assinatura
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Actions
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="item in ListaMeusContratos"
                                                                            :key="item.gctId">
                                                                            <td>{{ ListaContratos.filter(x=> x.cmcId == item.cmcId)[0].cmcNome }}</td>
                                                                            <td>{{ item.pctStatusEnvelope == null ? 'Não enviado p/assinatura' :  item.pctDataAss == null ? 'Envelope encaminhado para coleta de assinaturas!' : 'Assinado' }}</td>
                                                                            <td>{{ item.pctDataAss }}</td>
                                                                            <td style="text-align:center;">
                                                                                <v-btn icon @click.prevent="VisualizarContrato(item)"
                                                                                    variant="text">
                                                                                    <v-icon>mdi-eye</v-icon>
                                                                                    <v-tooltip activator="parent"
                                                                                        location="top">Visualizar</v-tooltip>
                                                                                </v-btn>
                                                                                <v-btn v-if="item.pctStatusEnvelope == null" class="btn btn-sm btn-info" @click.prevent="EnviarAssinatura(item)" variant="text">
                                                                                     Enviar p/ Assinatura
                                                                                </v-btn>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </v-table>
                                                            </v-row>
                                                        </v-container>
                                                    </v-form>
                                                </div>
                                            </v-container>
                                        </v-form>
                                       </v-window-item>
                                         <!--Contratos-->
                                </v-window>
                            </div>
                        </div>
                    </div>
                    <div class="card-action" v-if="tab=='dados'">
                        <button v-if="Parceiro.parId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                            @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de parceiros</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="ListParceiros"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>
                                        <v-btn icon @click="DeleteGrid(item)" variant="text" v-if="User!='' && User.garid == 0">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'ParceirosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        EditView: false,
        User:[],
        ListGarantidorasGeral:[],
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Parceiros',
        InfoTela:'Lista de Parceiros',
        MostraGrid: true,
        headers: [],
        //dados
        Parceiro: {
            parId: 0,
            parVisivel: null,
            parDataCadastro: null,
            parNomeEmpresa: '',
            parRazaoSocial: '',
            tpdId: null,
            parDocumento: '',
            parTelefone: '',
            parWhatsapp: '',
            parResponsavel: '',
            parEmail: '',
            parAtivo: null,
            parDataAniversario: null,
            parCampoExtra1: '',
            parCampoExtra2: '',
            parCampoExtra3: '',
            parCampoExtra4: '',
            parCampoExtra5: '',
            parTexto1: '',
            parTexto2: '',
            parTexto3: '',
            parData1: null,
            parData2: null,
            parCreci: '',
            garId: null,
        },
        ListParceiros: [],
        ListParceirosGeral:[],
        TiposDocumentos:[],
        //dados
        //imagens
        selectedFile: null,
        //endereco
        Endereco: {
            paeId: 0,
            grtId: 0,
            cdtId: 0,
            paeEndereco: '',
            paeNumero: 0,
            paeComplemento: '',
            paeObsEndereco: '',
            paeCep: '',
            paePais: '',
            paeEstado: '',
            paeCidade: '',
            paeBairro: '',
            parId: null
        },
        ListaEnderecos:[],
        showend:false,
        Retornocep:null,
        //endereco
        //contrato
        Contrato: {
            pctId: 0,
            grtId: 0,
            cdtId: 0,
            parId: null,
            cmcId: '',
            pctUrlContrato: null,
            pctIp: null,
            pctDataAss: null, // Data inicializada como null
            pctRetornoJson: null,
            pctIdenvelope: null,
            pctStatusEnvelope: null
        },
      ListaContratos:[],
      ListaMeusContratos:[],
      ListUser:[],
        //contrato
        Garantidora: {
            garId: 0,
            garVisivel: null,
            garDataCadastro: null,
            garNomeEmpresa: '',
            garRazaoSocial: '',
            tpdId: null,
            garDocumento: '',
            garTelefone: '',
            garWhatsapp: '',
            garResponsavel: '',
            garEmail: '',
            garAtivo: null,
            garDataAniversario: null,
            garCampoExtra1: '',
            garCampoExtra2: '',
            garCampoExtra3: '',
            garCampoExtra4: '',
            garCampoExtra5: '',
            garTexto1: '',
            garTexto2: '',
            garTexto3: '',
            garData1: null,
            garData2: null,
            garUrlLogo: ''
        },
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListParceiros = this.ListParceirosGeral;
        },
        //imagens
        //parantidora
         //edit
        EditNew(){
        this.EditView = true;
        this.Parceiro.parId = 0;
        this.Parceiro.parVisivel= null;
        this.Parceiro.parDataCadastro= null;
        this.Parceiro.parNomeEmpresa= '';
        this.Parceiro.parRazaoSocial= '';
        this.Parceiro.tpdId= null;
        this.Parceiro.parDocumento= '';
        this.Parceiro.parTelefone= '';
        this.Parceiro.parWhatsapp= '';
        this.Parceiro.parResponsavel= '';
        this.Parceiro.parEmail= '';
        this.Parceiro.parAtivo= null;
        this.Parceiro.parDataAniversario= null;
        this.Parceiro.parCampoExtra1= '';
        this.Parceiro.parCampoExtra2= '';
        this.Parceiro.parCampoExtra3= '';
        this.Parceiro.parCampoExtra4= '';
        this.Parceiro.parCampoExtra5= '';
        this.Parceiro.parTexto1= '';
        this.Parceiro.parTexto2= '';
        this.Parceiro.parTexto3= '';
        this.Parceiro.parData1= null;
        this.Parceiro.parData2= null;
        this.Parceiro.parCreci= '';
        this.Parceiro.garId= this.User.garid == 0? null : this.User.garid;
        //endereco
        this.Endereco.paeId = 0;
        this.Endereco.grtId = 0;
        this.Endereco.cdtId = 0;
        this.Endereco.paeEndereco = '';
        this.Endereco.paeNumero = 0;
        this.Endereco.paeComplemento = '';
        this.Endereco.paeObsEndereco = '';
        this.Endereco.paeCep = '';
        this.Endereco.paePais = '';
        this.Endereco.paeEstado = '';
        this.Endereco.paeCidade = '';
        this.Endereco.paeBairro = '';
        this.showend = false;
        this.Retornocep = null;
        //contrato
        this.Contrato.pctId = 0;
        this.Contrato.grtId = 0;
        this.Contrato.cdtId = 0;
        this.Contrato.cmcId = '';
        this.Contrato.pctUrlContrato = null;
        this.Contrato.pctIp = null;
        this.Contrato.pctDataAss = null; // Data inicializada como null
        this.Contrato.pctRetornoJson = null;
        this.Contrato.pctIdenvelope = null;
        this.Contrato.pctStatusEnvelope = null;
        this.ListaMeusContratos= [];
        this.tab = 'dados';
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        EditGrid(item) {
            this.EditView = true;
            this.tab = 'dados';
            console.log('item')
            console.log(item)
            if (item.parId != '') {
                // Lógica para editar o item
                 axios.get(`/Process/ParParceiros/GetById/` + item.parId) 
                    .then(response => {
                        this.Parceiro = response.data;

                        //carrepar endereços
                        this.CarregaEndereco(item.parId);

                        //carrega contratos
                        this.CarregaContratos(item.parId)

                    });

              
            }
        },
        async Add() {
               if (this.Parceiro.garId === '' || this.Parceiro.garId === null) {
                    this.$mensagemAviso("Campo Garantidora Obrigatório.")
                }
                else if (this.Parceiro.tpdId === '' || this.Parceiro.tpdId === null) {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
                }
                else if (this.Parceiro.parDocumento === '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
                else if (this.Parceiro.parNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
              
                else if (this.Parceiro.parTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Parceiro.parWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Parceiro.parEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Parceiro.parResponsavel === '' && this.Parceiro.tpdId == 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Parceiro.parCampoExtra1 === '' && this.Parceiro.tpdId == 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
                else {
                    if (this.Parceiro.parVisivel === "" || this.Parceiro.parVisivel === null) {
                        this.Parceiro.parVisivel = true;
                    }
                    if (this.Parceiro.parAtivo === "" || this.Parceiro.parAtivo === null) {
                        this.Parceiro.parAtivo = true;
                    }

                    this.Parceiro.parDataCadastro = this.$moment().format('YYYY-MM-DD')
                    axios.post(`/Process/ParParceiros/Add`, this.Parceiro)
                        .then(response => {
                            if (response.data > 0) {
                                this.Parceiro.parId = response.data;
                                 //carrepar endereços
                                this.CarregaEndereco(this.Parceiro.parId);

                                //carrega contratos
                                this.CarregaContratos(this.Parceiro.parId)
                                this.$mensagemAvisoSucesso("Registro Incluído. abas para informações adicionais liberadas.")
                            }
                        });
                
            }
        },
        Put() {
            if (this.Parceiro.garId === '' || this.Parceiro.garId === null) {
                    this.$mensagemAviso("Campo Garantidora Obrigatório.")
                }
                else if (this.Parceiro.tpdId === '' || this.Parceiro.tpdId === null) {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
                }
                else if (this.Parceiro.parDocumento === '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
                else if (this.Parceiro.parNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
              
                else if (this.Parceiro.parTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Parceiro.parWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Parceiro.parEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Parceiro.parResponsavel === '' && this.Parceiro.tpdId == 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Parceiro.parCampoExtra1 === '' && this.Parceiro.tpdId == 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
            else {
                if (this.Parceiro.parVisivel === "" || this.Parceiro.parVisivel === null) {
                        this.Parceiro.parVisivel = true;
                    }
                    if (this.Parceiro.parAtivo === "" || this.Parceiro.parAtivo === null) {
                        this.Parceiro.parAtivo = true;
                    }
             
                axios.put(`/Process/ParParceiros/Update`, this.Parceiro)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        CriarUsuarioEmail(){
            if (this.Contrato.cmcId === ''){
                this.$mensagemAviso("Campo Contrato - Modelo de contrato Obrigatório.")
            }
            else{
                axios.get(`/Process/UsrCadastroUsuarios/GetByUserEmail/` + this.Parceiro.parEmail)
                .then(response => {
                    this.ListUser = response.data;
                    if (this.ListUser.length > 0)
                    {
                        var result = this.ListUser.filter(x=> x.tpeId == 2);

                        if (result != null){
                            this.$mensagemAviso("Usuário já possui cadastro ativo.")
                        }
                        else{
                            var _usuario = {
                                cusId: 0,
                                cusNome: this.Parceiro.parNomeEmpresa,
                                cusEmail: this.Parceiro.parEmail,
                                cusTelefone: this.Parceiro.parTelefone,
                                cusWhatsapp: this.Parceiro.parWhatsapp,
                                cusSenha: this.Parceiro.parWhatsapp.slice(-4),
                                cusDtCadastro: this.$moment(),
                                tusId: 7,
                                cusAtivo: 1,
                                cusUrlImagem: '',
                                cliId: 0,
                                admTipoUsuario: {tusId: 7},
                                tpeId:2,
                                parId: this.Parceiro.parId,
                                pesId: this.Parceiro.parId,
                            }
                            axios.post(`/Process/UsrCadastroUsuarios/Add`, _usuario)
                                .then(response => {
                                    if (response.data > 0) {
                                        _usuario.cusId = response.data;
                                    this.EnviaEmailCadastro(_usuario);
                                    }
                                });
                        }

                    }
                });
            }
         
        },
        EnviaEmailCadastro(usuariocad){
              axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 5);
                            let _garNomeEmpresa = this.ListGarantidorasGeral.filter(x=> x.garId == this.User.garid)[0].garNomeEmpresa;
                            let _dadosEmail = {
                                            nomeDe: this.$GarantePayNome,
                                            nomePara: usuariocad.cusNome,
                                            assunto: 'Cadastro Parceiro - Sistema GarantePay',
                                            destinatario: usuariocad.cusEmail,
                                            emailResposta: this.$GarantePayEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomeParceiro]',this.Parceiro.parNomeEmpresa)
                                                        .replace('[NomeUsuario]',this.Parceiro.parResponsavel)
                                                        .replace('[NomeGarantidora]', _garNomeEmpresa)
                                                        .replace('[LinkAcesso]', this.$GarantePayLinkAcesso)
                                                        .replace('[UsuarioLogin]', usuariocad.cusEmail)
                                                        .replace('[SenhaAcesso]', usuariocad.cusSenha)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        //edit
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.parId;
                axios.delete(`/Process/ParParceiros/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "parId", sortable: true, });
            this.headers.push({ text: "Nome", value: "parNomeEmpresa", sortable: true, });
            this.headers.push({ text: "Documento", value: "parDocumento", sortable: true, });
            this.headers.push({ text: "Whatsapp", value: "parWhatsapp", sortable: true, });
            this.headers.push({ text: "Telefone", value: "parTelefone", sortable: true, });
            this.headers.push({ text: "E-mail", value: "parEmail", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        //parantidora
        //endereco
        CarregaEndereco(_parId){
            axios.get(`/Process/ParParceiroEndereco/GetByParId/` + _parId) 
                .then(response => {
                    if (response.data.length > 0){
                        this.Endereco = response.data[0];
                        this.showend = true;
                    }
                    else{
                        this.Endereco.parId = _parId;
                    }                           
                });
        },
        Buscacep(value) {
            if (value == ""){
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else{
                fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                    if (this.Retornocep != null) {
                        this.Endereco.paeEndereco = this.Retornocep.logradouro;
                        this.Endereco.paeNumero = null;
                        this.Endereco.paeComplemento = null;
                        this.Endereco.paeEstado = this.Retornocep.uf;
                        this.Endereco.paeCidade = this.Retornocep.localidade;
                        this.Endereco.paeBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        this.$mensagemAviso("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }
        },
        EnderecoAdd(){
            if (this.Endereco.paeCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.paeEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.paeNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.paeEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.paeCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.paeBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                        axios.post(`/Process/ParParceiroEndereco/Add`, this.Endereco)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Endereco.paeId = response.data;
                                    this.$mensagemAvisoSucesso("Endereço Incluído")
                                }
                            });
                }
        },
        EnderecoPut(){
            if (this.Endereco.paeCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.paeEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.paeNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.paeEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.paeCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.paeBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                    axios.put(`/Process/ParParceiroEndereco/Update`, this.Endereco)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Endereço Alterado.")
                        }
                    });
                }
        },
        EnderecoCancel(){
            /*this.Endereco.grtId = 0;
            this.Endereco.cdtId = 0;
            this.Endereco.gaeEndereco = '';
            this.Endereco.gaeNumero = null;
            this.Endereco.gaeComplemento = '';
            this.Endereco.gaeObsEndereco = '';
            this.Endereco.gaeCep = '';
            this.Endereco.gaePais = '';
            this.Endereco.gaeEstado = '';
            this.Endereco.gaeCidade = '';
            this.Endereco.gaeBairro = '';
            this.showend = false;*/
            this.$router.go(0);
        },
        //endereco
         //contratos
         CarregaContratos(_parId){
            axios.get(`/Process/ParParceiroContrato/GetByParId/` + _parId) 
                .then(response => {
                    this.ListaMeusContratos = response.data;                        
                });
        },
        async ContratoAdd(){
               if (this.Contrato.cmcId === '') {
                    this.$mensagemAviso("Campo Modelo Contrato Obrigatório.")
                }
                else {
                    //dadosContratante = garantidora e dadosContratada = parceiro
                    var _dadosContrato = {
                            TipoPessoa: 2,
                            CmcId: this.Contrato.cmcId,
                            IdContratante: this.Parceiro.garId,
                            IdContratada: this.Parceiro.parId,
                            dadosContratacao: null
                        }

                        var _fileName = await this.Uploadpdf(_dadosContrato);

                        this.Contrato.parId = this.Parceiro.parId;
                        this.Contrato.pctUrlContrato = _fileName;

                        axios.post(`/Process/ParParceiroContrato/Add`, this.Contrato)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Contrato.pctId = response.data;
                                    this.CarregaContratos(this.Contrato.parId);
                                    this.$mensagemAvisoSucesso("Contrato Incluído")
                                }
                            });
                }
        },
        ContratosPut(){
                 if (this.Contrato.cmcId === '') {
                    this.$mensagemAviso("Campo Modelo Contrato Obrigatório.")
                }
                else {
                    axios.put(`/Process/ParParceiroContrato/Update`, this.Contrato)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Contrato Alterado.")
                        }
                    });
                }
        },
        ContratosCancel(){
            /*this.Contrato.grtId = 0;
            this.Contrato.cdtId = 0;
            this.Contrato.cmcId = null;
            this.Contrato.gctUrlContrato = '';
            this.Contrato.gctIp = '';
            this.Contrato.gctDataAss = null;
            this.Contrato.gctRetornoJson = '';*/
            this.$router.go(0);
        },
        VisualizarContrato(_contrato){
            if (_contrato && _contrato.pctUrlContrato) {
            // Abre o PDF em uma nova aba
            window.open(this.$imgURL + _contrato.pctUrlContrato, '_blank');
            } else {
                console.error("URL do contrato não está disponível.");
            }
        },
        async Uploadpdf(dadosContrato) {
            const uploadResult = await axios.post(`/Process/UploadImg/uploadContratoPdf`, dadosContrato);
            return uploadResult.data.nameFile;
        },
        async EnviarAssinatura(item){
            axios.get(`/Process/GarGarantidoraAssinatura/GetByGarId/` + this.Parceiro.garId) 
                .then(response => {
                    if (response.data.length > 0){
                      this.Assinatura = response.data[0]; 
                      this.Garantidora = this.ListGarantidorasGeral.filter(x=> x.garId == this.Parceiro.garId)[0];
                      
                        let idContrato = item.pctId;
                        let tpeId = 2;
                        let DadosEnvelopeRequest = {
                            token: this.Assinatura.gasToken,
                            envelopeDescricao: this.ListaContratos.filter(x=> x.cmcId == item.cmcId)[0].cmcNome,
                            repositorioId: parseInt(this.Assinatura.gasIdRepositorio),
                            listaDocumentos: [{ UrlArquivo: item.pctUrlContrato}],
                            listaSignatariosEnvelopes: [{
                                Ordem: 1,
                                EmailSignatario: this.Parceiro.parEmail,
                                NomeSignatario: this.Parceiro.parNomeEmpresa,
                                TipoAssinatura: 1
                            },
                            {
                                Ordem: 2,
                                EmailSignatario: this.Garantidora.garEmail,
                                NomeSignatario: this.Garantidora.garNomeEmpresa,
                                TipoAssinatura: 1
                            }]
                        }      
                       
                        axios.post(`/Process/AssinaturasAsten/CreateEnvelope/` + idContrato + '/' + tpeId, DadosEnvelopeRequest)
                            .then(response => {
                                if (response.status == 200) {
                                    this.CarregaContratos(this.Parceiro.parId);
                                    this.$mensagemAvisoSucesso("Assinatura enviada aos signatários");
                                }
                            })
                            .catch(error => {
                                if (error.response) {
                                // Erros retornados pelo servidor
                                this.$mensagemErro(
                                    `Erro ao enviar assinatura (Status ${error.response.status}): ${error.response.data}`
                                );
                                } else if (error.request) {
                                // O servidor não respondeu
                                this.$mensagemErro("Erro: Nenhuma resposta do servidor.", error.request);
                                } else {
                                // Erro ao configurar a requisição
                                this.$mensagemErro("Erro ao configurar requisição:", error.message);
                                }
                            });
                    }                 
                });
        },
        //contratos
        Get() {
            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }
            //console.log('this.User')
            //console.log(this.User)

            if (this.User.garid == 0){ //garantepay
                this.EditView = false;
                axios.get(`/Process/ParParceiros/GetAll`)
                .then(response => {
                    this.ListParceirosGeral = response.data.filter(x=> x.parAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else if (this.User.tpeid == 1){ //garantidora
                this.EditView = false;
                axios.get(`/Process/ParParceiros/GetByGarId/` + this.User.pesid)
                .then(response => {
                    this.ListParceirosGeral = response.data.filter(x=> x.parAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else if (this.User.tpeid == 2){ //parceiro mesmo
                var _item = {parId : this.User.pesid}
                this.EditGrid(_item);
            }    

            axios.get(`/Process/CadTipoDocumento/GetAll`) 
                .then(response => {
                    this.TiposDocumentos = response.data.filter(x=> x.tpdAtivo);                     
                });

            axios.get(`/Process/CadModelosContrato/GetAll`)
                .then(response => {
                    this.ListaContratos = response.data.filter(x=> x.tpeId == 2 && x.cmcAtivo);
                });

            axios.get(`/Process/GarGarantidoras/GetAll`)
                .then(response => {
                    this.ListGarantidorasGeral = response.data.filter(x=> x.garAtivo);
                });
        }
    },
    created() {
        this.Get();
    },
    computed: {
        documentoMask() {
        return this.Parceiro.tpdId === 1 ? '###.###.###-##' : '##.###.###/####-##';
        }
   }
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
