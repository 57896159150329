<template>
  <div class="hidden-sm hidden-xs">
    <div class="main-header" v-if="User!=''"> 
        <!-- Navbar Header -->
        <nav class="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom">
                <div class="container-fluid" style="justify-content: end;">
                  <ul class="navbar-nav topbar-nav align-items-center">
                    <!-- chamados -->
                    <li class="nav-item topbar-icon dropdown hidden-caret" v-show="false">
                      <a
                        class="nav-link"
                        href="#"
                        id="messageDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="toggleDropdownChamados()"
                      >
                        <i class="fa fa-envelope"></i>
                      </a>
                      <ul class="dropdown-menu messages-notif-box animated fadeIn" v-if="isDropdownOpenChamados">
                        <li>
                          <div
                            class="dropdown-title d-flex justify-content-between align-items-center">
                            Chamados
                          </div>
                        </li>
                        <li>
                          <div class="message-notif-scroll scrollbar-outer">
                            <div class="notif-center">
                              <a href="#">
                                
                                <div class="notif-content">
                                  <span class="subject">Chamado 1</span>
                                  <span class="time">Ver chamado</span>
                                </div>
                              </a>
                              <a href="#">
                                
                                <div class="notif-content">
                                  <span class="subject">Chamado 2</span>
                                  <span class="time">Ver chamado</span>
                                </div>
                              </a>
                              <a href="#">
                                
                                <div class="notif-content">
                                  <span class="subject">Chamado 3</span>
                                  <span class="time">Ver chamado</span>
                                </div>
                              </a>
                              <a href="#">
                                
                                <div class="notif-content">
                                  <span class="subject">Chamado 4</span>
                                  <span class="time">Ver chamado</span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a class="see-all" href="javascript:void(0);"
                            >Ver todos os chamados<i class="fa fa-angle-right"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <!-- duvidas frequentes -->
                    <li class="nav-item topbar-icon dropdown hidden-caret" v-show="false">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        id="notifDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="toggleDropdownDuvidas()"
                      >
                        <i class="fa fa-question"></i>
                        
                      </a>
                      <ul class="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown" v-if="isDropdownOpenDuvidas">
                        <li>
                          <div class="dropdown-title">
                            Dúvidas frequentes
                          </div>
                        </li>
                        <li>
                          <div class="notif-scroll scrollbar-outer">
                            <div class="notif-center">
                              <a href="#">
                                <div class="notif-icon notif-success">
                                  <i class="fa fa-comment"></i>
                                </div>
                                <div class="notif-content">
                                  <span class="block"> Lorem, ipsum dolor. </span>
                                  <span class="time">Ver</span>
                                </div>
                              </a>
                              <a href="#">
                                <div class="notif-icon notif-success">
                                  <i class="fa fa-comment"></i>
                                </div>
                                <div class="notif-content">
                                  <span class="block">
                                    Lorem ipsum dolor sit.
                                  </span>
                                  <span class="time">Ver</span>
                                </div>
                              </a>
                              <a href="#">
                                <div class="notif-icon notif-success">
                                  <i class="fa fa-comment"></i>
                                </div>
                                <div class="notif-content">
                                  <span class="block">
                                    Lorem ipsum dolor sit amet.
                                  </span>
                                  <span class="time">Ver</span>
                                </div>
                              </a>
                              <a href="#">
                                <div class="notif-icon notif-success">
                                  <i class="fa fa-comment"></i>
                                </div>
                                <div class="notif-content">
                                  <span class="block"> Lorem, ipsum dolor. </span>
                                  <span class="time">Ver</span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a class="see-all" href="javascript:void(0);"
                            >Ver todas as dúvidas<i class="fa fa-angle-right"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <!-- ações rápidas -->
                    <li class="nav-item topbar-icon dropdown hidden-caret">
                      <a
                        class="nav-link"
                        data-bs-toggle="dropdown"
                        href="#"
                        aria-expanded="false"
                        @click="toggleDropdownAcoes()"
                      >
                        <i class="fas fa-layer-group"></i>
                      </a>
                      <div class="dropdown-menu quick-actions animated fadeIn" v-if="isDropdownOpenAcoes">
                        <div class="quick-actions-header">
                          <span class="title mb-1">Ações Rápidas</span>
                        </div>
                        <div class="quick-actions-scroll scrollbar-outer">
                          <div class="quick-actions-items">
                            <div class="row m-0">
                              <router-link v-if="User.garid == 0 || User.tpeid == 1" to="/garantidoras" class="col-6 col-md-4 p-0" @click="toggleDropdownAcoes()">
                                <div class="quick-actions-item">
                                  <div class="avatar-item bg-danger rounded-circle">
                                    <i class="far fa-address-card"></i>
                                  </div>
                                     <span class="text">Garantidora</span>
                                </div>
                              </router-link>
                              <router-link to="/imobiliarias" class="col-6 col-md-4 p-0" @click="toggleDropdownAcoes()">
                                <div class="quick-actions-item">
                                  <div
                                    class="avatar-item bg-warning rounded-circle"
                                  >
                                    <i class="fas fa-warehouse"></i>
                                  </div>
                                  <span class="text">Imobiliária</span>
                                </div>
                              </router-link>
                              <router-link v-if="User.tpeid == 3" to="/clientes" class="col-6 col-md-4 p-0" @click="toggleDropdownAcoes()">
                                <div class="quick-actions-item">
                                  <div
                                    class="avatar-item bg-success rounded-circle"
                                  >
                                    <i class="fas fa-users"></i>
                                  </div>
                                  <span class="text">Clientes</span>
                                </div>
                              </router-link>
                              <router-link v-if="User.garid == 0 || User.tpeid == 1" to="/parceiros" class="col-6 col-md-4 p-0" @click="toggleDropdownAcoes()">
                                <div class="quick-actions-item">
                                  <div
                                    class="avatar-item bg-success rounded-circle"
                                  >
                                    <i class="fas fa-users"></i>
                                  </div>
                                  <span class="text">Parceiros</span>
                                </div>
                              </router-link>
                              <router-link to="/listacontratacoes" class="col-6 col-md-4 p-0" @click="toggleDropdownAcoes()">
                                <div class="quick-actions-item">
                                  <div class="avatar-item bg-info rounded-circle">
                                    <i class="fas fa-cart-plus"></i>
                                  </div>
                                  <span class="text">Contratação</span>
                                </div>
                              </router-link>
                            
                              <a class="col-6 col-md-4 p-0" href="#">
                                <div class="quick-actions-item">
                                  <div
                                    class="avatar-item bg-primary rounded-circle"
                                  >
                                    <i class="fas fa-chart-bar"></i>
                                  </div>
                                  <span class="text">Relatórios</span>
                                </div>
                              </a>
                              <a class="col-6 col-md-4 p-0" href="#">
                                <div class="quick-actions-item">
                                  <div
                                    class="avatar-item bg-secondary rounded-circle"
                                  >
                                    <i class="fas fa-credit-card"></i>
                                  </div>
                                  <span class="text">Financeiro</span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- meus dados -->
                    <li class="nav-item topbar-user dropdown hidden-caret">
                      <a
                        class="dropdown-toggle profile-pic"
                        data-bs-toggle="dropdown"
                        href="#"
                        aria-expanded="false"
                        @click="toggleDropdownUser()"
                      >
                        <div class="avatar-sm" style="text-align: right;">
                          <img v-if="User.urlimage!='' && User.urlimage!= null"  :src="$imgURL + User.urlimage" alt="Usuário"  class="avatar-img rounded-circle" style="width: 40px; height: auto" />
                          <img v-else  src="/images/userNo.png" alt="Usuário"  class="avatar-img rounded-circle" style="width: 40px; height: auto" />
                        </div>
                        <span class="profile-username">
                          <span class="op-7">Olá,</span>
                          <span class="fw-bold">{{  User.nome }}</span>
                        </span>
                      </a>
                      <ul class="dropdown-menu dropdown-user animated fadeIn" v-if="isDropdownOpenUser">
                        <div class="dropdown-user-scroll scrollbar-outer">
                          <li>
                            <div class="user-box">
                              <div class="avatar-lg">
                                <img
                                  v-if="User.urlimage!='' && User.urlimage!= null"  
                                  :src="$imgURL + User.urlimage"
                                  alt="Usuário"
                                  class="avatar-img rounded"
                                  style="width: 60px; height: auto"
                                />
                                <img v-else  src="/images/userNo.png" alt="Usuário"  style="width: 60px; height: auto" class="avatar-img rounded-circle" />
                              </div>
                              <div class="u-text">
                                <h4>{{  User.nome }}</h4>
                                <router-link :to="'/cadastroUsuarios/' + User.cusid" class="btn btn-xs btn-secondary btn-sm" @click="toggleDropdownUser()">Meus Dados</router-link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item2" style="float: left;" href="" @click="Logout()">Deslogar</a>
                            <a class="dropdown-item2" style="float: right;" href="#" @click="Logout()"><i class="fa fa-angle-right"></i></a>
                          </li>
                        </div>
                      </ul>
                    </li>
                  </ul>
                </div>
        </nav>
    </div>
</div>
<div class="hidden-md hidden-lg">
  <v-toolbar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="flex: 3 1!important;"> 
             <router-link to="/home" class="logo">
                 <img src="/images/logo_light.png" alt="navbar brand" class="navbar-brand" height="20" style="width: 100%;"/>
            </router-link></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon="mdi-account" variant="text"></v-btn>
      <v-btn icon  @click="Logout()">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" style="margin-top: 64px;left:0px ;background: #1a2035 !important;padding-top: 30px;">
      <v-list-item title="Inicio" prepend-icon="mdi-home" class="white-text"></v-list-item>
      <v-list v-for="item in GrupoTelas" :key="item.grtId">
            <v-list-group :value="item.grtNome">
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" :title="item.grtNome" :prepend-icon="item.grtIcon" class="white-text"></v-list-item>
              </template>
              <v-list-item v-for="subItem in Telas.filter(x=> x.admGrupoTelas.grtId == item.grtId)" :key="subItem.cdtId" :value="subItem.cdtNome" :title="subItem.cdtNome" :to="subItem.cdtUrl" prepend-icon="'-'" class="white-text">
            </v-list-item>
          </v-list-group>
        </v-list>
    </v-navigation-drawer>
</div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'PageTopo',
    components: {

    },
    data: () => ({
      drawer: false,
      isDropdownOpenChamados: false,
      isDropdownOpenDuvidas: false,
      isDropdownOpenAcoes: false,
      isDropdownOpenUser: false,
      User:{},
      Telas:[],
      GrupoTelas:[],
    }),
    methods: {
        toggleDropdownChamados() {
            this.isDropdownOpenChamados = !this.isDropdownOpenChamados;
        },
        toggleDropdownDuvidas() {
            this.isDropdownOpenDuvidas = !this.isDropdownOpenDuvidas;
        },
        toggleDropdownAcoes() {
            this.isDropdownOpenAcoes = !this.isDropdownOpenAcoes;
        },
        toggleDropdownUser() {
            this.isDropdownOpenUser = !this.isDropdownOpenUser;
        },
        toggleSidebar() {
            this.$emit('toggle-sidebar');
        },
        Logout() {
            this.$Cookies.remove("_vusavgar");
            localStorage.removeItem('_useravgar');
            this.$router.push('/')
        },
    },
    created() {
      const valorArmazenado = localStorage.getItem('_useravgar');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);
    }

    //carrega menu grupo de telas e telas
    axios.get(`/Process/AdmGrupoTelas/GetAll`)
      .then(response => {
        this.GrupoTelas = response.data.filter(x => x.grtVisivel).sort((a, b) => {
                                                                                  const valorA = parseInt(a.grtOrdem) || 0; 
                                                                                  const valorB = parseInt(b.grtOrdem) || 0; 
                                                                                  return valorA - valorB;
                                                                                  });


            axios.get(`Process/AdmCadastroTelas/GetAll`)
                .then(response => {
                    this.Telas = response.data.filter(x => x.cdtVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.cdtOrdem) || 0;
                                                                                          const valorB = parseInt(b.cdtOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                 


                    const arrayFiltrado2 = this.Telas.filter(objeto => this.User.menu.some(item => item.cdtId === objeto.cdtId));
                    this.Telas = arrayFiltrado2;
                    
                    const arrayFiltrado1 = this.GrupoTelas.filter(objeto => this.Telas.some(item => item.admGrupoTelas && item.admGrupoTelas.grtId === objeto.grtId));
                    this.GrupoTelas = arrayFiltrado1;
                });
      });

    }
}
</script>

<style>

.dropdown-item2 {
    display: block;
    /* width: 100%; */
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    /* clear: both; */
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius, 0);
    font-size: 13px;
}
</style>
