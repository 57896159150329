<template>
    <div>
        <div class="container">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Admin</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><router-link to="/controlesmtp">Controle SMTP</router-link> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="ControleSmtp.stpId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>SMTP</label>
                                            <input v-model="ControleSmtp.stpSmtp" type="text" class="form-control"
                                                placeholder="SMTP" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>E-mail</label>
                                            <input v-model="ControleSmtp.stpEmail" type="email" class="form-control"
                                                placeholder="E-mail" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Senha</label>
                                            <div class="input-icon">
                                                <input v-model="ControleSmtp.stpSenha"
                                                    :type="senhaVisivel ? 'text' : 'password'" class="form-control"
                                                    placeholder="Senha" />
                                                <span class="input-icon-addon">
                                                    <i @click="toggleSenhaVisivel()"
                                                    :class="senhaVisivel ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Porta</label>
                                            <input v-model.number="ControleSmtp.stpPorta" type="text"
                                                class="form-control" placeholder="Porta" />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="ControleSmtp.stpId != 0" type="button" class="btn btn-success"
                            style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="Get()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ControleSmtpView',
    components: {
        EasyDataTable
    },
    data: () => ({
        ControleSmtp: {
            stpId: 0,
            stpSmtp: '',
            stpEmail: '',
            stpSenha: '',
            stpPorta: 0,
        },
        ControleSmtps: [],
        senhaVisivel: false,
    }),
    methods: {
        toggleSenhaVisivel() {
            this.senhaVisivel = !this.senhaVisivel;
        },
        Add() {
            if (this.ControleSmtp.stpSmtp == '') {
                this.$mensagemAviso("SMTP Obrigatório.")
            }
            else if (this.ControleSmtp.stpEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.ControleSmtp.stpSenha == '') {
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.ControleSmtp.stpPorta == '') {
                this.$mensagemAviso("Porta Obrigatória.")
            }
            else {
                axios.post(`/Process/AdmControleSmtp/Add`, this.ControleSmtp)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.ControleSmtp.stpSmtp == '') {
                this.$mensagemAviso("SMTP Obrigatório.")
            }
            else if (this.ControleSmtp.stpEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.ControleSmtp.stpSenha == '') {
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.ControleSmtp.stpPorta == '') {
                this.$mensagemAviso("Porta Obrigatória.")
            }
            else {
                // console.log(this.ControleSmtp)
                axios.put(`/Process/AdmControleSmtp/Update`, this.ControleSmtp)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        Get() {
            axios.get(`/Process/AdmControleSmtp/GetAll`)
                .then(response => {
                    this.ControleSmtps = response.data;
                    if (this.ControleSmtps != '') {
                        this.ControleSmtp = this.ControleSmtps;
                    }

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
