<template>
    <div>
        <Loader v-if="isLoading"></Loader>
        <div class="container">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Contratação Garantia Locatícia</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7">
                                <v-tab value="dados" class="tabinfo">Nova Contratação</v-tab>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item value="dados">
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="6">
                                                        <v-card color="#f7f7f7" style="padding: 15px;">
                                                            <v-row style="padding-bottom:20px;">
                                                                <v-col cols="12">
                                                                    <v-row v-if="User.garid == 0">
                                                                        <v-col cols="12" md="12">
                                                                            <label>Garantidora</label>
                                                                            <select  v-model="selectedGarantidora" class="form-select form-control" 
                                                                            @change="BuscaImobialiarias()">
                                                                                <option :value=null>Selecione</option>
                                                                                <option v-for="optionTipo in DadosGarantidora" :key="optionTipo.garId"
                                                                                    :value="optionTipo">{{ optionTipo.garNomeEmpresa }}</option>
                                                                            </select>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row v-if="User.tpeid != 3">
                                                                        <v-col cols="12" md="12" v-if="selectedGarantidora!='' && selectedGarantidora != null && ListImobiliarias!= ''">
                                                                            <label>Imobiliária</label>
                                                                            <select  v-model="selectedImobiliaria" class="form-select form-control" 
                                                                            @change="SelecionarConsumidor()">
                                                                                <option :value=null>Selecione</option>
                                                                                <option v-for="optionTipo in ListImobiliarias" :key="optionTipo.imoId"
                                                                                    :value="optionTipo">{{ optionTipo.nomeConcatenado }}</option>
                                                                            </select>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="12" md="12" v-if="selectedImobiliaria!='' && selectedImobiliaria != null">
                                                                            <label>Cliente</label>
                                                                            <select  v-model="selectedConsumidor" class="form-select form-control">
                                                                                <option :value=null>Selecione</option>
                                                                                <option v-for="optionTipo in ListConsumidor" :key="optionTipo.cliId"
                                                                                    :value="optionTipo">{{ optionTipo.nomeConcatenado }}</option>
                                                                            </select>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="12" md="12" v-if="selectedConsumidor=='' || selectedConsumidor == null && selectedImobiliaria!='' && selectedImobiliaria != null">
                                                                            <router-link type="button" class="btn btn-info" to="/clientes">
                                                                                Novo Cliente
                                                                            </router-link>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                        <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="endereco!='' && tipoImovel !=''">
                                                            <v-item-group v-model="ProdutoSelected">
                                                                    <v-container>
                                                                        <v-row>
                                                                            <h5 class="subtitulo" style="padding-bottom: 15px;padding-left:10px;">Selecione o produto</h5>
                                                                        </v-row>
                                                                    <v-row>
                                                                        <v-col v-for="mens in ListProdutos" :key="mens.proId" cols="12" md="6">
                                                                        <v-item>
                                                                            <v-card 
                                                                            class="d-flex align-center" 
                                                                            dark height="230" 
                                                                            @click="toggle(mens)"
                                                                            :class="{ 'border-green': ProdutoSelected === mens }"
                                                                            >
                                                                            <div class="flex-grow-1 text-center" style="justify-content: center;">
                                                                                <img :src="$imgURL + mens.proProdutos.proCampoExtra10" style="height: 150px;" class="mx-auto" />
                                                                                {{ mens.nomeConcatenado }}
                                                                            </div>
                                                                            <v-icon v-if="ProdutoSelected === mens" class="check-icon">mdi-check</v-icon>
                                                                            </v-card>
                                                                        </v-item>
                                                                        </v-col>
                                                                    </v-row>
                                                                    </v-container>
                                                                </v-item-group>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" md="6" v-if="selectedConsumidor !='' && selectedConsumidor != null">
                                                        <v-card color="#f7f7f7" style="padding: 15px;">
                                                            <v-row style="line-height: 0;">
                                                                <v-col cols="12" v-if="endereco=='' || endereco==null">
                                                                    <br><button class="btn btn-info" @click.prevent="dialogEndereco = true">Adicionar Endereço da Contratação</button>
                                                                </v-col>
                                                                <v-col cols="12" v-else>
                                                                    <br><button class="btn btn-info" @click.prevent="dialogEndereco = true">Editar Endereço da Contratação</button>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row v-if="endereco!='' && endereco!=null">
                                                                <v-col cols="12" style="line-height: 0;">
                                                                <small><strong>Endereço:</strong> {{ endereco }} - {{  numeroend }} - {{ complemento }}</small> 
                                                                </v-col>
                                                                <v-col cols="12" style="line-height: 0;">
                                                                    <small><strong>CEP: </strong>{{ numCep }}</small>
                                                                </v-col>
                                                                <v-col cols="12" style="line-height: 0;">
                                                                    <small><strong>Cidade:</strong> {{ nomeCidade }} / {{  siglaEstado }} - {{  nomeBairro }}</small>
                                                                </v-col>
                                                                <v-col cols="12" style="line-height: 0;">
                                                                    <small><strong>Tipo Imóvel:</strong> {{ tipoImovel }} {{ residencialTipo }}</small>
                                                                </v-col>
                                                        </v-row>
                                                        </v-card>
                                                        <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="ListaPedidoItem!=''" >
                                                            <v-row style="padding: 10px;">
                                                                <div style="padding-top: 20px;min-height: 350px;">
                                                                    <h5 class="subtitulo" style="padding-bottom: 15px;padding-left:10px;">Itens da contratação</h5>
                                                                    <div v-for="(produto,index) in ListaPedidoItem" :key="produto.pdiId"
                                                                        class="descricao-pedido border-top py-3">
                                                                        <div class="row">
                                                                            <div class="col-6 col-md-3 d-flex align-items-center">
                                                                                <div class="descricao-content">
                                                                                    <h6 class="subtitulo">{{ produto.nomeConcatenado}}</h6>
                                                                                    <p class="texto-mensagem"> Código: {{ produto.proProdutos.proId }}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6 col-md-5 d-flex align-items-center">
                                                                            <small>Vigência de {{ formattedDate(produto.vigenciaini) }} até {{ formattedDate(produto.vigenciafim) }} </small>
                                                                            </div>
                                                                            <div class="col-6 col-md-3 d-flex align-items-center">
                                                                                <div class="descricao-content">
                                                                                    <h6 class="subtitulo" style="font-size: 15px;">
                                                                                        R$ {{
                                                                                            parseFloat(produto.valorTotalProduto).toFixed(2).toString().replace('.', ',')
                                                                                        }}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6 col-md-1 d-flex align-items-center">
                                                                                <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir" @click.prevent="ExcluiProduto(index)">
                                                                                    <i class="fa fa-window-close" style="color: black;font-size: 13px;"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                            </v-row>
                                                        </v-card>
                                                        <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="ListaPedidoItem!=''">
                                                            <v-row>
                                                                <v-col cols="12" md="12">
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                            <h5 class="subtitulo">Total</h5>
                                                                            <h6 class="subtitulo">R$ {{
                                                                                parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',')
                                                                            }}</h6>
                                                                        </div>
                                                                </v-col>
                                                            </v-row>
                                                            </v-card>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="6" style="text-align: right;">
                                                        <button type="button" @click="LimparMemoria(1)" class="btn btn-link">
                                                            Limpar
                                                        </button>
                                                    </v-col>
                                                    <v-col cols="12" md="6" style="text-align: left;" v-if="ListaPedidoItem !=''">
                                                        <button type="button" style="color:#ffffff;" class="btn btn-success" @click="GerarPedido()">
                                                            Enviar Contratação para Análise
                                                        </button>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>

                                    </v-window-item>
                                </v-window>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!--modal endereco-->
    <v-row justify="center">
        <v-dialog v-model="dialogEndereco" class="controlcelEnd" transition="dialog-bottom-transition"
          >
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;">Cadastrar Endereço do imóvel</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="5">
                                    <label>CEP</label>
                                    <input v-model="numCep" v-mask="'#####-###'" type="text"
                                        class="form-control" placeholder="CEP" />
                                </v-col>
                                <v-col cols="12" md="3">
                                    <a href="#" class="btn btn-secondary" style="padding: 5px;margin: 5px;font-size: 14px;margin-top: 25px;" @click.prevent="Buscacep(numCep)">Verificar</a>
                                </v-col>
                                <v-col cols="12" md="5" v-show="showend">
                                    <label>Endereço</label>
                                    <input v-model="endereco" type="text"
                                        class="form-control" placeholder="Endereço" />
                                </v-col>
                                <v-col cols="12" md="2" v-show="showend">
                                    <label>Número</label>
                                    <input v-model="numeroend" type="number"
                                        class="form-control" placeholder="Número" />
                                </v-col>
                                <v-col cols="12" md="2" v-show="showend">
                                    <label>Complemento</label>
                                    <input v-model="complemento" type="text"
                                        class="form-control" placeholder="Complemento" />
                                </v-col>
                                <v-col cols="12" md="3" v-show="showend">
                                    <label>Cidade</label>
                                    <input v-model="nomeCidade" type="text"
                                        class="form-control" placeholder="Cidade" />
                                </v-col>
                                <v-col cols="12" md="2" v-show="showend">
                                    <label>Estado</label>
                                    <input v-model="siglaEstado" type="text" v-mask="'AA'"
                                        class="form-control" placeholder="UF" />
                                  </v-col>
                                <v-col cols="12" md="3" v-show="showend">
                                    <label>Bairro</label>
                                    <input v-model="nomeBairro" type="text"
                                        class="form-control" placeholder="Bairro" />
                                </v-col>
                                <v-col cols="12" md="3" v-show="showend">
                                    <strong>Tipo do Imóvel </strong>
                                    <v-radio-group v-model="tipoImovel"
                                        style="display: inline-flex;flex-direction: row;">
                                        <v-radio :label="'Comercial'" value="Comercial" style="width: 280px;"></v-radio>
                                        <v-radio :label="'Residencial'" value="Residencial"
                                            style="width: 280px;"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" md="3" v-show="showend && tipoImovel == 'Residencial'">
                                    <strong>Tipo do Imóvel Residencial </strong>
                                    <v-radio-group v-model="residencialTipo"
                                        style="display: inline-flex;flex-direction: row;">
                                        <v-radio :label="'Casa'" value="Casa" style="width: 280px;"></v-radio>
                                        <v-radio :label="'Apartamento'" value="Apartamento"
                                            style="width: 280px;"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions v-show="showend">
                <v-spacer></v-spacer>
                     <v-btn class="btn btn-success" style="padding: 5px;margin: 5px;font-size: 13px;color:#ffffff;" @click.prevent="ValidaEndereco()">Confirmar Endereço</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <!--modal produto garantia-->
     <v-row justify="center">
        <v-dialog v-model="dialogProdutoGarantia" class="controlcelEnd" transition="dialog-bottom-transition"
          >
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;" >Produto Garantia {{  tipoImovel }}</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                  <v-row v-for="(grids,index) in ProdutosGrid" :key="index" style="line-height:0 !important">
                                     <v-col cols="12" md="4" class="d-flex align-center">
                                        {{grids.pisNome}}
                                     </v-col>
                                     <v-col cols="12" md="6">
                                        <v-text-field class="input" v-model="grids.pisCampoExtra1" type="text"
                                           variant="outlined" placeholder="Valor R$ "></v-text-field>
                                     </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-row>
                                     <v-col cols="12" md="12">
                                        <small>Selecione o plano de Setup da Imobiliária:</small>
                                        <v-slider
                                            v-model="PlanosGarantiasSelected"
                                            :max="300"
                                            :min="0"
                                            :step="50"
                                            thumb-label="always"
                                            style="padding-top: 20px;"
                                        ></v-slider>
                                     </v-col>
                                     <v-col cols="12" md="12">
                                        <small>Resultado</small>
                                        <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="ProdutosTaxas!=''">
                                            Pacote: R$ {{ formatarValor(ValorTotalComputed * ProdutosTaxas) }}<br>
                                            Taxa Setup: R$ {{ formatarValor(parseFloat(PlanosGarantiasSelected)) }}<br>
                                            <strong>Valor Total: R$ {{ formatarValor(parseFloat(ValorTotalComputed * ProdutosTaxas) + parseFloat(PlanosGarantiasSelected)) }}  </strong>
                                        </v-card>
                                        <small>* Todas os valores informados são de responsabilidade da imobiliária parceira.</small>
                                    </v-col>
                                  </v-row>
                                </v-col> 
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions v-show="showend">
                <v-spacer></v-spacer>
                     <v-btn class="btn btn-success" style="padding: 5px;margin: 5px;font-size: 12px;color:#ffffff" @click.prevent="AdicionarGarantia()">Adicionar Garantia</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import Loader from '../../components/PageLoader.vue'


export default {
    name: 'NovaContratacaoView',
    components: {
        EasyDataTable,
        moment,
        Loader
    },
    data: () => ({
        tab: null,
        NomeTela: 'Contratação',
        InfoTela: 'Nova Contratação',
        CadastroTelas: [{ cdtId: 28, cdtNome: "Contratação" }],
        //cli geral
        ListClientesGeral: [],
        //garantidoras
        selectedGarantidora: null,
        //Imobiliarias
        ListImobiliariaGeral: [],
        selectedImobiliaria: null,
        ListImobiliarias: [],
        //Imobliarias
        //consumidor
        selectedConsumidor:null,
        ListConsumidor:[],
        Cliente:{},
        //consumidor
        //endereco
        dialogEndereco: false,
        endereco: '',
        numeroend: '',
        complemento: '',
        siglaEstado: '',
        nomeCidade: '',
        nomeBairro: '',
        numCep: '',
        Observacoes: '',
        Retornocep: {},
        showend: false,
        tipoImovel:'',
        residencialTipo:'',
        //endereco
        //pedido
          Pedidos: [],
          PedidosCli: [],
          TotalPedido: 0,
          ValorDesconto:0,
          ValorFrete:0,
          ListaPedidoItem: [],
          ListaPedidoItemAdicional: [],
          Situacoes: [],
        //pedido
        //produtos
        ProdutoSelected: '',
        ListProdutosGeral:[],
        ListProdutos:[],
        selectedItemProduto: null,
        dialogProdutoGarantia:false,
        dialogProdutoSeguro:false,
        ProdutosGrid:[],
        PlanosGarantiasSelected:0,
        PlanosGarantias:[],
        ProdutosTaxas:0.1,
        ValorTotal:0,
        //produtos
        DadosCompra: {
            imobiliaria:[],
            consumidor:[],
            garantidora:[],
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        linkPagamento: null,
        //pagamentos
        //artes
        dialogArtes: false,
        dialogFoto: false,
        artes:[],
        artesGeral:[],
        ArteSelected: '',
        ArteSelectedImg: '',
        controleindex:'',
        controleindexFoto:'',
        ListaArtesCateg:[],
        CategArteSelected:'',
        UrlImagem:'',
        selectedFileFoto:'',
       //artes 
       DadosGarantidora:[],
       DadosConfiguracao:[],
       isLoading: false,
       RetornoSerasaAntigo:null,
       ListaContratos:[],
       ContratoPedido:[],
       Assinatura:[],
    }),
    methods: {
        startLoading() {
            this.isLoading = true;
        },
        stopLoading() {
            this.isLoading = false;
        },
        //garantidoras
        async BuscaImobialiarias(){
            if (this.selectedGarantidora != '' && this.selectedGarantidora != null){
                this.selectedImobiliaria = null;
                this.ListImobiliariaGeral = [];
               await axios.get(`/Process/ImoImobiliarias/GetByGarIdParId/` + this.selectedGarantidora.garId + '/0') 
                    .then(response => {
                        this.ListImobiliariaGeral = response.data;  
                        this.ListImobiliarias = this.ListImobiliariaGeral.filter(x=> x.imoAtivo && x.imoVisivel);  //imobiliaria
                        this.ListImobiliarias = this.ListImobiliarias.map(item => {
                            return {
                                ...item,
                                nomeConcatenado: `${item.imoId} - ${item.imoNomeEmpresa} - ${item.imoDocumento}`
                            };
                        });

                        if (this.User.tpeid == 3){
                            this.ListImobiliarias = this.ListImobiliarias.filter(x=> x.imoId == this.User.pesid)
                            this.selectedImobiliaria = this.ListImobiliarias[0];
                            this.SelecionarConsumidor();
                        }
                    });
            }
        },
        //consumidor
        SelecionarConsumidor(){
            if (this.selectedImobiliaria != '' && this.selectedImobiliaria != null){
                this.selectedConsumidor = null;
                this.ListConsumidor = [];
                axios.get(`/Process/CliClientes/GetByGarIdImoId/0/` + this.selectedImobiliaria.imoId) 
                    .then(response => {
                        this.ListConsumidor = response.data;  //consumidor
                        this.ListConsumidor = this.ListConsumidor.map(item => {
                            return {
                                ...item,
                                nomeConcatenado: `${item.cliId} - ${item.cliNomeEmpresa} - ${item.cliDocumento}`
                            };
                        });
                    });
            }
           // console.log('Produto Adicional Selecionado:', this.selectedImobiliaria);
        },
        //consumidor
        //endereco
        Buscacep(value) {
            if (this.numCep != ''){
                fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        this.$mensagemAviso("CEP inexistente");
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                    if (this.Retornocep != null) {
                        this.endereco = this.Retornocep.logradouro;
                        this.numeroend = null;
                        this.complemento = null;
                        this.siglaEstado = this.Retornocep.uf;
                        this.nomeCidade = this.Retornocep.localidade;
                        this.nomeBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        this.$mensagemAviso("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }
            else{
                this.$mensagemAviso("Digite um cep válido");
            }
          
        },
        ValidaEndereco(){
            if (this.numCep == '' || this.numCep == null){
                this.$mensagemAviso("Preencha o CEP");
            }
            else if (this.endereco == '' || this.endereco == null){
                this.$mensagemAviso("Preencha o Endereço");
            }
            else if (this.numeroend == '' || this.numeroend == null){
                this.$mensagemAviso("Preencha o número do endereço");
            }
            else if (this.complemento == '' || this.complemento == null){
                this.$mensagemAviso("Preencha complemento (casa, apto, etc)");
            }
            else if (this.siglaEstado == '' || this.siglaEstado == null){
                this.$mensagemAviso("Preencha sigla do estado");
            }
            else if (this.nomeCidade == '' || this.nomeCidade == null){
                this.$mensagemAviso("Preencha nome da cidade");
            }
            else if (this.nomeBairro == '' || this.nomeBairro == null){
                this.$mensagemAviso("Preencha nome do bairro");
            }
            else if (this.tipoImovel == '' || this.tipoImovel == null){
                this.$mensagemAviso("Selecione tipo de imóvel");
            }
            else if (this.tipoImovel == 'Residencial' && (this.residencialTipo == null || this.residencialTipo == '')){
                this.$mensagemAviso("Preencha tipo de imóvel residencial no endereço.")
            }
            else{
              this.dialogEndereco = false;
              this.ListaPedidoItem = [];
              this.SalvarMemoria(2);
              this.ListProdutos = this.ListProdutosGeral.filter(x => 
                                                x.proProdutos.proNomeProduto && this.tipoImovel &&
                                                x.proProdutos.proNomeProduto.toLowerCase().includes(this.tipoImovel.toLowerCase())
                                                );
            // console.log(' this.ListProdutos')
            // console.log( this.ListProdutos)
            }
        },
        //endereco
        //pedido
        LimparMemoria(value){
            localStorage.removeItem(this.$DadosCompraManual);
            if (value == 1){
                this.$mensagemAviso("Memória Limpa.");
            }
            
            this.vshow = false;
            this.selectedImobiliaria = null;
            this.selectedConsumidor = null;
            this.ProdutoSelected = "";
            this.ListaPedidoItem = [];
            this.endereco = "";
            this.numeroend = "";
            this.complemento = "";
            this.siglaEstado = "";
            this.nomeCidade = "";
            this.nomeBairro = "";
            this.numCep = "";
            this.showend = false,
            this.tipoImovel = "";
            this.residencialTipo = "";
            this.TotalPedido = '';
            this.Get();
        },
        SalvarMemoria(value){
            localStorage.removeItem(this.$DadosCompraManual);
            this.DadosCompra.imobiliaria = [];
            this.DadosCompra.consumidor = [];
            this.DadosCompra.garantidora = [];
            this.DadosCompra.produtos = [];
            this.DadosCompra.mensagem = [];
            this.DadosCompra.agenda = [];
            this.DadosCompra.foto = [];
            this.DadosCompra.avulsos = [];
            this.DadosCompra.informacao = [];
            this.DadosCompra.pagamento = [];
            this.DadosCompra.cupom = [];

              //garantidora
              this.DadosCompra.garantidora.push(this.selectedGarantidora);

            //imobiliaria
            this.DadosCompra.imobiliaria.push(this.selectedImobiliaria);

             //consumidor
             this.DadosCompra.consumidor.push(this.selectedConsumidor);

            //entrega
                let _agenda = {
                    TipoImovel: this.tipoImovel,
                    estado: this.siglaEstado,
                    cidade: this.nomeCidade,
                    bairro: this.nomeBairro,
                    endereco: this.endereco,
                    numero: this.numeroend,
                    complemento: this.complemento,
                    cep: this.numCep,
                    ResidencialTipo: this.residencialTipo === null ? '' :  this.residencialTipo,
                }
                
                this.DadosCompra.agenda.push(_agenda);

                
                //produto
                if (this.ListaPedidoItem.length > 0){
                    this.DadosCompra.produtos.push(this.ListaPedidoItem);
                }            

            localStorage.setItem(this.$DadosCompraManual, JSON.stringify(this.DadosCompra));
            if (value == 1){
                this.$mensagemAviso("Salvo em memória.")
            }
            
        },
        async GerarPedido(){
         await this.SalvarMemoria(2);

         let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
        if (dadosArmazenados) {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            if (dadosArmazenados.imobiliaria == '' || this.selectedImobiliaria == null){
                this.$mensagemAviso("Selecione imobiliaria.")
            }
            else if (dadosArmazenados.consumidor == '' || this.selectedConsumidor == null){
                this.$mensagemAviso("Selecione cliente.")
            }
            else if (dadosArmazenados.agenda[0].TipoImovel == '' || dadosArmazenados.agenda[0].TipoImovel == null){
                this.$mensagemAviso("Preencha tipo de imóvel no endereço.")
            }
            else if (dadosArmazenados.agenda[0].TipoImovel == 'Residencial' && (dadosArmazenados.agenda[0].ResidencialTipo == null || dadosArmazenados.agenda[0].ResidencialTipo == '')){
                this.$mensagemAviso("Preencha tipo de imóvel residencial no endereço.")
            }
            else if (dadosArmazenados.agenda[0].estado == '' || dadosArmazenados.agenda[0].estado == null){
                this.$mensagemAviso("Preencha sigla do estado do endereço.")
            }
            else if (dadosArmazenados.agenda[0].cidade == '' || dadosArmazenados.agenda[0].cidade == null){
                this.$mensagemAviso("Preencha cidade do endereco.")
            }
            else if (dadosArmazenados.agenda[0].bairro == null || dadosArmazenados.agenda[0].bairro == ""){
                this.$mensagemAviso("Preencha o bairro do endereço.")
            }
            else if (dadosArmazenados.agenda[0].endereco == '' || dadosArmazenados.agenda[0].endereco == null){
                this.$mensagemAviso("Preencha endereço.")
            }
            else if (dadosArmazenados.agenda[0].numero == "" || dadosArmazenados.agenda[0].numero == null ){
                this.$mensagemAviso("Preencha o número do endereço.")
            }
            else if (dadosArmazenados.agenda[0].complemento == "" || dadosArmazenados.agenda[0].complemento == null){
                this.$mensagemAviso("Preencha o complemento (casa, apto, escritório...) do endereco.")
            }
            else if (dadosArmazenados.agenda[0].cep == "" || dadosArmazenados.agenda[0].cep == null){
                this.$mensagemAviso("Preencha o CEP do endereço.")
            }
            else if (dadosArmazenados.produtos[0].length == 0){
                this.$mensagemAviso("Adicione um produto na lista.")
            }
            else{
               this.CarregaPedido();
               //this.$mensagemAviso("chegou aqui.")
            }
        }
        },
        calculatotal() {
            var valorProdutos = 0;
            var vcount = 0;

                if (this.ListaPedidoItem != '') {

                    this.ListaPedidoItem.forEach(item => {
                        valorProdutos += item.valorTotalProduto;
                        vcount++;
                    });
                }
                this.TotalPedido = (valorProdutos);
           
        },
        ExcluiProduto(index) {
            this.ListaPedidoItem.splice(index, 1);
            //this.calculatotal();
        },
        async CarregaPedido(){
                this.startLoading();
                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let _situacao = 'Aguardando Analise';
                    let _pspId = 1;

                    //dados do pedido
                        let _pedido = {
                            PedId: 0,
                            GrtId: 0,
                            CdtId: 0,
                            IdiId: 0,
                            PedDataPedido: moment().format('YYYY-MM-DDTHH:mm:ss'),
                            PedOrigemPedido: this.selectedGarantidora.garNomeEmpresa,
                            CliId: dadosArmazenados.consumidor[0].cliId,
                            ImobId: dadosArmazenados.imobiliaria[0].imoId,
                            GarId: dadosArmazenados.garantidora[0].garId,
                            PedFormaPgto: '',
                            PedObsLoja: '',
                            PedRetornoPgto: 'pending',
                            PedCodigoRetornoPgto: '',
                            PspId: _pspId,
                            PedSituacaoPedido: _situacao,
                            PedObsCliente: '',
                            PedUser: this.User.cusid
                            }
                           // console.log('_pedido')
                           // console.log(_pedido)

                           await axios.post(`/Process/PedPedidos/Add`, _pedido)
                                        .then(response => {
                                            if (response.data > 0) {
                                                    _pedido.PedId = response.data;   
                                                    this.NumeroPedido = _pedido.PedId;
                                                    console.log('_pedido adicionado')
                                                   // console.log(_pedido)                
                                            }
                                        });

                            //itens do pedido
                            let _pedidoItens = [];
                            if (dadosArmazenados.produtos[0].length > 0) {
                                await dadosArmazenados.produtos[0].forEach((itemprod) => {
                                _pedidoItens.push(
                                    {
                                        PdiId: 0,
                                        GrtId: 0,
                                        CdtId: 0,
                                        IdiId: 0,
                                        PedId: _pedido.PedId,
                                        ProId: itemprod.proProdutos.proId,
                                        PdiNomeProduto: itemprod.proProdutos.proNomeProduto,
                                        PdiQtdeProduto: parseInt(itemprod.qtdeProduto),
                                        PdiValorProduto: parseFloat(itemprod.valorProduto),
                                        PdiValorTotalProduto: itemprod.valorTotalProduto,
                                        PdiDataCadastro: moment().format('YYYY-MM-DDTHH:mm:ss'),
                                        PdiDataVigenciaIni: moment(itemprod.vigenciaini).format('YYYY-MM-DD'),
                                        PdiDataVigenciaFim: moment(itemprod.vigenciafim).format('YYYY-MM-DD'),
                                        PdiCampoExtra1: itemprod.codSetup.toString(),
                                        PdiCampoExtra2: itemprod.taxa.toString(),
                                        Grid: itemprod.proProdutos.proProdutosGrids,
                                    }
                                );
                                });
                            }

                           // console.log('_pedidoItens')
                           // console.log(_pedidoItens)
                            
                            await  _pedidoItens.forEach((itens) => {
                            axios.post(`/Process/PedPedidoItem/Add`, itens)
                                    .then(response => {
                                        if (response.data > 0) {
                                           this.ContratoAdd(_pedido.PedId, response.data);
                                           console.log('_pedidoItens adicionado')
                                           let _pedidoItensAvulsos = [];
                                           itens.Grid.forEach((itemprod) => {
                                                _pedidoItensAvulsos.push(
                                                    {
                                                        PiaId: 0,
                                                        GrtId: 0,
                                                        CdtId: 0,
                                                        IdiId: 0,
                                                        PedId: _pedido.PedId,
                                                        PiaNomeProduto: itemprod.pisNome,
                                                        PiaQtdeProduto: 1,
                                                        PiaValorProduto: parseFloat(itemprod.pisCampoExtra1.replaceAll('.', '').replaceAll(',', '.')),
                                                        PiaValorTotalProduto: parseFloat(itemprod.pisCampoExtra1),
                                                        PiaDataCadastro: moment().format('YYYY-MM-DDTHH:mm:ss'),
                                                        PiaCampoExtra1: '',   
                                                    }
                                                );
                                                });

                                            // console.log('_pedidoItensAvulsos')
                                             // console.log(_pedidoItensAvulsos)

                                               _pedidoItensAvulsos.forEach((itens) => {
                                                axios.post(`/Process/PedPedidoItemAdicional/Add`, itens)
                                                    .then(response => {
                                                        if (response.data > 0) {
                                                            console.log('_pedidoItensAvulsos adicionado')
                                                        // console.log(itens)         
                                                        }
                                                    });
                                            })
                                        }
                                    });
                            })                     

                            //pedido Endereço
                            let _pedidoEndereco = {
                                PdcId: 0,
                                GrtId: 0,
                                CdtId: 0,
                                IdiId: 0,
                                PdcEndereco: dadosArmazenados.agenda[0].endereco,
                                PdcNumero: parseInt(dadosArmazenados.agenda[0].numero,10),
                                PdcComplemento: dadosArmazenados.agenda[0].complemento,
                                PdcObsEndereco:'',
                                pdcCep: dadosArmazenados.agenda[0].cep,
                                PdcPais:'Brasil',
                                PdcEstado: dadosArmazenados.agenda[0].estado,
                                PdcCidade: dadosArmazenados.agenda[0].cidade,
                                PdcBairro: dadosArmazenados.agenda[0].bairro,
                                PedId:_pedido.PedId,
                                PdcTipo: dadosArmazenados.agenda[0].TipoImovel,
                                PdcResidencialTipo: dadosArmazenados.agenda[0].ResidencialTipo,
                            }

                            await axios.post(`/Process/PedPedidoEndereco/Add`, _pedidoEndereco)
                                    .then(response => {
                                        if (response.data > 0) {   
                                                console.log('_pedidoEndereco adicionado')
                                                //console.log(_pedidoEndereco)                
                                        }
                                    });
                         
                            this.ValidaAprovacao(_pedido);
                            
                        }
        },
        async Uploadpdf(dadosContrato) {
            const uploadResult = await axios.post(`/Process/UploadImg/uploadContratoPdf`, dadosContrato);
            return uploadResult.data.nameFile;
        },
        async ContratoAdd(_pedId, _pdiId){
            let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);

            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                 //chamar a montagem do pdf e gravar
                 //dadosContratante = cliente e dadosContratada = garantidora
                 var _dadosContrato = {
                    TipoPessoa: 4,
                    CmcId: 4,
                    IdContratante: dadosArmazenados.consumidor[0].cliId,
                    IdContratada: dadosArmazenados.garantidora[0].garId,
                    dadosContratacao:{
                        idPedido: _pedId,
                        multiplicador: '18x',
                        valorContratado: dadosArmazenados.produtos[0][0].valorTotalProduto.toString(),
                        custoSaida: '3x',
                        valorLocaticio: '0',
                        valorTaxa: dadosArmazenados.produtos[0][0].taxa.toString(),
                        setup: dadosArmazenados.produtos[0][0].codSetup.toString()
                    }
                }

                var _fileName = await this.Uploadpdf(_dadosContrato);

                var _pedidoDocumento = {
                    pftId:0,
                    grtId: 0,
                    cdtId:0,
                    pftUrlImagem: _fileName,
                    pedId: _pedId,
                    pdiId: _pdiId,
                    pftDadosContrato: JSON.stringify(_dadosContrato.dadosContratacao)
                }

                await axios.post(`/Process/PedPedidoDocumento/Add`, _pedidoDocumento)
                        .then(response => {
                        if (response.data > 0) {
                            _pedidoDocumento.pftId = response.data;
                        }
                    });
            }
        },
        async ValidaAprovacao(pedidoDados){
            //console.log('pedidoDados')
            //console.log(pedidoDados)
          var _resultSerasa = await this.ConsultaSerasa(pedidoDados);

          var _resultJusBrasil = await this.ConsultaJusBrasil(pedidoDados);

          //console.log('_resultSerasa')
          //console.log(_resultSerasa)
          //console.log('_resultJusBrasil')
          //console.log(_resultJusBrasil)
          
          if (_resultJusBrasil != 0 && _resultSerasa != 0){
                if (_resultSerasa == 5 && _resultJusBrasil == 5){
                    let _resultAss = await this.EnviarAssinatura(pedidoDados);//gerar documento para assinar 
                    if (_resultAss == 1){
                        let _dadosSituacao = {
                                PspId: 9,
                                PedSituacaoPedido: "Aguardando Assinatura",
                                PedRetornoPgto: "Pending",
                                PedInvoiceId: "",
                                PedCodigoRetornoPgto: "",
                                PedQtdeParcelas: 0,
                                PedValorParcelas: 0,
                                PedId: pedidoDados.PedId,
                            }

                            axios.put(`/Process/PedPedidos/UpdateSituacaoPgto`, _dadosSituacao)
                                .then(response => {
                                    if (response.status == 200) {
                                        console.log('Update Situacao')
                                    }
                                });
                                this.LimparMemoria(2);
                                this.$mensagemAviso("A contratação foi enviada para assinatura contrato.")
                                this.$router.push('/listacontratacoes')
                    }
                    else{
                        this.$mensagemAviso("Não foi possível enviar contrato para assinatura.")
                    }
            
                }
                else if (_resultSerasa == 4 || _resultJusBrasil == 4){
                    let _dadosSituacao = {
                                PspId: 4,
                                PedSituacaoPedido: "Não Aprovado",
                                PedRetornoPgto: "Pending",
                                PedInvoiceId: "",
                                PedCodigoRetornoPgto: "",
                                PedQtdeParcelas: 0,
                                PedValorParcelas: 0,
                                PedId: pedidoDados.PedId,
                            }

                            axios.put(`/Process/PedPedidos/UpdateSituacaoPgto`, _dadosSituacao)
                                .then(response => {
                                    if (response.status == 200) {
                                        console.log('Update Situacao')
                                    }
                                });
                                this.LimparMemoria(2);
                                this.$mensagemAviso("A contratação não foi aprovada.")
                            this.$router.push('/contratacoesNegadoCancelado')
                }
                else{
                    let _dadosSituacao = {
                                PspId: 2,
                                PedSituacaoPedido: "Análise Manual",
                                PedRetornoPgto: "Pending",
                                PedInvoiceId: "",
                                PedCodigoRetornoPgto: "",
                                PedQtdeParcelas: 0,
                                PedValorParcelas: 0,
                                PedId: pedidoDados.PedId,
                            }

                            axios.put(`/Process/PedPedidos/UpdateSituacaoPgto`, _dadosSituacao)
                                .then(response => {
                                    if (response.status == 200) {
                                        console.log('Update Situacao')
                                    }
                                });
                                this.LimparMemoria(2);
                                this.$mensagemAviso("A contratação foi enviada para análise.")
                                this.$router.push('/listacontratacoes')
                }       
          }
          else{
            let _dadosSituacao = {
                PspId: 2,
                PedSituacaoPedido: "Análise Manual",
                PedRetornoPgto: "Pending",
                PedInvoiceId: "",
                PedCodigoRetornoPgto: "",
                PedQtdeParcelas: 0,
                PedValorParcelas: 0,
                PedId: pedidoDados.PedId,
            }

            axios.put(`/Process/PedPedidos/UpdateSituacaoPgto`, _dadosSituacao)
                .then(response => {
                    if (response.status == 200) {
                        console.log('Update Situacao')
                    }
                });
                this.LimparMemoria(2);
                this.$mensagemAviso("A contratação foi enviada para análise.")
                this.$router.push('/listacontratacoes')
          }
        },
        async ConsultaSerasa(pedi) {
            var result = 2;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);

            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                let documentId = "";
                let tipo = "";
                let relatorio = "";
                let estado = dadosArmazenados.agenda[0].estado;

                if (dadosArmazenados.consumidor[0].tpdId == 1) {
                    tipo = 3;
                    relatorio = "RELATORIO_BASICO_PF_PME";
                    documentId = dadosArmazenados.consumidor[0].cliDocumento.replaceAll('.', '').replaceAll('-', '');
                } else {
                    tipo = 4;
                    relatorio = "RELATORIO_BASICO_PJ_PME";
                    documentId = dadosArmazenados.consumidor[0].cliDocumento.replaceAll('.', '').replaceAll('-','').replaceAll('/', '');
                }

                try {
                    const response = await axios.get(`/Process/PedPedidos/GetByDocumentId/` + documentId + '/' + pedi.PedId);

                    if (response.data == 0) {
                        const serasaResponse = await axios.post(`/Process/ValidaSerasa/checkSerasa/` + documentId + '/' + tipo + '/' + relatorio + '/' + estado + '/' + pedi.PedId);
                          
                        if (serasaResponse.data && serasaResponse.data.reports && serasaResponse.data.reports[0].score && serasaResponse.data.reports[0].score.score)
                        {
                            let resultScore = serasaResponse.data.reports[0].score.score;

                            let _rangemax = parseInt(this.DadosConfiguracao.filter(x=> x.garId == dadosArmazenados.garantidora[0].garId)[0].gseRangeAprovacao);
                            let _rangemin = _rangemax*0.25;

                            if (resultScore > _rangemax) {
                                result = 5;
                            } else if (resultScore > _rangemin && resultScore < _rangemax) {
                                result = 2;
                            } else if (resultScore == 0) {
                                result = 2;
                            } else {
                                result = 4;
                            }
                        }
                        else{
                            result = 2;
                        }
                    } else {
                        result = 4;
                    }

                   return result;
                } catch (error) {
                    console.error('Erro ao buscar dados', error);
                    result = 0;
                    //this.$mensagemAviso("Falha ao se comunicar com Serasa");
                   // this.stopLoading();
                    return result;
                }
            }
        },
        async ConsultaJusBrasil(pedi){
            var result = 2;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);

            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                let documentId = "";
                documentId = dadosArmazenados.consumidor[0].cliDocumento.replaceAll('.', '').replaceAll('-','').replaceAll('/', '');
                await axios.post(`/Process/ValidaJusBrasil/checkJusBrasil/` + documentId + '/' + pedi.PedId)
                    .then(response => {
                        if (response.data && response.data.processos) {
                            const processos = response.data.processos;

                            // Palavras-chave para buscar
                            const keywords = ["revisional", "aluguel", "despejo"];

                            // Verifica se algum processo contém as palavras-chave nos campos "natureza" ou "assunto"
                            const hasKeywords = processos.some((processo) => {
                                const natureza = processo.natureza?.toLowerCase() || "";
                                const assunto = processo.assunto?.toLowerCase() || "";

                                // Verifica se qualquer palavra-chave está presente em "natureza" ou "assunto"
                                return keywords.some((keyword) =>
                                    natureza.includes(keyword) || assunto.includes(keyword)
                                );
                            });

                            if (hasKeywords) {
                                result = 2;
                            } else {
                                result = 5;
                            }
                        } else {
                            console.log("Dados inválidos recebidos:", response);
                            const errorMessage = response.data?.ErrorResponse?.message || "Erro ao processar a solicitação.";
                            this.$mensagemAviso(errorMessage);
                        }
                    })
                    .catch(error => {
                        result = 0;
                       // this.$mensagemAviso("Falha ao se comunicar com JusBrasil");
                       // this.stopLoading();
                    });
                return result;
            }
        },
        async EnviarAssinatura(pedi) {
                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);

                if (!dadosArmazenados) {
                    console.error("Nenhum dado armazenado encontrado.");
                    return 0; // Falha
                }

                dadosArmazenados = JSON.parse(dadosArmazenados);

                try {
                    // Buscar dados da garantidora
                    const responseGarantidora = await axios.get(`/Process/GarGarantidoraAssinatura/GetByGarId/` + dadosArmazenados.garantidora[0].garId);
                    
                    if (responseGarantidora.data.length === 0) {
                        console.error("Nenhuma garantidora encontrada para o ID fornecido.");
                        return 0; // Falha
                    }

                    this.Assinatura = responseGarantidora.data[0];

                    // Buscar documentos do pedido
                    const responseDocumentos = await axios.get(`/Process/PedPedidoDocumento/GetAllByPedId/` + pedi.PedId);

                    if (responseDocumentos.data.length === 0) {
                        console.error("Nenhum documento encontrado para o pedido.");
                        return 0; // Falha
                    }

                    this.ContratoPedido = responseDocumentos.data;

                    // Processar cada documento
                    for (const element of this.ContratoPedido) {
                        const idContrato = element.pftId;
                        const tpeId = 4;
                        const DadosEnvelopeRequest = {
                            token: this.Assinatura.gasToken,
                            envelopeDescricao: this.ListaContratos.filter(x => x.cmcId === 4)[0]?.cmcNome || "Termo de Garantia Locatícia",
                            repositorioId: parseInt(this.Assinatura.gasIdRepositorio),
                            listaDocumentos: [{ UrlArquivo: element.pftUrlImagem }],
                            listaSignatariosEnvelopes: [
                                {
                                    Ordem: 1,
                                    EmailSignatario: dadosArmazenados.consumidor[0].cliEmail,
                                    NomeSignatario: dadosArmazenados.consumidor[0].cliNomeEmpresa,
                                    TipoAssinatura: 1,
                                },
                                {
                                    Ordem: 2,
                                    EmailSignatario: dadosArmazenados.garantidora[0].garEmail,
                                    NomeSignatario: dadosArmazenados.garantidora[0].garNomeEmpresa,
                                    TipoAssinatura: 1,
                                },
                            ],
                        };

                        try {
                            const responseEnvelope = await axios.post(
                                `/Process/AssinaturasAsten/CreateEnvelope/` + idContrato + '/' + tpeId,
                                DadosEnvelopeRequest
                            );

                            if (responseEnvelope.status !== 200) {
                                console.error("Erro ao criar envelope:", responseEnvelope.status, responseEnvelope.data);
                                return 0; // Falha
                            }

                            console.log("Envelope criado com sucesso:", responseEnvelope.data);
                        } catch (error) {
                            console.error("Erro ao enviar assinatura:", error.message);
                            return 0; // Falha
                        }
                    }

                    return 1; // Sucesso
                } catch (error) {
                    console.error("Erro geral na função EnviarAssinatura:", error.message);
                    return 0; // Falha
                }
            },
        EnviaAvisoPgtoCliente(dadoslink, texto, consumidor, imob, pedi) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 4);

                    let _dadosEmail = {
                        nomeDe: this.DadosGarantidora[0].itiNome,
                        nomePara: consumidor.cliNomeEmpresa,
                        assunto: 'Andamento Contratação: ' + pedi.PedId,
                        destinatario: consumidor.cliEmail,
                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                        textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', consumidor.cliNomeEmpresa)
                                                                  .replace('[NumeroPedido]', pedi.PedId)
                                                                  .replace('[imobiliaria]', imob.cliNomeEmpresa)
                                                                  .replace('[descricao]',texto)
                                                                  .replace('[LinkPgto]',dadoslink)
                                                                  ,
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoPgtoImobiliaria(dadoslink, texto, consumidor, imob, pedi) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 4);

                    let _dadosEmail = {
                        nomeDe: this.DadosGarantidora[0].itiNome,
                        nomePara:  imob.cliNomeEmpresa + ' - ' + consumidor.cliNomeEmpresa,
                        assunto: 'Andamento Contratação: ' + pedi.PedId,
                        destinatario: imob.cliEmail,
                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                        textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', consumidor.cliNomeEmpresa)
                                                                  .replace('[NumeroPedido]', pedi.PedId)
                                                                  .replace('[imobiliaria]', imob.cliNomeEmpresa)
                                                                  .replace('[descricao]',texto)
                                                                  .replace('[LinkPgto]',dadoslink),
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        //pedido
        //produtos
        toggle(mes) {
            this.ProdutoSelected = mes;
            if (mes.proProdutos.proId == 1 || mes.proProdutos.proId == 2){
                    let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                        if (dadosArmazenados) {
                            dadosArmazenados = JSON.parse(dadosArmazenados);
                            if (dadosArmazenados.produtos.length > 0){
                                this.ProdutosGrid =  dadosArmazenados.produtos[0][0].proProdutos.proProdutosGrids;
                            }
                            else{
                                //console.log('aqui')
                                axios.get(`/Process/ProProdutosItens/GetAllByProId/${mes.proProdutos.proId}`)
                                .then(response => {
                                    this.ProdutosGrid = response.data; 
                                                
                                });
                            }
                        }
                        else{
                            axios.get(`/Process/ProProdutosItens/GetAllByProId/${mes.proProdutos.proId}`)
                            .then(response => {
                                this.ProdutosGrid = response.data; /*.sort((a, b) => {
                                                                            const valorA = parseInt(a.pimOrdem) || 0;
                                                                            const valorB = parseInt(b.pimOrdem) || 0;
                                                                            return valorA - valorB;
                                                                        });*/
                                                                        
                           // console.log('mes')
                           // console.log(mes)

                      //  console.log('ProdutosGrid')
                      //  console.log(this.ProdutosGrid)

                            });
                        }
                    

                         /*   axios.get(`/Process/ProProdutosPrecos/GetAllByPtcId/5/${mes.proProdutos.proId}`)
                            .then(response => {
                                this.ProdutosTaxas = response.data;                                                                 
                               // console.log('ProdutosTaxas')
                               // console.log(this.ProdutosTaxas)

                            });*/

                    this.openModalProduto(1);
                            
            }
        },
        openModalProduto(value){
            if (value == 1)
            {
                this.dialogProdutoGarantia = true;
            }
            else{
                this.dialogProdutoSeguro = true;
            }
        },
        AdicionarGarantia(){
            if (this.ProdutosGrid.some(item => item.pisCampoExtra1 === ''))
            {
                this.$mensagemAviso("Valores precisam ser preenchidos.")
            }
            else if (this.PlanosGarantiasSelected == '' || this.PlanosGarantiasSelected == null){
                this.$mensagemAviso("Selecione taxa de setup.")
            }
            else{
                this.ListaPedidoItem = [];
                var qtdeprod = 1;
                this.ProdutoSelected.qtdeProduto = qtdeprod;
                this.ProdutoSelected.valorTotalProduto = qtdeprod * (parseFloat(this.ValorTotalComputed * this.ProdutosTaxas) + parseFloat(this.PlanosGarantiasSelected));
                this.ProdutoSelected.valorProduto = (parseFloat(this.ValorTotalComputed * this.ProdutosTaxas) + parseFloat(this.PlanosGarantiasSelected));
                this.ProdutoSelected.codSetup = this.PlanosGarantiasSelected;
                this.ProdutoSelected.taxa = this.ProdutosTaxas;
                this.ProdutoSelected.vigenciaini = moment();
                this.ProdutoSelected.vigenciafim = moment(this.ProdutoSelected.vigenciaini).add(12, 'months');
                this.ProdutoSelected.proProdutos.proProdutosGrids = this.ProdutosGrid;
                this.ListaPedidoItem.push(this.ProdutoSelected);
                this.SalvarMemoria(2);
                this.dialogProdutoGarantia = false;
                this.calculatotal();
            }
           
        },
        //produtos
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        async Get() {

            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }
           // console.log('this.User')
           // console.log(this.User)

                axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data
                    //console.log('this.Situacoes')
                   // console.log(this.Situacoes)
                });


              await  axios.get('/Process/ProProdutosSegmentos/GetAllBySite')
                    .then(response => {
                        this.ListProdutosGeral = response.data.filter(x=> x.proProdutos.proVisivel);
                        this.ListProdutosGeral = this.ListProdutosGeral.map(item => {
                            return {
                                ...item,
                                nomeConcatenado: `${item.proProdutos.proNomeProduto}`
                            };
                        });

                        // Fazer a distinção dos itens com base na propriedade nomeConcatenado
                        const distinctItems = this.ListProdutosGeral.filter((item, index, self) =>
                            index === self.findIndex(t => (
                                t.nomeConcatenado === item.nomeConcatenado
                            ))
                        );
                        this.ListProdutosGeral = distinctItems.sort((a, b) => {
                            const nomeA = a.proProdutos.proNomeProduto || "";
                            const nomeB = b.proProdutos.proNomeProduto || "";
                            return nomeA.localeCompare(nomeB);
                        });
                        console.log('Itens distintos:');
                        console.log(distinctItems);

                    })
                    .catch(error => {
                        console.error(error);
                    });

                    axios.get(`/Process/CadModelosContrato/GetAll`)
                    .then(response => {
                        this.ListaContratos = response.data.filter(x=> x.tpeId == 1 && x.cmcAtivo);
                    });

                    //dados da Garantidora
                     await    axios.get('/Process/GarGarantidoras/GetAll')
                    .then(response => {
                        this.DadosGarantidora = response.data.filter(x=> x.garAtivo && x.garVisivel);
                        if (this.User.garid != 0){
                            this.DadosGarantidora = this.DadosGarantidora.filter(x=> x.garId == this.User.garid)
                            this.selectedGarantidora = this.DadosGarantidora[0];
                            this.BuscaImobialiarias();
                        }
                        //console.log('this.DadosGarantidora')
                        //console.log(this.DadosGarantidora)
                    })
                    .catch(error => {
                        console.error(error);
                    });

                   await axios.get('/Process/GarGarantidoraSerasa/Getall')
                    .then(response => {
                        this.DadosConfiguracao = response.data;
                        //console.log('this.DadosConfiguracao')
                        //console.log(this.DadosConfiguracao)
                    })
                    .catch(error => {
                        console.error(error);
                    });


                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.consumidor != ''){
                        this.selectedGarantidora = dadosArmazenados.garantidora[0];
                        await this.BuscaImobialiarias();
                        this.selectedImobiliaria = dadosArmazenados.imobiliaria[0];
                        this.SelecionarConsumidor();
                        this.selectedConsumidor = dadosArmazenados.consumidor[0];
                     
                        if (dadosArmazenados.agenda != ''){
                            //endereco
                            this.tipoImovel = dadosArmazenados.agenda[0].TipoImovel;
                            this.nomeCidade = dadosArmazenados.agenda[0].cidade;
                            this.siglaEstado = dadosArmazenados.agenda[0].estado;
                            this.nomeBairro = dadosArmazenados.agenda[0].bairro;                         
                            this.endereco = dadosArmazenados.agenda[0].endereco;
                            this.numeroend = dadosArmazenados.agenda[0].numero;
                            this.complemento = dadosArmazenados.agenda[0].complemento;
                            this.numCep = dadosArmazenados.agenda[0].cep;
                            this.residencialTipo = dadosArmazenados.agenda[0].ResidencialTipo;
                            this.showend = true;
                        }
                       // console.log('this.tipoImovel')
                       // console.log(this.tipoImovel)
                        //console.log('this.ListProdutosGeral')
                       // console.log(this.ListProdutosGeral)

                        this.ListProdutos = this.ListProdutosGeral.filter(x => 
                                                x.proProdutos.proNomeProduto && this.tipoImovel &&
                                                x.proProdutos.proNomeProduto.toLowerCase().includes(this.tipoImovel.toLowerCase())
                                                );

                      
                        //produtos
                        if (dadosArmazenados.produtos != ''){
                            this.ListaPedidoItem = dadosArmazenados.produtos[0];
                            this.PlanosGarantiasSelected = dadosArmazenados.produtos[0][0].codSetup;
                        }
                        //pagamento
                        if (dadosArmazenados.pagamento != ''){
                           this.FormaPgto = dadosArmazenados.pagamento[0].codigo;
                        }

                      this.calculatotal();
                    }
                    }
        }
    },
    created() {
        this.Get();
    },
    computed: {
    ValorTotalComputed() {
        let valorTotal = 0;
        this.ProdutosGrid.forEach(element => {
            // Certifique-se de que o valor de pgsCampoExtra1 é um número antes de somar
            const parsedValue = parseFloat(element.pisCampoExtra1);
            if (!isNaN(parsedValue)) {
                valorTotal += parsedValue;
            }
        });
        return valorTotal * 12;
    }
}
}
</script>

<style scoped>.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}


td {
    border: 1px solid #333333;
    padding: 3px;
}

.small-text {
    font-size: 10px;
    color: #dc2626;
    margin-top: 0px !important;
    position: absolute;
}


@media (min-width:1200px) {

    .fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */

    .styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}

.controlcelEnd {
    height: 600px;
        width: calc(100% - 248px) !important;
        display: flex;
        flex-direction: column;
    }

}

@media (min-width:768px) {
    .altavul {
    padding-top: 35px;
}

.fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}
}


@media (max-width:767px) {

    .fonttext{
        font-size: 12px;
    }

    .altcel {
    min-height: 300px !important;
    height: auto !important;
}
.altavul {
    padding-top: 5px;
}

.text-h5{
    font-size: 15px !important;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    padding: 0 !important;
     font-size: 12px;
}

/* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.v-toolbar-title__placeholder {
    overflow: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-toolbar-title {
    flex: auto !important;
    font-size: 1.25rem;
    min-width: 0;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75rem;
    text-transform: none;
}

}

.border-green {
    border: 4px solid green !important; /* Defina a cor e largura da borda */
    border-radius: 8px; /* Adicione um raio de borda para suavizar a aparência */
}

.check-icon {
    position: absolute;
    bottom: 5px; /* Ajuste conforme necessário */
    right: 5px; /* Ajuste conforme necessário */
    color: green;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); /* Adiciona sombra para destaque */
}

</style>
