import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueViewer from 'v-viewer';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import JSEncrypt from 'jsencrypt';
import { SHA256 } from 'crypto-js';
import Cookies from 'js-cookie';
import 'viewerjs/dist/viewer.css';
import 'swiper/swiper-bundle.css';
import 'moment/locale/pt-br'; 
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { loading } from './loading';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import TheMask from 'vue-the-mask';
import CKEditor from '@ckeditor/ckeditor5-vue';

SwiperCore.use([Navigation, Pagination]);

const decimalDirective = {
  beforeMount(el, binding) {
    const input = el.querySelector('input') || el;

    input.addEventListener('input', () => {
      let value = input.value;

      // Remover todos os caracteres não numéricos, exceto ponto e vírgula
      value = value.replace(/[^\d,]/g, '');

      // Se houver mais de uma vírgula, remove a extra
      const parts = value.split(',');
      if (parts.length > 2) {
        parts[1] = parts.slice(1).join('');
        value = parts.join(',');
      }

      // Limitar a 2 casas decimais após a vírgula
      if (parts[1] && parts[1].length > 2) {
        value = `${parts[0]},${parts[1].substring(0, 2)}`;
      }

      input.value = value;

      // Converter vírgula para ponto para o v-model (back-end usa ponto)
      const numericValue = parseFloat(value.replace(',', '.')) || null;
      binding.instance[binding.arg] = numericValue;
    });

    input.addEventListener('blur', () => {
      let value = input.value;

      // Formatar o valor para duas casas decimais ao perder o foco
      let numericValue = parseFloat(value.replace(',', '.'));
      if (!isNaN(numericValue)) {
        input.value = numericValue.toFixed(2).replace('.', ','); // Mostrar com vírgula
        binding.instance[binding.arg] = numericValue;
      } else {
        input.value = '';
        binding.instance[binding.arg] = null;
      }
    });
  }
};

const app = createApp(App)

app.use(router)
app.use(vuetify)
app.use(VueViewer)
app.use(TheMask)
app.use(CKEditor)
app.directive('decimal', decimalDirective);

app.config.globalProperties.$ckconfig = {
  toolbar: ['selectAll','heading', '|', 'bold', 'italic','strikethrough', 'underline','|', 'fontSize','fontFamily', 'link','|', 'fontColor', 'fontBackgroundColor', 'bulletedList', 'numberedList', 'blockQuote','alignment', 'insertTable', '|'],
  heading: {
    options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
    ]
},
fontFamily: {
  options: [
      'default',
      'Arial, Helvetica, sans-serif',
      'Courier New, Courier, monospace',
      'Georgia, serif',
      'Lucida Sans Unicode, Lucida Grande, sans-serif',
      'Tahoma, Geneva, sans-serif',
      'Times New Roman, Times, serif',
      'Trebuchet MS, Helvetica, sans-serif',
      'Verdana, Geneva, sans-serif'
  ],
  supportAllValues: true
},
fontSize: {
  options: [ 10, 12, 14, 'default', 18, 20, 22,25,30,50,60 ],
  supportAllValues: true
},
}

app.component('EasyDataTable', Vue3EasyDataTable);

app.config.globalProperties.$loading = loading; 
app.config.globalProperties.$DadosCompraManual = "SistemaGarantePayContratacao";
app.config.globalProperties.$ChaveAsaas = "#"


//dados GarantePay
app.config.globalProperties.$GarantePayNome = "GarantePay";
app.config.globalProperties.$GarantePayEmail = "rogerio@garantepay.com.br";
app.config.globalProperties.$GarantePayLinkAcesso = "https://garantepay.net.br/";
app.config.globalProperties.$GarantePayLinkAcessoAsten = "https://plataforma.astenassinatura.com.br/login/GARANTEPAY";


app.config.globalProperties.$scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};


app.config.globalProperties.$Cookies = Cookies;
app.config.globalProperties.$CookiesDefaults = {
  expires: 0.0832, // Expira em 2 a 5 horas 
  path: '/',
  domain: '',
  secure: false,
  sameSite: 'strict',
};

app.config.globalProperties.$moment = moment
app.config.globalProperties.$imgURL = 'https://garantepay.net.br/imagensProducao/'
//app.config.globalProperties.$imgURL = '/ImagensProducao/'
axios.defaults.baseURL = 'https://controle.garantepay.net.br/api'
//axios.defaults.baseURL = 'http://localhost:5000/api'

// Obtém o dia do ano (1 a 366)
const dayOfYear = moment().dayOfYear();
// Obtém o ano com 4 dígitos
const year = moment().year();
// Concatena o texto com o dia do ano e o ano
const hashedCookieUsuario = SHA256(`CookieSistemasGarantePayAdms${dayOfYear}${year}`).toString();

app.config.globalProperties.$CookieUsuario = hashedCookieUsuario;

app.config.globalProperties.$mensagemSucesso = function (mensagem) {
  Swal.fire(' ', mensagem, 'success')
}
app.config.globalProperties.$mensagemErro = function (mensagem) {
  Swal.fire(' ', mensagem, 'error')
}
app.config.globalProperties.$mensagemAviso = function (mensagem) {
  Swal.fire({
    position: 'top-end',
    icon: 'warning',
    title: mensagem,
    showConfirmButton: false,
    timer: 3000 // Tempo em milissegundos (3 segundos neste exemplo)
  })
}

app.config.globalProperties.$mensagemAvisoSucesso = function (mensagem) {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: mensagem,
    showConfirmButton: false,
    timer: 2000 // Tempo em milissegundos (3 segundos neste exemplo)
  })
}

app.config.globalProperties.$mensagemConfirma = function (mensagem) {
  return Swal.fire({
    icon: 'warning',
    title: mensagem,
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true
  });
};

// Crie uma instância do objeto JSEncrypt
const encryptor = new JSEncrypt();

// Chave de criptografia
const publicKey = `-----BEGIN PUBLIC KEY-----
                      MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4cbwIH80zlW9eAEUjaIz
                      eOncsBX7JX1rHpH3Oe1kj5s8FMAJkI5Lsc5Xiy7AU48BYu3Z/MnZ+Qhpxv+zpIrx
                      JJFXmqfhmIYXStLLtYOCIC4duMfiy+KLlzrIgL/+DWsutR7D/LUDDC6ucExKDxC6
                      vYaYzmpObqm08WhvRzbbU43mgdaPzJXbhZfPVIj9w9kN+ZDYaLydKXbBvwy5okpi
                      wGkSKs0mR0HKFo4U+NpfBxth28QW2JRAY9CQmwqdoFAXsDTugoupNBZHb5GBn2MQ
                      U/JXZ+VApmZCrc+4SpdxdIL7nNTEl125pf4WnWNgPLITKQrJJNhH+AOBHVRTf555
                      cQIDAQAB
                      -----END PUBLIC KEY-----`;
// Defina as chaves na instância do JSEncrypt
encryptor.setPublicKey(publicKey);

// Criptografe os dados desejados
app.config.globalProperties.$axios = axios
axios.defaults.headers.common['Authorization'] = 'Basic U2lzdGVtYUF2YW5jZUNsaTpBdlNpc0djYWQzMEx1YyE3OA==';
axios.defaults.headers.common['SisAvaKey'] = null;
axios.defaults.headers.common['SisAvaToken'] = null;

axios.interceptors.request.use((config) => {
  // Configurações adicionais antes de fazer a chamada à API
  app.config.globalProperties.$loading.value = true; // Definir o estado do loader como true
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // Tratamento de sucesso da resposta da API
    app.config.globalProperties.$loading.value = false;  // Definir o estado do loader como false
    return response;
  },
  error => {
    console.log(error);
    if (error.response && error.response.status === 401) {
      // Tratamento de erro para status 401 (Acesso não autorizado)
      app.config.globalProperties.$mensagemErro("Acesso não autorizado.");
    } else if (error.code === "ERR_NETWORK") {
      // Tratamento de erro para erro de rede
      app.config.globalProperties.$mensagemErro("Sistema fora do ar." + error);
    } else if (error.response && error.response.data && error.response.data.message) {
      // Tratamento de erro para outras respostas com mensagem
      app.config.globalProperties.$mensagemErro(error.response.data.message);
    } else {
      // Tratamento de erro genérico
      app.config.globalProperties.$mensagemErro("Falha ao conectar, contate o administrador.");
    }
    app.config.globalProperties.$loading.value = false;
    return Promise.reject(error);
  }
);

app.mount('#app')






