<template>
 <div class="hidden-sm hidden-xs">
   <div class="sidebar" data-background-color="dark">
       <div class="sidebar-logo">
          <!-- Logo Header -->
          <div class="logo-header" data-background-color="dark">
            <router-link to="/home" class="logo" style="background-color: #ffffff;padding: 5px; margin-top: 10px;border-radius: 5px;">
              <img v-if="imagemlogo!='' && imagemlogo!= null"  :src="$imgURL + imagemlogo" alt="Usuário"  class="avatar-img" style="width: 100%; height: auto" />
              <img v-else  src="/images/logogarantepay.jpg" alt="Usuário"  class="avatar-img" style="width: 100%; height: auto" />
            </router-link>
          </div>
          <!-- End Logo Header -->
        </div>
        <div class="sidebar-wrapper scrollbar scrollbar-inner">
          <div class="sidebar-content">
            <div class="sidebar-content">
              <ul class="nav nav-secondary">
                <li class="nav-item active">
                    <router-link to="/home" class="collapsed">
                      <i class="fas fa-home"></i>
                      <p>Início</p>
                    </router-link>
                 </li>
                <li class="nav-section">
                  <span class="sidebar-mini-icon">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                  <h4 class="text-section">Menu</h4>
                </li>
              </ul>
            </div>
            <v-navigation-drawer v-model="drawer" :rail="rail" permanent @click="rail = false" style="background: transparent !important;overflow-x:hidden;position: relative;max-height: 100vh; overflow-y: auto;">
                <v-list v-for="item in GrupoTelas" :key="item.grtId">
                    <v-list-group :value="item.grtNome">
                      <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" :title="item.grtNome" :prepend-icon="item.grtIcon" class="white-text" ></v-list-item>
                      </template>
                      <v-list-item v-for="subItem in Telas.filter(x=> x.admGrupoTelas.grtId == item.grtId)" :key="subItem.cdtId" :value="subItem.cdtNome" :title="subItem.cdtNome" :to="subItem.cdtUrl" prepend-icon="'-'" class="white-text">
                      </v-list-item>
                  </v-list-group>
                </v-list>
              </v-navigation-drawer>
          </div>
        </div>
   </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageHeader',
  components: {
  },
  data: () => ({
    drawer: true,
    rail: false,
    isSidebarVisible: false,
    isDropdownOpen: false,
    QtdeSegInst: 0,
    Telas:[],
    GrupoTelas:[],
    imagemlogo: '',
    Garantidora:[],
    ListaMeusContratos:[],
    libera:[],
  }),
  methods: {
    toggleMenu(item) {
      item.isOpen = !item.isOpen;
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    CarregaTelas(){
       //carrega menu grupo de telas e telas
       axios.get(`/Process/AdmGrupoTelas/GetAll`)
          .then(response => {
        this.GrupoTelas = response.data.filter(x => x.grtVisivel).sort((a, b) => {
                                                                                  const valorA = parseInt(a.grtOrdem) || 0; 
                                                                                  const valorB = parseInt(b.grtOrdem) || 0; 
                                                                                  return valorA - valorB;
                                                                                  });


            axios.get(`Process/AdmCadastroTelas/GetAll`)
                .then(response => {
                    this.Telas = response.data.filter(x => x.cdtVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.cdtOrdem) || 0;
                                                                                          const valorB = parseInt(b.cdtOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                


                    const arrayFiltrado2 = this.Telas.filter(objeto => this.User.menu.some(item => item.cdtId === objeto.cdtId));
                    this.Telas = arrayFiltrado2;
                    
                    const arrayFiltrado1 = this.GrupoTelas.filter(objeto => this.Telas.some(item => item.admGrupoTelas && item.admGrupoTelas.grtId === objeto.grtId));
                    this.GrupoTelas = arrayFiltrado1;
                });
      });
    }
  },
 async created() {
    const valorArmazenado = localStorage.getItem('_useravgar');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);

      if (this.User.urlimage == '') {
        this.User.urlimage = "/images/userNo.png";
      }
      else {
        this.User.urlimage = this.$imgURL + this.User.urlimage;
      }
    }

   // console.log('this.User')
   // console.log(this.User)
    //carrega menu grupo de telas e telas
    if (this.User.garid != 0){
     await axios.get(`/Process/GarGarantidoras/GetById/` + this.User.garid) 
            .then(response => {
                this.Garantidora = response.data;
                this.imagemlogo = this.Garantidora.garUrlLogo;
            });

    }
    
    if (this.User.garid != 0 && this.User.tpeid == 1){

      await axios.get(`/Process/GarGarantidoraContrato/GetByGarId/` + this.User.garid) 
           .then(response => {
              this.ListaMeusContratos = response.data; 

              this.libera = this.ListaMeusContratos.filter(x=> x.gctDataAss === null); 
              if (this.libera.length === 0)
              {
                this.CarregaTelas();
              }                 
          });
    }
    else if (this.User.garid != 0 && this.User.tpeid == 3)
    {
      await axios.get(`/Process/ImoImobiliariaContrato/GetByImoId/` + + this.User.pesid) 
                .then(response => {
                    this.ListaMeusContratos = response.data;       
                    this.libera = this.ListaMeusContratos.filter(x=> x.ictDataAss === null); 
                      if (this.libera.length === 0)
                      {
                        this.CarregaTelas();
                      }                                  
                });
    }
    else{
      this.CarregaTelas();
    }
  },

}
</script>

<style>
.white-text{
  color:#ffffff;
}

.v-list-group__items .v-list-item {
    -webkit-padding-start: 0px !important;
    padding-inline-start: 0px !important;
}

.v-list-item-title{
  font-size: 13px !important;
}

.v-list-item--density-default.v-list-item--one-line {
    min-height: 28px !important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.v-list {
    padding: 0px 0 !important;
}


/* Estilo para o scrollbar personalizado */
.v-navigation-drawer__content {
  max-height: 100vh;
  overflow-y: auto;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 8px !important; /* Largura do scrollbar */
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  background: transparent !important; /* Fundo do track */
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3) !important; /* Cor do "polegar" do scrollbar */
  border-radius: 4px !important; /* Borda arredondada */
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5) !important; /* Cor ao passar o mouse */
}

</style>
