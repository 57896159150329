<template>
    <div>
        <!--Edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Garantidoras</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de garantidoras</a>
                        </li>
                        <li class="separator" v-if="User.garid == 0"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item" v-if="User.garid == 0"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#dadcdf">
                                    <v-tab value="dados" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Dados Garantidora</v-tab>
                                    <div v-if="Garantidora.garId != 0"> 
                                        <v-tab value="endereco" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Endereço</v-tab>
                                    </div>
                                    <div v-if="User.garid == 0 && Garantidora.garId != 0">
                                        <v-tab value="plano" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Plano</v-tab>
                                    </div>
                                    <div v-if="Garantidora.garId != 0">
                                        <v-tab value="serasa" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Análise de crédito</v-tab>
                                    </div>
                                    <div v-if="User.garid == 0 && Garantidora.garId != 0">
                                        <v-tab value="assinatura" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Assinatura</v-tab>
                                    </div>
                                    <div v-if="Garantidora.garId != 0">
                                        <v-tab value="pagamento" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Pagamento</v-tab>
                                    </div>
                                    <div v-if="Garantidora.garId != 0">
                                        <v-tab value="contrato" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Contrato</v-tab>
                                    </div>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item value="dados">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Garantidora.garId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2" v-if="User!=''">
                                                        <div class="form-group">
                                                            <label>Tipo Pessoa</label>
                                                            <select  v-model="Garantidora.tpdId" class="form-select form-control" :disabled="User.tpeid == 1">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in TiposDocumentos" :key="optionTipo.tpdId" 
                                                                    :value="optionTipo.tpdId">{{ optionTipo.tpdNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="User!=''">
                                                        <div class="form-group">
                                                            <label>Nº {{ Garantidora.tpdId == 1 ? 'CPF' : 'CNPJ'}}</label>
                                                            <input v-model="Garantidora.garDocumento" type="text" v-mask="documentoMask" :disabled="User.tpeid == 1"
                                                                class="form-control" :placeholder="Garantidora.tpdId == 1 ? 'CPF' : 'CNPJ'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Nome</label>
                                                            <input v-model="Garantidora.garNomeEmpresa" type="text"
                                                                class="form-control" :placeholder="Garantidora.tpdId == 1 ? 'Nome' : 'Nome Fantasia'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Garantidora.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Razão Social</label>
                                                            <input v-model="Garantidora.garRazaoSocial" type="text"
                                                                class="form-control" placeholder="Razão Social" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Garantidora.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Nome Responsável</label>
                                                            <input v-model="Garantidora.garResponsavel" type="text"
                                                                class="form-control" placeholder="Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Garantidora.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>CPF Responsável</label>
                                                            <input v-model="Garantidora.garCampoExtra1" type="text" v-mask="'###.###.###-##'"
                                                                class="form-control" placeholder="CPF Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Telefone</label>
                                                            <input v-model="Garantidora.garTelefone" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Telefone" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Whatsapp</label>
                                                            <input v-model="Garantidora.garWhatsapp" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Whatsapp" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="User!=''">
                                                        <div class="form-group">
                                                            <label>E-mail</label>
                                                            <input v-model="Garantidora.garEmail" type="text" class="form-control" :disabled="User.tpeid == 1"
                                                                placeholder="E-mail" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Indicação</label>
                                                            <input v-model="Garantidora.garCampoExtra2" type="text" class="form-control" :disabled="User.tpeid == 1"
                                                                placeholder="Indicação" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="exampleFormControlFile1">Upload logo</label>
                                                            <v-file-input v-model="selectedFile" label="Imagem" variant="underlined"
                                                                prepend-icon="mdi-account-circle" accept="image/*"></v-file-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="Garantidora.garUrlLogo !== '' && Garantidora.garUrlLogo !== null">
                                                        <div class="form-group">
                                                            <img :src="$imgURL + Garantidora.garUrlLogo" style="width: 100px" />
                                                            <v-btn icon density="compact" color="red" class="delete-icon"
                                                                variant="text">
                                                                <v-icon @click="DeleteImagem()"
                                                                    style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereço-->
                                    <v-window-item value="endereco">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Endereco.gaeId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <input v-model="Endereco.garId" type="text"
                                                                class="form-control" placeholder="Id Garantidora" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>CEP</label>
                                                            <input v-model="Endereco.gaeCep" v-mask="'#####-###'" type="text"
                                                                class="form-control" placeholder="CEP" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group" style="padding-top: 35px;">
                                                            <button type="button" class="btn btn-info" @click.prevent="Buscacep(Endereco.gaeCep)" style="color:#ffffff;">
                                                                Verificar CEP
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Endereço</label>
                                                            <input v-model="Endereco.gaeEndereco" type="text"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Número</label>
                                                            <input v-model="Endereco.gaeNumero" type="number"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Complemento</label>
                                                            <input v-model="Endereco.gaeComplemento" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Estado</label>
                                                            <input v-model="Endereco.gaeEstado" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Cidade</label>
                                                            <input v-model="Endereco.gaeCidade" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Bairro</label>
                                                            <input v-model="Endereco.gaeBairro" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10" v-if="showend">
                                                        <div class="form-group">
                                                            <button v-if="Endereco.gaeId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="EnderecoPut()">
                                                                Alterar Endereço
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="EnderecoAdd()">
                                                                Salvar Endereço
                                                            </button>
                                                            <a href="#" @click="EnderecoCancel()" class="btn btn-link">Cancelar</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereco-->
                                     <!--plano-->
                                     <v-window-item value="plano">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="GarantidoraPlanos.gplId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <input v-model="GarantidoraPlanos.garId" type="text"
                                                                class="form-control" placeholder="Id Garantidora" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Planos</label>
                                                            <select  v-model="GarantidoraPlanos.cpgId" class="form-select form-control">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in ListaPlanos" :key="optionTipo.cpgId"
                                                                    :value="optionTipo.cpgId">{{ optionTipo.cpgNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Valor Mensal R$</label>
                                                            <input v-model="GarantidoraPlanos.gplValor" v-decimal
                                                                class="form-control" placeholder="Valor Mensal" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Tipo Cobrança</label>
                                                            <select v-model="GarantidoraPlanos.gplTipoCobranca" class="form-select form-control">
                                                                <option value="">Selecione</option>
                                                                <option value="Pré">Pré</option>
                                                                <option value="Pós">Pós</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Vencimento Dia</label>
                                                            <input v-model="GarantidoraPlanos.gplVencimento" type="number"
                                                                class="form-control" placeholder="Vencimento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Vigência Inicial</label>
                                                            <input v-model="GarantidoraPlanos.gplVigenciaInicial" type="date"
                                                                class="form-control" placeholder="Vigência" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Remuneração por Negócio</label>
                                                            <input v-model="GarantidoraPlanos.gplRemuneracaoNegocio" v-decimal
                                                                class="form-control" placeholder="Remuneração" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Valor de Adesão R$</label>
                                                            <input v-model="GarantidoraPlanos.gplValorAdesao" v-decimal
                                                                class="form-control" placeholder="Valor de Adesão" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Qtde Usuários</label>
                                                            <input v-model="GarantidoraPlanos.gplQtdeUsuarios" type="number"
                                                                class="form-control" placeholder="Qtde Usuários" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10">
                                                        <div class="form-group">
                                                            <button v-if="GarantidoraPlanos.gplId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="PlanoPut()">
                                                                Alterar Plano
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="PlanoAdd()">
                                                                Salvar Plano
                                                            </button>
                                                            <a href="#" @click="PlanoCancel()" class="btn btn-link">Cancelar</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                      <!--plano-->
                                      <!--serasa-->
                                      <v-window-item value="serasa">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Serasa.gseId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <input v-model="Serasa.garId" type="text"
                                                                class="form-control" placeholder="Id Garantidora" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="User.garid == 0">
                                                        <div class="form-group">
                                                            <label>Qtde Consultas Mensal</label>
                                                            <input v-model="Serasa.gseQtdeMensal" type="number"
                                                                class="form-control" placeholder="Qtde Consultas" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="User.garid == 0">
                                                        <div class="form-group">
                                                            <label>Custo Consulta Extra R$</label>
                                                            <input v-model="Serasa.gseCustoPesExtra" v-decimal
                                                                class="form-control" placeholder="Custo Extra" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="User.garid == 0" v-show="false">
                                                        <div class="form-group">
                                                            <label>Qtde Grátis Mensal</label>
                                                            <input v-model="Serasa.gseQtdeGratisMensal" type="number"
                                                                class="form-control" placeholder="Qtde Grátis" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Nível de Aprovação</label>
                                                            <v-slider
                                                                v-model="Serasa.gseRangeAprovacao"
                                                                :max="1000"
                                                                :min="0"
                                                                :step="50"
                                                                thumb-label="always"
                                                                style="padding-top: 20px;"
                                                            ></v-slider>
                                                        </div>
                                                    </div>
                                                      <div class="col-md-10 col-lg-10">
                                                        <div class="form-group">
                                                            <button v-if="Serasa.gseId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="SerasaPut()">
                                                                Alterar Análise
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="SerasaAdd()">
                                                                Salvar Análise
                                                            </button>
                                                            <a href="#" @click="SerasaCancel()" class="btn btn-link">Cancelar</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                      </v-window-item>
                                      <!--serasa-->
                                       <!--Assinatura-->
                                       <v-window-item value="assinatura">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Ao salvar, será gerada integração com Asten para preenchimento dos dados de repositório e Token.<br>
                                                                Bem como será disparada notificação para a Garantidora complementar dados de cadastro na plataforma de assinaturas.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Assinatura.gasId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <input v-model="Assinatura.garId" type="text"
                                                                class="form-control" placeholder="Id Garantidora" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Qtde Envelopes Mensal</label>
                                                            <input v-model="Assinatura.gasQtdeMensal" type="number"
                                                                class="form-control" placeholder="Qtde Envelopes" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Custo Extra R$</label>
                                                            <input v-model="Assinatura.gasCustoPesExtra" v-decimal
                                                                class="form-control" placeholder="Custo Extra" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>CPF para Assinaturas</label>
                                                            <input v-model="Assinatura.gasPropriedade" type="number"
                                                                class="form-control" placeholder="CPF para Assinaturas" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-show="false">
                                                        <div class="form-group">
                                                            <label>Qtde Grátis Mensal</label>
                                                            <input v-model="Assinatura.gasQtdeGratisMensal" type="number"
                                                                class="form-control" placeholder="Qtde Grátis" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Código Repositorio</label>
                                                            <input v-model="Assinatura.gasIdRepositorio" type="text" disabled
                                                                class="form-control" placeholder="Repositorio" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Token</label>
                                                            <input v-model="Assinatura.gasToken" type="text" disabled
                                                                class="form-control" placeholder="Token" />
                                                        </div>
                                                    </div>
                                                      <div class="col-md-10 col-lg-10">
                                                        <div class="form-group">
                                                            <button v-if="Assinatura.gasId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="AssinaturaPut()">
                                                                Alterar Assinatura
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="AssinaturaAdd()">
                                                                Salvar Assinatura
                                                            </button>
                                                            <a href="#" @click="AssinaturaCancel()" class="btn btn-link">Cancelar</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                       </v-window-item>
                                       <!--Assinatura-->
                                       <!--Pagamentos-->
                                       <v-window-item value="pagamento">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Pagamento.gpgId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <input v-model="Pagamento.garId" type="text"
                                                                class="form-control" placeholder="Id Garantidora" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2" v-if="User.garid == 0">
                                                        <div class="form-group">
                                                            <label>Operadoras</label>
                                                            <select  v-model="Pagamento.topId" class="form-select form-control">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in TipoOperadoras" :key="optionTipo.topId"
                                                                    :value="optionTipo.topId">{{ optionTipo.topNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>E-mail Notificação</label>
                                                            <input v-model="Pagamento.gpgEmailAviso" type="text"
                                                                class="form-control" placeholder="E-mail Notificação" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6" v-if="User.garid == 0">
                                                        <div class="form-group">
                                                            <label>Chave de identificação</label>
                                                            <input v-model="Pagamento.gpgSplitToken" type="text"
                                                                class="form-control" placeholder="Chave Identificação" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-show="false">
                                                        <div class="form-group">
                                                            <label>Agência</label>
                                                            <input v-model="Pagamento.gpgSplitAgencia" type="text"
                                                                class="form-control" placeholder="Agência" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-show="false">
                                                        <div class="form-group">
                                                            <label>Conta</label>
                                                            <input v-model="Pagamento.gpgSplitConta" type="text"
                                                                class="form-control" placeholder="Conta" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-show="false">
                                                        <div class="form-group">
                                                            <label>Titular</label>
                                                            <input v-model="Pagamento.gpgSplitTitularConta" type="text"
                                                                class="form-control" placeholder="Titular" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-show="false">
                                                        <div class="form-group">
                                                            <label>Documento</label>
                                                            <input v-model="Pagamento.gpgSplitTitularDocumento" type="text"
                                                                class="form-control" placeholder="Documento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6" v-show="false">
                                                        <div class="form-group">
                                                            <label>Chave Pix</label>
                                                            <input v-model="Pagamento.gpgSplitChavePix" type="text"
                                                                class="form-control" placeholder="Chave Pix" />
                                                        </div>
                                                    </div>
                                                      <div class="col-md-10 col-lg-10">
                                                        <div class="form-group">
                                                            <button v-if="Pagamento.gpgId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="PagamentoPut()">
                                                                Alterar Pagamento
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="PagamentoAdd()">
                                                                Salvar Pagamento
                                                            </button>
                                                            <a href="#" @click="PagamentoCancel()" class="btn btn-link">Cancelar</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                       </v-window-item>
                                       <!--Pagamentos-->
                                        <!--Contratos-->
                                        <v-window-item value="contrato">
                                        <v-form>
                                            <v-container>
                                                <div class="row" v-if="User.garid == 0">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Contrato.gctId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <input v-model="Contrato.garId" type="text"
                                                                class="form-control" placeholder="Id Garantidora" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Modelos de Contrato</label>
                                                            <select  v-model="Contrato.cmcId" class="form-select form-control">
                                                                <option value="">Selecione</option>
                                                                <option v-for="optionTipo in ListaContratos" :key="optionTipo.cmcId"
                                                                    :value="optionTipo.cmcId">{{ optionTipo.cmcNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label></label>
                                                            <div>
                                                                <button type="button" class="btn btn-sm btn-success" 
                                                                 style="color:#ffffff;margin-top:12px;" @click="ContratoAdd()">Adicionar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <v-form>
                                                        <v-container>
                                                            <v-row>
                                                                <v-table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Contrato
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Status
                                                                            </th>
                                                                            <th class="text-left">
                                                                                Data Assinatura
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Actions
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="item in ListaMeusContratos"
                                                                            :key="item.gctId">
                                                                            <td>{{ ListaContratos.filter(x=> x.cmcId == item.cmcId)[0].cmcNome }}</td>
                                                                            <td>{{ item.gctStatusEnvelope == null ? 'Não enviado p/assinatura' :  item.gctDataAss == null ? 'Envelope encaminhado para coleta de assinaturas!' : 'Assinado' }}</td>
                                                                            <td>{{ item.gctDataAss }}</td>
                                                                            <td style="text-align:center;">
                                                                                <v-btn icon @click.prevent="VisualizarContrato(item)"
                                                                                    variant="text">
                                                                                    <v-icon>mdi-eye</v-icon>
                                                                                    <v-tooltip activator="parent"
                                                                                        location="top">Visualizar</v-tooltip>
                                                                                </v-btn>
                                                                                <v-btn v-if="item.gctStatusEnvelope == null" class="btn btn-sm btn-info" @click.prevent="EnviarAssinatura(item)" variant="text">
                                                                                     Enviar p/ Assinatura
                                                                                </v-btn>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </v-table>
                                                            </v-row>
                                                        </v-container>
                                                    </v-form>
                                                </div>
                                            </v-container>
                                        </v-form>
                                       </v-window-item>
                                         <!--Contratos-->
                                </v-window>
                            </div>
                        </div>
                    </div>
                    <div class="card-action" v-if="tab=='dados'">
                        <button v-if="Garantidora.garId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                            @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                        <div  class="col-md-3 col-lg-3" v-if="User != '' && User.garid == 0" style="float: right;">
                            <div class="form-group">
                                <button type="button" class="btn btn-sm btn-info" style="color:#ffffff;" @click="CriarUsuarioEmail()">Criar e Notificar Usuário de acesso</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de garantidoras</h4>
                                    <a v-if="User.garid == 0" href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="ListGarantidoras"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>
                                        <v-btn icon @click="DeleteGrid(item)" variant="text" v-if="User!='' && User.garid == 0">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'GarantidorasView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        EditView: false,
        User:[],
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Garantidoras',
        InfoTela:'Lista de garantidoras',
        MostraGrid: true,
        headers: [],
        //dados
        Garantidora: {
            garId: 0,
            garVisivel: null,
            garDataCadastro: null,
            garNomeEmpresa: '',
            garRazaoSocial: '',
            tpdId: null,
            garDocumento: '',
            garTelefone: '',
            garWhatsapp: '',
            garResponsavel: '',
            garEmail: '',
            garAtivo: null,
            garDataAniversario: null,
            garCampoExtra1: '',
            garCampoExtra2: '',
            garCampoExtra3: '',
            garCampoExtra4: '',
            garCampoExtra5: '',
            garTexto1: '',
            garTexto2: '',
            garTexto3: '',
            garData1: null,
            garData2: null,
            garUrlLogo: ''
        },
        ListGarantidoras: [],
        ListGarantidorasGeral:[],
        TiposDocumentos:[],
        //dados
        //imagens
        selectedFile: null,
        //endereco
        Endereco: {
            gaeId: 0,
            grtId: 0,
            cdtId: 0,
            gaeEndereco: '',
            gaeNumero: 0,
            gaeComplemento: '',
            gaeObsEndereco: '',
            gaeCep: '',
            gaePais: '',
            gaeEstado: '',
            gaeCidade: '',
            gaeBairro: '',
            garId: null
        },
        ListaEnderecos:[],
        showend:false,
        Retornocep:null,
        //endereco
        //planos
        GarantidoraPlanos: {
            gplId: 0,
            grtId: 0,
            cdtId: 0,
            cpgId: null,
            garId: null,
            gplValor: null,
            gplVencimento: '',
            gplVigenciaInicial: null,
            gplTipoCobranca: '',
            gplRemuneracaoNegocio: null,
            gplQtdeUsuarios: '',
            gplValorAdesao: null
        },
        ListaPlanos:[],
        //planos
        //serasa
         Serasa: {
            gseId: 0,
            grtId: 0,
            cdtId: 0,
            gsePropriedade: 0,
            gseQtdeMensal: '',
            gseCustoPesExtra: null, 
            gseQtdeGratisMensal: 0,
            gseRangeAprovacao: 0,
            garId: 0
        },
        ListaSerasa:[],
        //serasa
        //assinatura
        Assinatura: {
            gasId: 0,
            grtId: 0,
            cdtId: 0,
            gasPropriedade: '',
            gasQtdeMensal: '',
            gasCustoPesExtra: 0, 
            gasQtdeGratisMensal: 0,
            gasIdRepositorio:'',
            gasToken:'',
            garId: ''
        },
        ListaAssinaturas:[],
        //assinatura
        //Pagamentos
        Pagamento: {
            gpgId: 0,
            grtId: 0,
            cdtId: 0,
            garId: null,
            topId: null,
            gpgEmailAviso: '',
            gpgSplitToken: '',
            gpgSplitBanco: '',
            gpgSplitBancoNome: '',
            gpgSplitAgencia: '',
            gpgSplitConta: '',
            gpgSplitTitularConta: '',
            gpgSplitTitularDocumento: '',
            gpgSplitChavePix: '',
            gpgSplitTipoConta: ''
        },
        ListaPagamentos:[],
        TipoOperadoras:[],
        //Pagamentos
        //contrato
        Contrato: {
            gctId: 0,
            grtId: 0,
            cdtId: 0,
            garId: null,
            cmcId: '',
            gctUrlContrato: null,
            gctIp: null,
            gctDataAss: null, // Data inicializada como null
            gctRetornoJson: null,
            gctIdenvelope: null,
            gctStatusEnvelope: null
        },
      ListaContratos:[],
      ListaMeusContratos:[],
      ListUser:[]
        //contrato
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListGarantidoras = this.ListGarantidorasGeral;
        },
        //imagens
        //garantidora
         //edit
        EditNew(){
        this.EditView = true;
        this.Garantidora.garId = 0;
        this.Garantidora.garVisivel= null;
        this.Garantidora.garDataCadastro= null;
        this.Garantidora.garNomeEmpresa= '';
        this.Garantidora.garRazaoSocial= '';
        this.Garantidora.tpdId= null;
        this.Garantidora.garDocumento= '';
        this.Garantidora.garTelefone= '';
        this.Garantidora.garWhatsapp= '';
        this.Garantidora.garResponsavel= '';
        this.Garantidora.garEmail= '';
        this.Garantidora.garAtivo= null;
        this.Garantidora.garDataAniversario= null;
        this.Garantidora.garCampoExtra1= '';
        this.Garantidora.garCampoExtra2= '';
        this.Garantidora.garCampoExtra3= '';
        this.Garantidora.garCampoExtra4= '';
        this.Garantidora.garCampoExtra5= '';
        this.Garantidora.garTexto1= '';
        this.Garantidora.garTexto2= '';
        this.Garantidora.garTexto3= '';
        this.Garantidora.garData1= null;
        this.Garantidora.garData2= null;
        this.Garantidora.garUrlLogo= '';
        //endereco
        this.Endereco.gaeId = 0;
        this.Endereco.grtId = 0;
        this.Endereco.cdtId = 0;
        this.Endereco.gaeEndereco = '';
        this.Endereco.gaeNumero = 0;
        this.Endereco.gaeComplemento = '';
        this.Endereco.gaeObsEndereco = '';
        this.Endereco.gaeCep = '';
        this.Endereco.gaePais = '';
        this.Endereco.gaeEstado = '';
        this.Endereco.gaeCidade = '';
        this.Endereco.gaeBairro = '';
        this.showend = false;
        this.Retornocep = null;
        //planos
        this.GarantidoraPlanos.gplId = 0;
        this.GarantidoraPlanos.grtId = 0;
        this.GarantidoraPlanos.cdtId = 0;
        this.GarantidoraPlanos.cpgId = null;
        this.GarantidoraPlanos.gplValor = null;
        this.GarantidoraPlanos.gplVencimento = '';
        this.GarantidoraPlanos.gplVigenciaInicial = null;
        this.GarantidoraPlanos.gplTipoCobranca = '';
        this.GarantidoraPlanos.gplRemuneracaoNegocio = null;
        this.GarantidoraPlanos.gplQtdeUsuarios = '';
        this.GarantidoraPlanos.gplValorAdesao = null;
        //serasa
        this.Serasa.gseId = 0;
        this.Serasa.grtId = 0;
        this.Serasa.cdtId = 0;
        this.Serasa.gsePropriedade = 0;
        this.Serasa.gseQtdeMensal = '';
        this.Serasa.gseCustoPesExtra = null; 
        this.Serasa.gseQtdeGratisMensal = 0;
        this.Serasa.gseRangeAprovacao = 0;
        //assinatura
        this.Assinatura.gasId = 0;
        this.Assinatura.grtId = 0;
        this.Assinatura.cdtId = 0;
        this.Assinatura.gasPropriedade = '';
        this.Assinatura.gasQtdeMensal = '';
        this.Assinatura.gasCustoPesExtra = 0; 
        this.Assinatura.gasQtdeGratisMensal = 0;
        this.Assinatura.gasIdRepositorio ='';
        this.Assinatura.gasToken ='';
        //pagamento
        this.Pagamento.gpgId = 0;
        this.Pagamento.grtId = 0;
        this.Pagamento.cdtId = 0;
        this.Pagamento.topId = null;
        this.Pagamento.gpgEmailAviso = '';
        this.Pagamento.gpgSplitToken = '';
        this.Pagamento.gpgSplitBanco = '';
        this.Pagamento.gpgSplitBancoNome = '';
        this.Pagamento.gpgSplitAgencia = '';
        this.Pagamento.gpgSplitConta = '';
        this.Pagamento.gpgSplitTitularConta = '';
        this.Pagamento.gpgSplitTitularDocumento = '';
        this.Pagamento.gpgSplitChavePix = '';
        this.Pagamento.gpgSplitTipoConta = '';
        //contrato
        this.Contrato.gctId = 0;
        this.Contrato.grtId = 0;
        this.Contrato.cdtId = 0;
        this.Contrato.cmcId = '';
        this.Contrato.gctUrlContrato = null;
        this.Contrato.gctIp = null;
        this.Contrato.gctDataAss = null; // Data inicializada como null
        this.Contrato.gctRetornoJson = null;
        this.Contrato.gctIdenvelope = null;
        this.Contrato.gctStatusEnvelope = null;
        this.ListaMeusContratos= [];
        this.tab = 'dados';
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        EditGrid(item) {
            this.EditView = true;
            this.tab = 'dados';
            //console.log('item')
            //console.log(item)
            if (item.garId != '') {
                // Lógica para editar o item
                 axios.get(`/Process/GarGarantidoras/GetById/` + item.garId) 
                    .then(response => {
                        this.Garantidora = response.data;

                        //carregar endereços
                        this.CarregaEndereco(item.garId);
                        //carregar planos
                        this.CarregaPlano(item.garId);
                        //carregar serasa
                        this.CarregaSerasa(item.garId);
                        //carregar serasa
                        this.CarregaPagamentos(item.garId);
                         //carregar assinaturas
                         this.CarregaAssinatura(item.garId);
                         //carregar contratos
                         this.CarregaContratos(item.garId);
                    });
            }
        },
        async Add() {
               if (this.Garantidora.tpdId === '' || this.Garantidora.tpdId === null) {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
               }
               else if (this.Garantidora.garDocumento == '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
               else if (this.Garantidora.garNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
                else if (this.Garantidora.garTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Garantidora.garWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Garantidora.garEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Garantidora.garResponsavel === '' && this.Garantidora.tpdId === 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Garantidora.garCampoExtra1 === '' && this.Garantidora.tpdId === 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
                else {
                    if (this.Garantidora.garVisivel === "" || this.Garantidora.garVisivel === null) {
                        this.Garantidora.garVisivel = true;
                    }
                    if (this.Garantidora.garAtivo === "" || this.Garantidora.garAtivo === null) {
                        this.Garantidora.garAtivo = true;
                    }
                    this.Garantidora.garDataCadastro = this.$moment().format('YYYY-MM-DD')

                    if (this.selectedFile != '' && this.selectedFile != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Garantidora.garUrlLogo = uploadSuccess.data.nameFile;
                        axios.post(`/Process/GarGarantidoras/Add`, this.Garantidora)
                            .then(response => {
                                if (response.data > 0) {
                                    this.Garantidora.garId = response.data;
                                    //carregar endereços
                                    this.CarregaEndereco(this.Garantidora.garId);
                                    //carregar planos
                                    this.CarregaPlano(this.Garantidora.garId);
                                    //carregar serasa
                                    this.CarregaSerasa(this.Garantidora.garId);
                                    //carregar serasa
                                    this.CarregaPagamentos(this.Garantidora.garId);
                                    //carregar assinaturas
                                    this.CarregaAssinatura(this.Garantidora.garId);
                                    //carregar contratos
                                    this.CarregaContratos(this.Garantidora.garId);
                                    this.$mensagemAvisoSucesso("Registro Incluído. abas para informações adicionais liberadas.")
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.Garantidora.garUrlLogo = "";
                    axios.post(`/Process/GarGarantidoras/Add`, this.Garantidora)
                        .then(response => {
                            if (response.data > 0) {
                                    this.Garantidora.garId = response.data;
                                    //carregar endereços
                                    this.CarregaEndereco(this.Garantidora.garId);
                                    //carregar planos
                                    this.CarregaPlano(this.Garantidora.garId);
                                    //carregar serasa
                                    this.CarregaSerasa(this.Garantidora.garId);
                                    //carregar serasa
                                    this.CarregaPagamentos(this.Garantidora.garId);
                                    //carregar assinaturas
                                    this.CarregaAssinatura(this.Garantidora.garId);
                                    //carregar contratos
                                    this.CarregaContratos(this.Garantidora.garId);
                                    this.$mensagemAvisoSucesso("Registro Incluído. abas para informações adicionais liberadas.")
                            }
                        });
                }
            }
        },
        async Put() {
            if (this.Garantidora.tpdId === '') {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
               }
               else if (this.Garantidora.garDocumento == '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
               else if (this.Garantidora.garNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
                }
                else if (this.Garantidora.garTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Garantidora.garWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Garantidora.garEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Garantidora.garResponsavel === '' && this.Garantidora.tpdId === 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Garantidora.garCampoExtra1 === '' && this.Garantidora.tpdId === 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
            else {
                if (this.Garantidora.garVisivel === "" || this.Garantidora.garVisivel === null) {
                        this.Garantidora.garVisivel = true;
                    }
                    if (this.Garantidora.garAtivo === "" || this.Garantidora.garAtivo === null) {
                        this.Garantidora.garAtivo = true;
                    }
             
                    if (this.selectedFile != '' && this.selectedFile != null) {
                    //  console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Garantidora.garUrlLogo = uploadSuccess.data.nameFile;
                        axios.put(`/Process/GarGarantidoras/Update`, this.Garantidora)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Alterado.")
                                    this.Get();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    if (this.Garantidora.garUrlLogo != "") {
                        this.Garantidora.garUrlLogo = "0";
                    }
                    axios.put(`/Process/GarGarantidoras/Update`, this.Garantidora)
                        .then(response => {
                            if (response.status == 200) {
                                this.$mensagemAvisoSucesso("Registro Alterado.")
                                this.Get();
                            }
                        });
                }
            }
        },
        CriarUsuarioEmail(){
            if (this.GarantidoraPlanos.cpgId === '') {
                    this.$mensagemAviso("Campo Plano Assinatura Obrigatório.")
            }
            else if (this.Serasa.gseRangeAprovacao === '' || this.Serasa.gseRangeAprovacao === '0'){
                this.$mensagemAviso("Campo Serasa - Nível de aprovação Obrigatório.")
            }
            else if (this.Assinatura.gasIdRepositorio === ''){
                this.$mensagemAviso("Campo Assinaturas - Id Repositório Obrigatório.")
            }
            else if (this.Pagamento.topId === ''){
                this.$mensagemAviso("Campo Pagamento - Operadora Obrigatório.")
            }
            else if (this.Pagamento.gpgEmailAviso === ''){
                this.$mensagemAviso("Campo Pagamento - E-mail Notificação Obrigatório.")
            }
            else if (this.Pagamento.gpgSplitToken === ''){
                this.$mensagemAviso("Campo Pagamento - Chave de Identificação Obrigatório.")
            }
            else if (this.ListaMeusContratos.length == 0){
                this.$mensagemAviso("Campo Contrato - Modelo de contrato Obrigatório ser adicionado.")
            }
            else if (this.ListaMeusContratos.filter(x=> x.gctIdEnvelope === null).length > 0){
                this.$mensagemAviso("Contrato precisar ser enviado para assinatura.")
            }
            else{
                axios.get(`/Process/UsrCadastroUsuarios/GetByEmail/` + this.Garantidora.garEmail)
                .then(response => {
                    this.ListUser = response.data.filter(x=> x.tpeId == 1);
                    console.log('this.ListUser')
                    console.log(this.ListUser)
                    if (this.ListUser.length == 0)
                    {
                            var _usuario = {
                                cusId: 0,
                                cusNome: this.Garantidora.garNomeEmpresa,
                                cusEmail: this.Garantidora.garEmail,
                                cusTelefone: this.Garantidora.garTelefone,
                                cusWhatsapp: this.Garantidora.garWhatsapp,
                                cusSenha: this.Garantidora.garWhatsapp.slice(-4),
                                cusDtCadastro: this.$moment(),
                                tusId: 5,
                                cusAtivo: true,
                                cusUrlImagem: '',
                                cliId: 0,
                                admTipoUsuario: {tusId: 5},
                                tpeId:1,
                                garId: this.Garantidora.garId,
                                pesId: this.Garantidora.garId,
                                }
                                axios.post(`/Process/UsrCadastroUsuarios/Add`, _usuario)
                                    .then(response => {
                                        if (response.data > 0) {
                                            _usuario.cusId = response.data;
                                           this.EnviaEmailCadastro(_usuario);
                                           this.$mensagemAviso("Usuário criado e notificado.")
                                        }
                                    });
                                        
                    }
                    else{
                        this.$mensagemAviso("Usuário já possui cadastro ativo.")
                    }
                });
            }
        },
        EnviaEmailCadastro(usuariocad){
              axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 4);
                            let _dadosEmail = {
                                            nomeDe: this.$GarantePayNome,
                                            nomePara: usuariocad.cusNome,
                                            assunto: 'Cadastro Garantidora - Sistema GarantePay',
                                            destinatario: usuariocad.cusEmail,
                                            emailResposta: this.$GarantePayEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomeGarantidora]',this.Garantidora.garNomeEmpresa)
                                                        .replace('[NomeUsuario]',this.Garantidora.garResponsavel)
                                                        .replace('[LinkAcesso]', this.$GarantePayLinkAcesso)
                                                        .replace('[UsuarioLogin]', usuariocad.cusEmail)
                                                        .replace('[SenhaAcesso]', usuariocad.cusSenha)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        //edit
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.garId;
                axios.delete(`/Process/GarGarantidoras/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "garId", sortable: true, });
            this.headers.push({ text: "Nome", value: "garNomeEmpresa", sortable: true, });
            this.headers.push({ text: "Documento", value: "garDocumento", sortable: true, });
            this.headers.push({ text: "Whatsapp", value: "garWhatsapp", sortable: true, });
            this.headers.push({ text: "Telefone", value: "garTelefone", sortable: true, });
            this.headers.push({ text: "E-mail", value: "garEmail", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        //garantidora
        //endereco
        CarregaEndereco(_garId){
            axios.get(`/Process/GarGarantidoraEndereco/GetByGarId/` + _garId) 
                .then(response => {
                    if (response.data.length > 0){
                        this.Endereco = response.data[0];
                        this.showend = true;
                    }   
                    else{
                        this.Endereco.garId = _garId;
                    }                  
                });
        },
        Buscacep(value) {
            if (value == ""){
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else{
                fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                    if (this.Retornocep != null) {
                        this.Endereco.gaeEndereco = this.Retornocep.logradouro;
                        this.Endereco.gaeNumero = null;
                        this.Endereco.gaeComplemento = null;
                        this.Endereco.gaeEstado = this.Retornocep.uf;
                        this.Endereco.gaeCidade = this.Retornocep.localidade;
                        this.Endereco.gaeBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        this.$mensagemAviso("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }
        },
        EnderecoAdd(){
            if (this.Endereco.gaeCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.gaeEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.gaeNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.gaeEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.gaeCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.gaeBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                       this.Endereco.garId = this.Garantidora.garId;
                        axios.post(`/Process/GarGarantidoraEndereco/Add`, this.Endereco)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Endereco.gaeId = response.data;
                                    this.$mensagemAvisoSucesso("Endereço Incluído")
                                }
                            });
                }
        },
        EnderecoPut(){
            if (this.Endereco.gaeCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.gaeEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.gaeNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.gaeEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.gaeCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.gaeBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                    axios.put(`/Process/GarGarantidoraEndereco/Update`, this.Endereco)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Endereço Alterado.")
                        }
                    });
                }
        },
        EnderecoCancel(){
            /*this.Endereco.grtId = 0;
            this.Endereco.cdtId = 0;
            this.Endereco.gaeEndereco = '';
            this.Endereco.gaeNumero = null;
            this.Endereco.gaeComplemento = '';
            this.Endereco.gaeObsEndereco = '';
            this.Endereco.gaeCep = '';
            this.Endereco.gaePais = '';
            this.Endereco.gaeEstado = '';
            this.Endereco.gaeCidade = '';
            this.Endereco.gaeBairro = '';
            this.showend = false;*/
            this.$router.go(0);
        },
        //endereco
        //plano
        CarregaPlano(_garId) {
            axios.get(`/Process/GarGarantidoraPlanos/GetByGarId/` + _garId) 
                .then(response => {
                    if (response.data.length > 0) {
                        this.GarantidoraPlanos = response.data[0];
                        this.GarantidoraPlanos.gplValor = String(this.GarantidoraPlanos.gplValor).replace('.', ',');
                        this.GarantidoraPlanos.gplRemuneracaoNegocio = String(this.GarantidoraPlanos.gplRemuneracaoNegocio).replace('.', ',');
                        this.GarantidoraPlanos.gplValorAdesao = String(this.GarantidoraPlanos.gplValorAdesao).replace('.', ',');
                        
                        if (this.GarantidoraPlanos.gplVigenciaInicial) {
                            this.GarantidoraPlanos.gplVigenciaInicial = moment(this.GarantidoraPlanos.gplVigenciaInicial).format('YYYY-MM-DD');
                        }

                    } else {
                        this.GarantidoraPlanos.garId = _garId;
                    }
                });
        },
        PlanoAdd(){
            if (this.GarantidoraPlanos.cpgId === '' || this.GarantidoraPlanos.cpgId === null) {
                    this.$mensagemAviso("Campo Plano Obrigatório.")
                }
                else if (this.GarantidoraPlanos.gplVencimento === '') {
                    this.$mensagemAviso("Campo Vencimento Obrigatório.")
                }
                else if (this.GarantidoraPlanos.gplVigenciaInicial === '') {
                    this.$mensagemAviso("Campo Vigência Obrigatório.")
                }
                else {
                    if (this.GarantidoraPlanos.gplValor !== '' && this.GarantidoraPlanos.gplValor !== null) {
                        this.GarantidoraPlanos.gplValor = parseFloat(
                            String(this.GarantidoraPlanos.gplValor).replace(',', '.')
                        );
                    } else {
                        this.GarantidoraPlanos.gplValor = 0;
                    }

                    if (this.GarantidoraPlanos.gplRemuneracaoNegocio !== '' && this.GarantidoraPlanos.gplRemuneracaoNegocio !== null) {
                        this.GarantidoraPlanos.gplRemuneracaoNegocio = parseFloat(
                            String(this.GarantidoraPlanos.gplRemuneracaoNegocio).replace(',', '.')
                        );
                    } else {
                        this.GarantidoraPlanos.gplRemuneracaoNegocio = 0;
                    }

                    if (this.GarantidoraPlanos.gplValorAdesao !== '' && this.GarantidoraPlanos.gplValorAdesao !== null) {
                        this.GarantidoraPlanos.gplValorAdesao = parseFloat(
                            String(this.GarantidoraPlanos.gplValorAdesao).replace(',', '.')
                        );
                    } else {
                        this.GarantidoraPlanos.gplValorAdesao = 0;
                    }

                        axios.post(`/Process/GarGarantidoraPlanos/Add`, this.GarantidoraPlanos)
                             .then(response => {
                                if (response.data > 0) {
                                    this.GarantidoraPlanos.gplId = response.data;
                                    this.$mensagemAvisoSucesso("Plano Incluído")
                                    this.CarregaPlano(this.Garantidora.garId);
                                }
                            });
                }
        },
        PlanoPut(){
            if (this.GarantidoraPlanos.cpgId === '' || this.GarantidoraPlanos.cpgId === null) {
                    this.$mensagemAviso("Campo Plano Obrigatório.")
                }
                else if (this.GarantidoraPlanos.gplVencimento === '') {
                    this.$mensagemAviso("Campo Vencimento Obrigatório.")
                }
                else if (this.GarantidoraPlanos.gplVigenciaInicial === '') {
                    this.$mensagemAviso("Campo Vigência Obrigatório.")
                }
                else {
                    if (this.GarantidoraPlanos.gplValor !== '' && this.GarantidoraPlanos.gplValor !== null) {
                        this.GarantidoraPlanos.gplValor = parseFloat(
                            String(this.GarantidoraPlanos.gplValor).replace(',', '.')
                        );
                    } else {
                        this.GarantidoraPlanos.gplValor = 0;
                    }

                    if (this.GarantidoraPlanos.gplRemuneracaoNegocio !== '' && this.GarantidoraPlanos.gplRemuneracaoNegocio !== null) {
                        this.GarantidoraPlanos.gplRemuneracaoNegocio = parseFloat(
                            String(this.GarantidoraPlanos.gplRemuneracaoNegocio).replace(',', '.')
                        );
                    } else {
                        this.GarantidoraPlanos.gplRemuneracaoNegocio = 0;
                    }

                    if (this.GarantidoraPlanos.gplValorAdesao !== '' && this.GarantidoraPlanos.gplValorAdesao !== null) {
                        this.GarantidoraPlanos.gplValorAdesao = parseFloat(
                            String(this.GarantidoraPlanos.gplValorAdesao).replace(',', '.')
                        );
                    } else {
                        this.GarantidoraPlanos.gplValorAdesao = 0;
                    }

                    axios.put(`/Process/GarGarantidoraPlanos/Update`, this.GarantidoraPlanos)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Plano Alterado.")
                            this.CarregaPlano(this.Garantidora.garId);
                        }
                    });
                }
        },
        PlanoCancel(){
           /* this.GarantidoraPlanos.grtId = 0;
            this.GarantidoraPlanos.cdtId = 0;
            this.GarantidoraPlanos.cpgId = null;
            this.GarantidoraPlanos.gplValor = null;
            this.GarantidoraPlanos.gplVencimento = '';
            this.GarantidoraPlanos.gplVigenciaInicial = null;
            this.GarantidoraPlanos.gplTipoCobranca = '';
            this.GarantidoraPlanos.gplRemuneracaoNegocio = null;
            this.GarantidoraPlanos.gplQtdeUsuarios = '';
            this.GarantidoraPlanos.gplValorAdesao = null;*/
            this.$router.go(0);
        },
        //plano
        //serasa
        CarregaSerasa(_garId){
            axios.get(`/Process/GarGarantidoraSerasa/GetByGarId/` + _garId) 
                .then(response => {
                    if (response.data.length > 0){
                      this.Serasa = response.data[0];  
                      this.Serasa.gseCustoPesExtra = String(this.Serasa.gseCustoPesExtra).replace('.', ',');
                    }  
                    else{
                        this.Serasa.garId = _garId;
                    }                     
                });
        },
        SerasaAdd(){
            if (this.Serasa.gseQtdeMensal === '') {
                    this.$mensagemAviso("Campo Qtde Mensal Obrigatório.")
                }
                else if (this.Serasa.gseCustoPesExtra === '') {
                    this.$mensagemAviso("Campo Custo Extra Obrigatório.")
                }
                else if (this.Serasa.gseRangeAprovacao === '') {
                    this.$mensagemAviso("Campo Nivel de Aprovação Obrigatório.")
                }
                else {

                    if (this.Serasa.gseCustoPesExtra !== '' && this.Serasa.gseCustoPesExtra !== null) {
                        this.Serasa.gseCustoPesExtra = parseFloat(
                            String(this.Serasa.gseCustoPesExtra).replace(',', '.')
                        );
                    } else {
                        this.Serasa.gseCustoPesExtra = 0;
                    }

                    if (this.Serasa.gseRangeAprovacao !== '' && this.Serasa.gseRangeAprovacao !== null) {
                        this.Serasa.gseRangeAprovacao = parseInt(this.Serasa.gseRangeAprovacao);
                    } else {
                        this.Serasa.gseRangeAprovacao = 0;
                    }

                        axios.post(`/Process/GarGarantidoraSerasa/Add`, this.Serasa)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Serasa.gseId = response.data;
                                    this.$mensagemAvisoSucesso("Serasa Incluído")
                                    this.CarregaSerasa(this.Garantidora.garId);
                                }
                            });
                }
        },
        SerasaPut(){
            if (this.Serasa.gseQtdeMensal === '') {
                    this.$mensagemAviso("Campo Qtde Mensal Obrigatório.")
                }
                else if (this.Serasa.gseCustoPesExtra === '') {
                    this.$mensagemAviso("Campo Custo Extra Obrigatório.")
                }
                else if (this.Serasa.gseRangeAprovacao === '') {
                    this.$mensagemAviso("Campo Nivel de Aprovação Obrigatório.")
                }
                else {

                    if (this.Serasa.gseCustoPesExtra !== '' && this.Serasa.gseCustoPesExtra !== null) {
                        this.Serasa.gseCustoPesExtra = parseFloat(
                            String(this.Serasa.gseCustoPesExtra).replace(',', '.')
                        );
                    } else {
                        this.Serasa.gseCustoPesExtra = 0;
                    }
                    console.log('this.Serasa')
                    console.log(this.Serasa)

                    if (this.Serasa.gseRangeAprovacao !== '' && this.Serasa.gseRangeAprovacao !== null) {
                        this.Serasa.gseRangeAprovacao = parseInt(this.Serasa.gseRangeAprovacao);
                    } else {
                        this.Serasa.gseRangeAprovacao = 0;
                    }

                  

                    axios.put(`/Process/GarGarantidoraSerasa/Update`, this.Serasa)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Serasa Alterado.")
                            this.CarregaSerasa(this.Garantidora.garId);
                        }
                    });
                }
        },
        SerasaCancel(){
            /*this.Serasa.grtId =  0;
            this.Serasa.cdtId = 0;
            this.Serasa.gsePropriedade = '';
            this.Serasa.gseQtdeMensal = '';
            this.Serasa.gseCustoExstra = null; 
            this.Serasa.gseQtdeGratisMensal = '';
            this.Serasa.gseRangeAprovacao = '';*/
            this.$router.go(0);
        },
        //serasa
        //assinaturas
        CarregaAssinatura(_garId){
            axios.get(`/Process/GarGarantidoraAssinatura/GetByGarId/` + _garId) 
                .then(response => {
                    if (response.data.length > 0){
                      this.Assinatura = response.data[0];    
                      this.Assinatura.gasCustoPesExtra = String(this.Assinatura.gasCustoPesExtra).replace('.', ',');
                    }  
                    else{
                        this.Assinatura.garId = _garId;
                    }                   
                });
        },
        AssinaturaAdd() {
            if (this.Assinatura.gasPropriedade === '') {
                this.$mensagemAviso("Campo CPF Obrigatório.")
            }
            else if (this.Assinatura.gasQtdeMensal === '') {
                this.$mensagemAviso("Campo Qtde Mensal Obrigatório.")
            }
            else if (this.Assinatura.gasCustoPesExtra === '') {
                this.$mensagemAviso("Campo Custo Extra Obrigatório.")
            }
            else {
                const propriedade = String(this.Assinatura.gasPropriedade);
                const _senha = this.generatePassword();
                let _ConfAss = {
                    Nome: this.Garantidora.garNomeEmpresa,
                    Iniciais: this.initials(this.Garantidora.garNomeEmpresa),
                    Email: this.Garantidora.garEmail,
                    Senha: _senha,
                    Cpf: String(this.Assinatura.gasPropriedade),
                    ConfirmacaoSenha: _senha,
                    AcessoAPI: 'S',
                    Cargo: 'Empresa',
                    Empresa: this.Garantidora.garRazaoSocial,
                    QtdeEnvelopes: this.Assinatura.gasQtdeMensal
                }

               axios.post(`/Process/AssinaturasAsten/CreateUserAss`, _ConfAss)
                        .then(response => {
                            console.log("Status:", response.status); // Verifica o status
                            console.log("Data:", response.data);     // Verifica o valor de data
                            if (response.status === 200) {
                                // Se a resposta da API é OK, segue com a inserção
                                this.Assinatura.gasToken = response.data.response.data.tokenAPI;
                                this.Assinatura.gasIdUserAsten = response.data.response.data.idUsuario;

                                let _createRepo = {
                                    token: response.data.response.data.tokenAPI,
                                    params: {
                                        Repositorio: {
                                            Usuario: {id: response.data.response.data.idUsuario},
                                            nome: "Documentos",
                                            compartilharCriacaoDocs: 'S',
                                            compartilharVisualizacaoDocs: 'S',
                                            ocultarEmailSignatarios: 'N',
                                            nomeRemetente: null,
                                            opcaoValidCodigo: 'S',
                                            opcaoValidCertICP: 'S',
                                            opcaoValidDocFoto: 'S',
                                            opcaoValidDocSelfie: 'S',
                                            opcaoValidTokenSMS: 'S',
                                            opcaoValidLogin: 'S',
                                            opcaoValidReconhecFacial: 'S',
                                            opcaoValidPix: 'S',
                                            lembrarAssinPendentes: 'S'
                                        }
                                    }
                                }
                                console.log("_createRepo:"); // Verifica o status
                                console.log(_createRepo);   
                                axios.post(`/Process/AssinaturasAsten/CreateRepository`, _createRepo)
                                    .then(response => {
                                        if (response.status == 200) {
                                            this.Assinatura.gasIdRepositorio = response.data.response.data.idRepositorio;
                                            this.Assinatura.gasPropriedade = String(this.Assinatura.gasPropriedade);

                                            if (this.Assinatura.gasCustoPesExtra !== '' && this.Assinatura.gasCustoPesExtra !== null) {
                                                this.Assinatura.gasCustoPesExtra = parseFloat(
                                                    String(this.Assinatura.gasCustoPesExtra).replace(',', '.')
                                                );
                                            } else {
                                                this.Assinatura.gasCustoPesExtra = 0;
                                            }
                                           /* let _updateUser = {
                                                    Token: '',
                                                    Params: {
                                                        emailUsuario: this.Garantidora.garEmail,
                                                        saldoAtual: parseInt(this.Assinatura.gasQtdeMensal)
                                                     }
                                             }
                                             console.log("_updateUser:"); // Verifica o status
                                             console.log(_updateUser); */
                                           // axios.post(`/Process/AssinaturasAsten/UpdateSaldoUsuario`, _updateUser)
                                           //     .then(response => {
                                           //         if (response.status == 200) {
                                                        axios.post(`/Process/GarGarantidoraAssinatura/Add`, this.Assinatura)
                                                            .then(response => {
                                                                if (response.data > 0) {
                                                                    this.Assinatura.gasId = response.data;
                                                                    this.EnviaEmailCadastroUsuarioAsten(_senha);
                                                                    console.log("this.Assinatura:"); // Verifica o status
                                                                    console.log(this.Assinatura); 
                                                                    this.$mensagemAvisoSucesso("Assinatura Incluído, usuario notificado");
                                                                }
                                                            })
                                                            .catch(error => {
                                                                this.$mensagemErro("Erro ao incluir Assinatura na Garantidora: " + error.message);
                                                            });
                                            //        }
                                            //    });                                   
                                        }
                                    });
                            } else {
                                // Se o status não for OK ou o response.data <= 0, exibe mensagem de erro
                                this.$mensagemErro("Erro ao criar usuário: resposta não foi bem-sucedida.");
                            }
                        })
                        .catch(error => {
                            // Trata erros de rede ou outras falhas no primeiro axios.post
                            this.$mensagemErro("Erro na criação do usuário: " + error.response.data.error);
                        });

            }
        },
        AssinaturaPut(){
            if (this.Assinatura.gasPropriedade === '') {
                    this.$mensagemAviso("Campo CPF Obrigatório.")
                }
                else if (this.Assinatura.gasQtdeMensal === '') {
                    this.$mensagemAviso("Campo Qtde Mensal Obrigatório.")
                }
                else if (this.Assinatura.gasCustoExstra === '') {
                    this.$mensagemAviso("Campo Custo Extra Obrigatório.")
                }
                else {

                    if (this.Assinatura.gasCustoPesExtra !== '' && this.Assinatura.gasCustoPesExtra !== null) {
                        this.Assinatura.gasCustoPesExtra = parseFloat(
                            String(this.Assinatura.gasCustoPesExtra).replace(',', '.')
                        );
                    } else {
                        this.Assinatura.gasCustoPesExtra = 0;
                    }

                   /* let _updateUser = {
                            Token: this.Assinatura.gasToken,
                            Params: {
                                emailUsuario: this.Garantidora.garEmail,
                                saldoAtual: parent(this.Assinatura.gasQtdeMensal)
                                }
                        }*/

                   // axios.put(`/Process/AssinaturasAsten/UpdateSaldoUsuario`, _updateUser)
                   //     .then(response => {
                   //         if (response.status == 200) {
                                axios.put(`/Process/GarGarantidoraAssinatura/Update`, this.Assinatura)
                                    .then(response => {
                                        if (response.status == 200) {
                                            this.$mensagemAvisoSucesso("Assinatura Alterado.")
                                            this.CarregaAssinatura(this.Garantidora.garId);
                                        }
                                    });
                   //         }
                   //       });                         
                }
        },
        AssinaturaCancel(){
            /*this.Assinatura.grtId =  0;
            this.Assinatura.cdtId = 0;
            this.Assinatura.gasPropriedade = '';
            this.Assinatura.gasQtdeMensal = '';
            this.Assinatura.gasCustoExstra = null; 
            this.Assinatura.gasQtdeGratisMensal = '';
            this.Assinatura.gasIdRepositorio = '';
            this.Assinatura.gasToken = '';*/
            this.$router.go(0);
        },
        initials(value) {
            if (!value) return '';
            const words = value.split(' ');
            return words.map(word => word[0].toUpperCase()).join('').slice(0, 2);
        },
        generatePassword() {
            const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const lowercase = 'abcdefghijklmnopqrstuvwxyz';
            const numbers = '0123456789';
            const special = '!';

            // Garante que a senha terá pelo menos um caractere de cada categoria
            let password = [
                uppercase[Math.floor(Math.random() * uppercase.length)],
                lowercase[Math.floor(Math.random() * lowercase.length)],
                numbers[Math.floor(Math.random() * numbers.length)],
                special
            ];

            // Preenche o restante da senha com caracteres aleatórios
            const allCharacters = uppercase + lowercase + numbers + special;
            for (let i = password.length; i < 8; i++) {
                password.push(allCharacters[Math.floor(Math.random() * allCharacters.length)]);
            }

            // Embaralha os caracteres para garantir uma ordem aleatória
            return password.sort(() => Math.random() - 0.5).join('');
        },
        EnviaEmailCadastroUsuarioAsten(senha){
              axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 8);
                            let _dadosEmail = {
                                            nomeDe: this.$GarantePayNome,
                                            nomePara: this.Garantidora.garNomeEmpresa,
                                            assunto: 'Cadastro Usuário Assinaturas - Sistema GarantePay',
                                            destinatario: this.Garantidora.garEmail,
                                            emailResposta: this.$GarantePayEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomeUsuario]',this.Garantidora.garNomeEmpresa)
                                                        .replace('[LinkAcesso]', this.$GarantePayLinkAcessoAsten)
                                                        .replace('[UsuarioLogin]', this.Garantidora.garEmail)
                                                        .replace('[SenhaAcesso]', senha)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        //assinaturas
        //pagamentos
        CarregaPagamentos(_garId){
            axios.get(`/Process/GarGarantidoraPagamento/GetByGarId/` + _garId) 
                .then(response => {
                    if (response.data.length > 0){
                      this.Pagamento = response.data[0];
                    }   
                    else{
                        this.Pagamento.garId = _garId;
                    }                    
                });
        },
        PagamentoAdd(){
               if (this.Pagamento.gpgEmailAviso === '') {
                    this.$mensagemAviso("Campo E-mail Aviso Obrigatório.")
                }
                else {
                        axios.post(`/Process/GarGarantidoraPagamento/Add`, this.Pagamento)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Pagamento.gpgId = response.data;
                                    this.$mensagemAvisoSucesso("Pagamento Incluído")
                                }
                            });
                }
        },
        PagamentoPut(){
                if (this.Pagamento.gpgEmailAviso === '') {
                    this.$mensagemAviso("Campo E-mail Aviso Obrigatório.")
                }
                else {
                    axios.put(`/Process/GarGarantidoraPagamento/Update`, this.Pagamento)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Pagamento Alterado.")
                        }
                    });
                }
        },
        PagamentoCancel(){
            /*this.Pagamento.grtId = 0;
            this.Pagamento.cdtId = 0;
            this.Pagamento.topId = null;
            this.Pagamento.gpgEmailAviso = '';
            this.Pagamento.gpgSplitToken = '';
            this.Pagamento.gpgSplitBanco = '';
            this.Pagamento.gpgSplitBancoNome = '';
            this.Pagamento.gpgSplitAgencia = '';
            this.Pagamento.gpgSplitConta = '';
            this.Pagamento.gpgSplitTitularConta = '';
            this.Pagamento.gpgSplitTitularDocumento = '';
            this.Pagamento.gpgSplitChavePix = '';
            this.Pagamento.gpgSplitTipoConta = '';*/
            this.$router.go(0);
        },
        //pagamentos
        //contratos
        CarregaContratos(_garId){
            axios.get(`/Process/GarGarantidoraContrato/GetByGarId/` + _garId) 
                .then(response => {
                    this.ListaMeusContratos = response.data;                        
                });
        },
        async ContratoAdd(){
               if (this.Contrato.cmcId === '') {
                    this.$mensagemAviso("Campo Modelo Contrato Obrigatório.")
                }
                else {
                     //chamar a montagem do pdf e gravar
                     //dadosContratante = garantidora e dadosContratada = Garantepay
                    var _dadosContrato = {
                            TipoPessoa: 1,
                            CmcId: this.Contrato.cmcId,
                            IdContratante: this.Garantidora.garId,
                            IdContratada: 0,
                            dadosContratacao: null
                        }

                        var _fileName = await this.Uploadpdf(_dadosContrato);


                        this.Contrato.garId = this.Garantidora.garId;
                        this.Contrato.gctUrlContrato = _fileName;
                        axios.post(`/Process/GarGarantidoraContrato/Add`, this.Contrato)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Contrato.gctId = response.data;
                                    this.CarregaContratos(this.Contrato.garId);
                                    this.$mensagemAvisoSucesso("Contrato Incluído")
                                }
                            });
                }
        },
        ContratosPut(){
                 if (this.Contrato.cmcId === '') {
                    this.$mensagemAviso("Campo Modelo Contrato Obrigatório.")
                }
                else {
                    axios.put(`/Process/GarGarantidoraContrato/Update`, this.Contrato)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Contrato Alterado.")
                        }
                    });
                }
        },
        ContratosCancel(){
            /*this.Contrato.grtId = 0;
            this.Contrato.cdtId = 0;
            this.Contrato.cmcId = null;
            this.Contrato.gctUrlContrato = '';
            this.Contrato.gctIp = '';
            this.Contrato.gctDataAss = null;
            this.Contrato.gctRetornoJson = '';*/
            this.$router.go(0);
        },
        VisualizarContrato(_contrato){
            if (_contrato && _contrato.gctUrlContrato) {
            // Abre o PDF em uma nova aba
            window.open(this.$imgURL + _contrato.gctUrlContrato, '_blank');
        } else {
            console.error("URL do contrato não está disponível.");
        }
        },
        async Uploadpdf(dadosContrato) {
            const uploadResult = await axios.post(`/Process/UploadImg/uploadContratoPdf`, dadosContrato);
            return uploadResult.data.nameFile;
        },
        async EnviarAssinatura(item){
          let idContrato = item.gctId;
          let tpeId = 1;
          let DadosEnvelopeRequest = {
            token: '',
            envelopeDescricao: this.ListaContratos.filter(x=> x.cmcId == item.cmcId)[0].cmcNome,
            repositorioId: 18,
            listaDocumentos: [{ UrlArquivo: item.gctUrlContrato}],
            listaSignatariosEnvelopes: [{
                Ordem: 1,
                EmailSignatario: this.Garantidora.garEmail,
                NomeSignatario: this.Garantidora.garNomeEmpresa,
                TipoAssinatura: 1
            },
            {
                Ordem: 2,
                EmailSignatario: this.$GarantePayEmail,
                NomeSignatario: this.$GarantePayNome,
                TipoAssinatura: 1
            }]
          }      
          axios.post(`/Process/AssinaturasAsten/CreateEnvelope/` + idContrato + '/' + tpeId, DadosEnvelopeRequest)
            .then(response => {
                if (response.status == 200) {
                    this.CarregaContratos(this.Garantidora.garId);
                    this.$mensagemAvisoSucesso("Assinatura enviada aos signatários");
                }
            })
            .catch(error => {
                if (error.response) {
                // Erros retornados pelo servidor
                this.$mensagemErro(
                    `Erro ao enviar assinatura (Status ${error.response.status}): ${error.response.data}`
                );
                } else if (error.request) {
                // O servidor não respondeu
                this.$mensagemErro("Erro: Nenhuma resposta do servidor.", error.request);
                } else {
                // Erro ao configurar a requisição
                this.$mensagemErro("Erro ao configurar requisição:", error.message);
                }
            });
        },
        //contratos
        Get() {
            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }
            console.log('this.User2')
            console.log(this.User)

            if (this.User.garid == 0){
                this.EditView = false;
                axios.get(`/Process/GarGarantidoras/GetAll`)
                .then(response => {
                    this.ListGarantidorasGeral = response.data.filter(x=> x.garAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else{
                var _item = {garId : this.User.garid}
                this.EditGrid(_item);
            }        

            axios.get(`/Process/CadTipoDocumento/GetAll`)
                .then(response => {
                    this.TiposDocumentos = response.data.filter(x=> x.tpdAtivo);
                });

            axios.get(`/Process/CadPlanosGarantepay/GetAll`)
                .then(response => {
                    this.ListaPlanos = response.data;
                });

            axios.get(`/Process/CadTipoOperadora/GetAll`)
                .then(response => {
                    this.TipoOperadoras = response.data.filter(x=> x.topAtivo);
                });

                axios.get(`/Process/CadModelosContrato/GetAll`)
                .then(response => {
                    this.ListaContratos = response.data.filter(x=> x.tpeId == 1 && x.cmcAtivo);
                });
              
        }
    },
    created() {
        this.Get();
         
    },
    computed: {
        documentoMask() {
        return this.Garantidora.tpdId === 1 ? '###.###.###-##' : '##.###.###/####-##';
        }
   }
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
