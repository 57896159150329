<template>
    <div>
        <!--Edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Produtos</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de produtos</a>
                        </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#dadcdf">
                                    <v-tab value="dados" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Dados do Produto</v-tab>
                                    <div v-if="Produtos.proId != 0">
                                        <v-tab value="Imagem" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Imagem</v-tab>
                                    </div>
                                    <div v-if="Produtos.proId != 0">
                                        <v-tab value="segmentos" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Segmentos</v-tab>
                                    </div>
                                    <div v-if="Produtos.proId != 0">
                                        <v-tab value="Itens" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Itens produto</v-tab>
                                    </div>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item value="dados">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Produtos.proId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Referência</label>
                                                            <input v-model=Produtos.proReferencia type="text"
                                                                class="form-control" placeholder="Referência" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10">
                                                        <div class="form-group">
                                                            <label>Nome do Produto</label>
                                                            <input v-model="Produtos.proNomeProduto" type="text"
                                                                class="form-control" placeholder="Nome do Produto" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Visível</label>
                                                            <select v-model="Produtos.proVisivel"
                                                                class="form-select form-control" id="defaultSelect">
                                                                <option value="">Selecione</option>
                                                                <option :value=true>Sim</option>
                                                                <option :value=false>Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Ordem</label>
                                                            <input v-model="Produtos.proOrdem" type="number"
                                                                class="form-control" placeholder="Ordem" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Possui itens</label>
                                                            <select v-model="Produtos.proCampoExtra1"
                                                                class="form-select form-control" id="defaultSelect">
                                                                <option value="">Selecione</option>
                                                                <option value="1">Sim</option>
                                                                <option value="0">Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Vigência</label>
                                                            <select v-model="Produtos.proCampoExtra2"
                                                                class="form-select form-control" id="defaultSelect">
                                                                <option value="">Selecione</option>
                                                                <option value="Anual">Anual</option>
                                                                <option value="Recorrente">Recorrente</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label for="comment">Descrição Curta</label>
                                                            <ckeditor :editor="editor" @ready="onReady"
                                                                v-model="Produtos.proTexto1" :config="$ckconfig">
                                                            </ckeditor>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label for="comment">Descrição Completa</label>
                                                            <ckeditor :editor="editor" @ready="onReady"
                                                                v-model="Produtos.proTexto2" :config="$ckconfig">
                                                            </ckeditor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--Imagens-->
                                    <v-window-item value="Imagem">
                                        <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload
                                            de até 1 imagem com dimensão: Largura: 300px - Altura: 300px</div>
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="3">
                                                        <v-file-input v-model="selectedFile" multiple label="Imagem"
                                                            variant="underlined" prepend-icon="mdi-image-outline"
                                                            accept="image/jpeg, image/png, image/jpg, image/gif"></v-file-input>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <a href="#" class="btn btn-sm btn-success" style="color:#ffffff;margin-top:5px;" @click.prevent="AddImagens()">Carregar Imagens</a>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-form>
                                                <v-container>
                                                    <v-row
                                                        v-for="imgs in ProdutosImgs" :key="imgs.pimId">
                                                        <v-col cols="12" md="4" v-show="false">
                                                            <v-text-field v-model="imgs.pimId" label="Id"
                                                                variant="underlined"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="2">
                                                            <v-col cols="12" md="12">
                                                                <v-img :src="$imgURL + imgs.pimUrlImagem"
                                                                    variant="underlined"></v-img>
                                                            </v-col>
                                                        </v-col>
                                                        <v-col cols="12" md="8">
                                                            <v-row>
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field v-model="imgs.pimNome" label="Nome"
                                                                        variant="underlined"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field v-model="imgs.pimOrdem" label="Ordem"
                                                                        type="number"
                                                                        variant="underlined"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4">
                                                                    <v-select v-model="imgs.pimVisivel" label="Visível"
                                                                        :items="StatusProdAvImgVisivel"
                                                                        item-title="nome" item-value="pimVisivel"
                                                                        variant="underlined">
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field v-model="imgs.pimAlt" label="Alt"
                                                                        variant="underlined"
                                                                        style="display:none"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="12" md="2">
                                                            <v-btn icon @click="SaveImages(imgs)"
                                                                style="margin-right: 10px;" variant="text">
                                                                <v-icon>mdi-content-save-outline</v-icon>
                                                                <v-tooltip activator="parent"
                                                                    location="top">Salvar</v-tooltip>
                                                            </v-btn>

                                                            <v-btn icon @click.prevent="DeleteImages(imgs)"
                                                                variant="text">
                                                                <v-icon>mdi-delete-forever-outline</v-icon>
                                                                <v-tooltip activator="parent"
                                                                    location="top">Excluir</v-tooltip>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-form>

                                        </v-form>
                                    </v-window-item>
                                    <!--Segmentos-->
                                    <v-window-item value="segmentos">
                                        <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Seleção
                                            de Segmentos</div>
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="8">
                                                        <select v-model="ProdutosSegs.psgId" class="form-select form-control" id="defaultSelect">
                                                            <option :value="null">Selecione</option>
                                                            <option v-for="optionTipo in ListaHierarquiaSeg" :key="optionTipo.psgId"
                                                                :value="optionTipo.psgId">{{ optionTipo.psgNomeSegmento }}</option>
                                                        </select>
                                                    </v-col>
                                                    <v-col cols="12" md="2">
                                                        <button type="button" class="btn btn-sm btn-success" style="color:#ffffff;margin-top:5px;" @click="AddSegmentos(Produtos.proId)">
                                                            Incluir Segmento
                                                        </button>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                        <v-form>
                                            <v-container>
                                                <v-row>
                                                    <v-table>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">
                                                                    Hierarquia de Segmentos
                                                                </th>
                                                                <th class="text-center">
                                                                    Actions
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in ListaSegmentos" :key="item.pssId">
                                                                <td>{{ item.Hierarquia }}</td>
                                                                <td style="text-align:center;">
                                                                    <v-btn icon @click.prevent="DeleteSegmentos(item)"
                                                                        variant="text">
                                                                        <v-icon>mdi-delete-forever-outline</v-icon>
                                                                        <v-tooltip activator="parent"
                                                                            location="top">Excluir</v-tooltip>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </v-table>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--Grid Itens-->
                                    <v-window-item value="Itens">
                                        <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;">Lista Itens</div>
                                        <v-form>
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-2 col-lg-2" v-show="false">
                                                        <div class="form-group">
                                                            <label>Identificador</label>
                                                            <input  v-model="ProdutosGrids.pisId" type="text" class="form-control"
                                                                placeholder="Identificador" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Item</label>
                                                            <input v-model="ProdutosGrids.pisNome" type="text" class="form-control" placeholder="Item" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Ordem</label>
                                                            <input v-model="ProdutosGrids.pisOrdem" type="number" class="form-control" placeholder="Ordem" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Visível</label>
                                                            <select v-model="ProdutosGrids.pisVisivel" class="form-select form-control">
                                                                <option value="">Selecione</option>
                                                                <option :value=true>Sim</option>
                                                                <option :value=false>Não</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label></label>
                                                            <div>
                                                                <button type="button" class="btn btn-sm btn-success" style="color:#ffffff;margin-top:12px;" @click="AddGrids()">Adicionar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-form>
                                                <v-container>
                                                    <v-row>
                                                        <v-table>
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-left">
                                                                        Título
                                                                    </th>
                                                                    <th class="text-left">
                                                                        Ordem
                                                                    </th>
                                                                    <th class="text-left">
                                                                        Visível
                                                                    </th>
                                                                    <th class="text-center">
                                                                        Actions
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="item in GridsItens"
                                                                    :key="item.pisId">
                                                                    <td>{{ item.pisNome }}</td>
                                                                    <td>{{ item.pisOrdem }}</td>
                                                                    <td>{{ item.pisVisivel?'Sim':'Não' }}</td>
                                                                    <td style="text-align:center;">
                                                                        <v-btn icon @click.prevent="DeleteGrids(item)"
                                                                            variant="text">
                                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                                            <v-tooltip activator="parent"
                                                                                location="top">Excluir</v-tooltip>
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </v-table>
                                                    </v-row>
                                                </v-container>
                                            </v-form>
                                        </v-form>
                                    </v-window-item>
                                </v-window>
                            </div>
                        </div>
                    </div>
                    <div class="card-action" v-if="tab=='dados'">
                        <button v-if="Produtos.proId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                            @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de produtos</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="preprocessData(ListProdutos)"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-proVisivel="item">
                                        <span>{{ item.proVisivel ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-proCampoExtra1="item">
                                        <span>{{ item.proCampoExtra1 == '1' ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>

                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'ProProdutosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        EditView: false,
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Produtos',
        InfoTela:'Lista de produtos',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        CadastroTelas:[{ cdtId: 14, cdtNome: "Produtos" }],
        //dados
        Produtos: {
            proId: 0,
            grtId: 0,
            cdtId: 0,
            proNomeProduto: '',
            proReferencia: '',
            proTempoProducao: '',
            proValorDe: '',
            proValorPor: '',
            proVisivel: '',
            proOrdem: '',
            proDestaque: '',
            proTexto1: '',
            proEtiqueta: '',
            proEtiquetaCorFonte: '',
            proEtiquetaCorFundo: '',
            proPeso: '',
            proAltura: '',
            proLargura: '',
            proProfundidade: '',
            proCampoExtra1: '',
            proCampoExtra2: '',
            proCampoExtra3: '',
            proCampoExtra4: '',
        },
        ListProdutos: [],
        //dados
        //imagens
        ProdutosImgs:[],
        ListaProdAvImgs:[],
        ImagemProdutos:{},
        selectedFile: null,
        StatusProdAvImgVisivel: [{ nome: "Sim", pimVisivel: true }, { nome: "Não", pimVisivel: false }],
        //imagens
        //Segmentos
        ProdutosSegs:{
            pssId:0,
            proId:0,
            psgId:'',
            pssOrdem:0,
            Hierarquia:'',
        },
        ListaHierarquiaSeg:[],
        ListaSegmentos:[],
        ListSegmentosProdGeral:[],
        MostraSegmento:false,
        //Segmentos
           //Grids
           ProdutosGrids:{
            pisId:0,
            pisUrlImagem:'',
            pisOrdem:'',
            pisNome:'',
            pisResumo:'',
            pisCampoExtra1:'',
            pisCampoExtra2:'',
            pisCampoExtra3:'',
            pisCampoExtra4:'',
            pisVisivel:'',
            proId:''
        },
        GridsItens:[],
        ListaProdGrids:[],
        selectedFileGrid: null,
        //Grids
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                proOrdem: parseInt(item.proOrdem),
            };
            });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListProdutos = this.ListProdutosGeral;
        },
        //imagens
        async AddImagens(){           
            if (this.selectedFile != '' && this.selectedFile != null) {
                if ((this.selectedFile.length + this.ProdutosImgs.length) <= 1){
                    let selected2Promises = this.selectedFile.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ImagemProdutos.pimUrlImagem = uploadSuccess.data.nameFile;
                                this.ImagemProdutos.pimVisivel = true;
                                this.ImagemProdutos.proId = this.Produtos.proId
                                this.ImagemProdutos.pimResumo = "";
                                return axios.post(`/Process/ProProdutosImagens/Add`, this.ImagemProdutos);
                            } else {
                                this.$mensagemErro("Não foi possível carregar a imagem.");
                                return null;
                            }
                    });

                    Promise.all(selected2Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaImagens();
                            this.selectedFile = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Alguma imagem pode ter dado erro na carga." + error);
                        });
                    }
                    else{
                        this.$mensagemAviso("Quantidade máxima de imagens excedida.")
                    }
                }
                else{
                    this.$mensagemAviso("Selecione uma imagem.")
                }
        },
        SaveImages(value){
            var _imginfo = value;
            if (_imginfo.pimOrdem === "" || _imginfo.pimOrdem === null) {
                _imginfo.pimOrdem = 0;
            }
            else {
                _imginfo.pimOrdem = parseInt(_imginfo.pimOrdem);
            }
            
            if (_imginfo.pimResumo === "" || _imginfo.pimResumo === null){
                _imginfo.pimResumo = "";
            }
            axios.put(`/Process/ProProdutosImagens/Update`, _imginfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaImagens();
                        }
                    });
        },
        DeleteImages(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.pimId;
                axios.delete(`/Process/ProProdutosImagens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaImagens();
                    }
                });
            }
            });
        },
        CarregaImagens(){
            axios.get(`/Process/ProProdutosImagens/GetAllByProId/${this.Produtos.proId}`)
                    .then(response => {
                        this.ProdutosImgs = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.pimOrdem) || 0;
                                                                                const valorB = parseInt(b.pimOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                        console.log('this.ProdutosImgs')
                        console.log(this.ProdutosImgs)
                        console.log('this.Produtos.proId')
                        console.log(this.Produtos.proId)

                    });
        },
        //imagens
        //produtos
         //edit
        EditNew(){
        this.EditView = true;
        this.Produtos.proId=0;
            this.Produtos.grtId=0;
            this.Produtos.cdtId=0;
            this.Produtos.proNomeProduto="";
            this.Produtos.proReferencia="";
            this.Produtos.proTempoProducao="";
            this.Produtos.proValorDe="";
            this.Produtos.proValorPor="";
            this.Produtos.proVisivel="";
            this.Produtos.proOrdem="";
            this.Produtos.proDestaque="";
            this.Produtos.proTexto1="";
            this.Produtos.proTexto2="";
            this.Produtos.proTexto3="";
            this.Produtos.proTexto4="";
            this.Produtos.proEtiqueta="";
            this.Produtos.proEtiquetaCorFonte="";
            this.Produtos.proEtiquetaCorFundo="";
            this.Produtos.proPeso="";
            this.Produtos.proAltura="";
            this.Produtos.proLargura="";
            this.Produtos.proProfundidade="";
            this.Produtos.proCampoExtra1="";
            this.Produtos.proCampoExtra2="";
            this.Produtos.proCampoExtra3="";
            this.Produtos.proCampoExtra4="";
            this.Produtos.proCampoExtra5="";
            this.Produtos.proCampoExtra6="";
            this.Produtos.proCampoExtra7="";
            this.Produtos.proCampoExtra8="";
            this.Produtos.proCampoExtra9="";
            this.Produtos.proCampoExtra10="";
            this.ListaProdAvImgs = [];
            this.MostraSegmento = false;
            this.MostraAvulsos = false;
            this.tab = 'dados';
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        EditGrid(item) {
            this.EditView = true;
            this.tab = 'dados';
            console.log('item')
            console.log(item)
            if (item.proId != '') {
                console.log('aqui')
                // Lógica para editar o item
                 axios.get(`/Process/ProProdutos/GetById/` + item.proId) //GetAllByCtiId/14
                    .then(response => {
                        this.Produtos = response.data;

                          //carrega lista de imagens
                        this.CarregaImagens();

                        this.CarregaListaSegmentos();

                        this.CarregaGrids();
                    });

              
            }
        },
        Add() {
               if (this.Produtos.proNomeProduto == '') {
                    this.$mensagemAviso("Campo Nome Produto Obrigatório.")
                }
                else if (this.Produtos.proVisivel === '') {
                    this.$mensagemAviso("Campo Visível Obrigatório.")
                }
                else {
                    if (this.Produtos.proVisivel === "" || this.Produtos.proVisivel === null) {
                        this.Produtos.proVisivel = false;
                    }
                    if (this.Produtos.proDestaque === "" || this.Produtos.proDestaque === null) {
                        this.Produtos.proDestaque = false;
                    }
                    if (this.Produtos.proOrdem === "" || this.Produtos.proOrdem === null) {
                        this.Produtos.proOrdem = 0;
                    }
                    else {
                        this.Produtos.proOrdem = parseInt(this.Produtos.proOrdem);
                    }
                    if (this.Produtos.proTempoProducao === "" || this.Produtos.proTempoProducao === null) {
                        this.Produtos.proTempoProducao = 0;
                    }
                    else {
                        this.Produtos.proTempoProducao = parseInt(this.Produtos.proTempoProducao);
                    }
                    if (this.Produtos.proPeso === "" || this.Produtos.proPeso === null) {
                        this.Produtos.proPeso = 0;
                    }
                    else {
                        this.Produtos.proPeso = parseFloat(this.Produtos.proPeso);
                    }
                    if (this.Produtos.proAltura === "" || this.Produtos.proAltura === null) {
                        this.Produtos.proAltura = 0;
                    }
                    else {
                        this.Produtos.proAltura = parseFloat(this.Produtos.proAltura);
                    }
                    if (this.Produtos.proLargura === "" || this.Produtos.proLargura === null) {
                        this.Produtos.proLargura = 0;
                    }
                    else {
                        this.Produtos.proLargura = parseFloat(this.Produtos.proLargura);
                    }
                    if (this.Produtos.proProfundidade === "" || this.Produtos.proProfundidade === null) {
                        this.Produtos.proProfundidade = 0;
                    }
                    else {
                        this.Produtos.proProfundidade = parseFloat(this.Produtos.proProfundidade);
                    }
                    if (this.Produtos.proValorDe === "" || this.Produtos.proValorDe === null) {
                        this.Produtos.proValorDe = 0;
                    }
                    else {
                        this.Produtos.proValorDe = parseFloat(this.Produtos.proValorDe);
                    }
                    if (this.Produtos.proValorPor === "" || this.Produtos.proValorPor === null) {
                        this.Produtos.proValorPor = 0;
                    }
                    else {
                        this.Produtos.proValorPor = parseFloat(this.Produtos.proValorPor);
                    }

                    this.Produtos.admGrupoTelas = { GrtId: this.Produtos.grtId }
                    this.Produtos.admCadastroTelas = { CdtId: this.Produtos.cdtId }
                 
                    axios.post(`/Process/ProProdutos/Add`, this.Produtos)
                        .then(response => {
                            if (response.data > 0) {
                                    this.Produtos.proId = response.data;
                                    this.$mensagemAvisoSucesso("Registro Incluído. abas para informações adicionais liberadas.")               
                            }
                        });
            }
        },
        Put() {
            if (this.Produtos.proNomeProduto == '') {
                this.$mensagemAviso("Campo Nome Produto Obrigatório.")
            }
            else if (this.Produtos.proVisivel === '') {
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else {
                if (this.Produtos.proVisivel === "" || this.Produtos.proVisivel === null) {
                    this.Produtos.proVisivel = false;
                }
                if (this.Produtos.proDestaque === "" || this.Produtos.proDestaque === null) {
                    this.Produtos.proDestaque = false;
                }
                if (this.Produtos.proOrdem === "" || this.Produtos.proOrdem === null) {
                    this.Produtos.proOrdem = 0;
                }
                else {
                    this.Produtos.proOrdem = parseInt(this.Produtos.proOrdem);
                }
                if (this.Produtos.proTempoProducao === "" || this.Produtos.proTempoProducao === null) {
                        this.Produtos.proTempoProducao = 0;
                    }
                    else {
                        this.Produtos.proTempoProducao = parseInt(this.Produtos.proTempoProducao);
                    }
                    if (this.Produtos.proPeso === "" || this.Produtos.proPeso === null) {
                        this.Produtos.proPeso = 0;
                    }
                    else {
                        this.Produtos.proPeso = parseFloat(this.Produtos.proPeso);
                    }
                    if (this.Produtos.proAltura === "" || this.Produtos.proAltura === null) {
                        this.Produtos.proAltura = 0;
                    }
                    else {
                        this.Produtos.proAltura = parseFloat(this.Produtos.proAltura);
                    }
                    if (this.Produtos.proLargura === "" || this.Produtos.proLargura === null) {
                        this.Produtos.proLargura = 0;
                    }
                    else {
                        this.Produtos.proLargura = parseFloat(this.Produtos.proLargura);
                    }
                    if (this.Produtos.proProfundidade === "" || this.Produtos.proProfundidade === null) {
                        this.Produtos.proProfundidade = 0;
                    }
                    else {
                        this.Produtos.proProfundidade = parseFloat(this.Produtos.proProfundidade);
                    }
                    if (this.Produtos.proValorDe === "" || this.Produtos.proValorDe === null) {
                        this.Produtos.proValorDe = 0;
                    }
                    else {
                        this.Produtos.proValorDe = parseFloat(this.Produtos.proValorDe);
                    }
                    if (this.Produtos.proValorPor === "" || this.Produtos.proValorPor === null) {
                        this.Produtos.proValorPor = 0;
                    }
                    else {
                        this.Produtos.proValorPor = parseFloat(this.Produtos.proValorPor);
                    }
                    this.Produtos.admGrupoTelas = { GrtId: this.Produtos.grtId }
                    this.Produtos.admCadastroTelas = { CdtId: this.Produtos.cdtId }
             
                axios.put(`/Process/ProProdutos/Update`, this.Produtos)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        //edit
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.proId;
                axios.delete(`/Process/ProProdutos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "proId", sortable: true, });
            this.headers.push({ text: "Nome Produto", value: "proNomeProduto", sortable: true, });
            this.headers.push({ text: "Referência", value: "proReferencia", sortable: true, });
            this.headers.push({ text: "Visível", value: "proVisivel", sortable: true, });
            this.headers.push({ text: "Personalizavel", value: "proCampoExtra1", sortable: true, });
            this.headers.push({ text: "Vigência", value: "proCampoExtra2", sortable: true, });
            this.headers.push({ text: "Ordem", value: "proOrdem", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        //produtos
        //segmentos
        AddSegmentos(value){
                if (this.ProdutosSegs.psgId == '' || this.ProdutosSegs.psgId == 0) {
                    this.$mensagemAviso("Campo Segmento Obrigatório.")
                }
                else{
                  var validexits = this.ListaSegmentos.filter(x=> x.proSegmentos.psgId == this.ProdutosSegs.psgId && x.proProdutos.proId == this.Produtos.proId);             
                  
                  if (validexits.length == 0){
 
                    if (this.ProdutosSegs.pssOrdem === "" || this.ProdutosSegs.pssOrdem === null) {
                        this.ProdutosSegs.pssOrdem = 0;
                    }
                    else {
                        this.ProdutosSegs.pssOrdem = parseInt(this.ProdutosSegs.pssOrdem);
                    }
                        this.ProdutosSegs.proProdutos = {ProId: value};
                        this.ProdutosSegs.ProSegmentos = {PsgId: this.ProdutosSegs.psgId};
                        axios.post(`/Process/ProProdutosSegmentos/Add`, this.ProdutosSegs)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Segmento Incluído.")
                                this.CarregaListaSegmentos();
                                this.ProdutosSegs.psgId = '';
                            }
                        });
                    }
                    else{
                        this.$mensagemAviso("Registro Existente.")
                    }
                }
        },
        CarregaSegmentos(){
            axios.get(`/Process/ProProdutosSegmentos/GetByProId/${this.Produtos.proId}`)
                    .then(response => {
                        this.ListaSegmentos = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.pssOrdem) || 0;
                                                                                const valorB = parseInt(b.pssOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                    this.ListaSegmentos =  this.itensComHierarquia(this.ListaSegmentos);  
                    });
        },
        CarregaListaSegmentos(){
            axios.get(`/Process/ProSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosProdGeral = response.data;
                                    this.ListaHierarquiaSeg = this.ListSegmentosProdGeral.filter(x=> x.psgVisivel);
        //------ carrega select psgIdPai com hierarquia
        function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
            const segmentosFilhos = segmentos.filter(segmento => segmento.psgIdPai === segmentoIdPai);
            const segmentosComCaminho = [];

            for (const segmentoFilho of segmentosFilhos) {
                const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.psgNomeSegmento : `${caminhoAtual} > ${segmentoFilho.psgNomeSegmento}`;

                segmentosComCaminho.push({ psgId: segmentoFilho.psgId, psgNomeSegmento: caminhoCompleto });
                
                const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.psgId, caminhoCompleto);
                segmentosComCaminho.push(...segmentosNetos);
            }

            return segmentosComCaminho;
            }
        
            const segmentosComCaminho = [];
            for (const segmento of this.ListaHierarquiaSeg.filter(x=> x.psgIdPai== 0)) {
            segmentosComCaminho.push({ psgId: segmento.psgId, psgNomeSegmento: segmento.psgNomeSegmento });
            
            const segmentosFilhos = buildHierarchy(this.ListaHierarquiaSeg, segmento.psgId, segmento.psgNomeSegmento);
            segmentosComCaminho.push(...segmentosFilhos);
            }
            this.ListaHierarquiaSeg = segmentosComCaminho;   
            this.CarregaSegmentos();
        //------
        });
        },
        itensComHierarquia(itens) {
        // Adicione a lógica para adicionar a coluna "Hierarquia" ao array de dados
        const itensComHierarquia = itens.map((item) => {
            const hierarquia = this.ListaHierarquiaSeg.filter(
            (x) => x.psgId === item.proSegmentos.psgId
            )[0]?.psgNomeSegmento || "";

            return { ...item, Hierarquia: hierarquia };
        });

          return itensComHierarquia;
        },
        DeleteSegmentos(item){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.pssId;
                axios.delete(`/Process/ProProdutosSegmentos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaListaSegmentos();
                    }
                });
            }
            });
        },
        //Segmentos
        //grids
        async AddGrids(){
                    this.ProdutosGrids.pisUrlImagem = "";
                    this.ProdutosGrids.proId = this.Produtos.proId
                    if (this.ProdutosGrids.pisOrdem === "" || this.ProdutosGrids.pisOrdem === null) {
                        this.ProdutosGrids.pisOrdem = 0;
                    }
                    else {
                        this.ProdutosGrids.pisOrdem = parseInt(this.ProdutosGrids.pisOrdem);
                    }
                    if (this.ProdutosGrids.pisVisivel === "" || this.ProdutosGrids.pisVisivel === null) {
                        this.ProdutosGrids.pisVisivel = true;
                    }
                    axios.post(`/Process/ProProdutosItens/Add`, this.ProdutosGrids)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaGrids();
                                this.LimpaGridInput();
                            }
                        });
                
        },
        DeleteGrids(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.pisId;
                axios.delete(`/Process/ProProdutosItens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaGrids();
                    }
                });
            }
            });
        },
        CarregaGrids(){
            axios.get(`/Process/ProProdutosItens/GetAllByProId/${this.Produtos.proId}`)
                    .then(response => {
                        this.GridsItens = response.data.sort((a, b) => {
                                                                        const valorA = parseInt(a.pgsOrdem) || 0;
                                                                        const valorB = parseInt(b.pgsOrdem) || 0;
                                                                        return valorA - valorB;
                                                                    });
                    });
        },
        LimpaGridInput(){
            this.ProdutosGrids.pisId=0;
            this.ProdutosGrids.pisNome= '';
            this.ProdutosGrids.pisResumo='';
            this.ProdutosGrids.pisCampoExtra1= '';
            this.ProdutosGrids.pisCampoExtra2= '';
            this.ProdutosGrids.pisCampoExtra3= '';
            this.ProdutosGrids.pisCampoExtra4= '';
            this.ProdutosGrids.pisOrdem= '';
            this.ProdutosGrids.pisVisivel= '';
            this.ProdutosGrids.pisUrlImagem= '';
            this.selectedFileGrid = null;
        },
        //grids
        Get() {
            this.EditView = false;
            axios.get(`/Process/ProProdutos/GetAll`) //GetAllByCtiId/14
                        .then(response => {
                            this.ListProdutosGeral = response.data; 
                            this.CarregaLista();                         
                        });
                    this.montaGrid();
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
