<template>
       <div>
        <div class="container" v-if="User!=''">
            <div class="page-inner">
                <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
              <div>
                <h3 class="fw-bold mb-3">Olá {{  User.nome }}</h3>
                <v-alert
                  v-if="showAlert"
                  density="compact"
                  :type="alertType"
                  @click="showAlert = false"
                >
                  <template v-slot:title>
                    {{ alertTitle }}
                  </template>
                  {{ alertText }}
                </v-alert>
              </div>
              <div class="ms-md-auto py-2 py-md-0" style="display: none;">
                <a href="#" class="btn btn-label-info btn-round me-2">Garantidora</a>
                <a href="#" class="btn btn-primary btn-round">Contratação</a>
              </div>
            </div>
            </div>
        </div>       
      </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {
  },
  data: () => ({
    accordion: false,
    User: [],
    ListaMeusContratos:[],
    libera:[],
    showAlert: false, // Controle de visibilidade do alerta
    alertTitle: "", // Título do alerta
    alertText: "", // Texto do alerta
    alertType: "", // Tipo do alerta (success, warning, error, etc.)
  }),
  methods: {
    // formattedDate (value) {
    //    return this.$moment(value).format('DD/MM/YYYY')
    // }
  },
 async  created() {
    const valorArmazenado = localStorage.getItem('_useravgar');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);

    }
  },
}
</script>

<style>

</style>
