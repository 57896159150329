<template>
    <div>
        <!--Edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Clientes</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de Clientes</a>
                        </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#dadcdf">
                                    <v-tab value="dados" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Dados Cliente</v-tab>
                                    <div v-if="Cliente.cliId != 0">
                                        <v-tab value="endereco" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Endereço</v-tab>
                                    </div>
                                    <div v-if="Cliente.cliId != 0 && Cliente.tpdId == 1">
                                        <v-tab value="conjuge" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Conjuge</v-tab>
                                    </div>
                                    <div v-if="Cliente.cliId != 0 && Cliente.tpdId == 1">
                                        <v-tab value="corresponsavel" class="tabinfo" style="font-size: 13px;text-transform: none !important;">Corresponsável</v-tab>
                                    </div>
                                </v-tabs>
                                <v-window v-model="tab">
                                    <v-window-item value="dados">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Cliente.cliId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="User.garid == 0">
                                                        <div class="form-group">
                                                            <label>Garantidora</label>
                                                            <select  v-model="Cliente.garId" class="form-select form-control"
                                                            @change="BuscaImobiliarias()">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in ListGarantidorasGeral" :key="optionTipo.garId"
                                                                    :value="optionTipo.garId">{{ optionTipo.garNomeEmpresa }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="User.garid == 0 || User.tpeid == 1">
                                                        <div class="form-group">
                                                            <label>Imobiliaria</label>
                                                            <select  v-model="Cliente.imoId" class="form-select form-control">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in ListImobiliariasGeral" :key="optionTipo.imoId"
                                                                    :value="optionTipo.imoId">{{ optionTipo.imoNomeEmpresa }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Tipo Pessoa</label>
                                                            <select  v-model="Cliente.tpdId" class="form-select form-control">
                                                                <option :value=null>Selecione</option>
                                                                <option v-for="optionTipo in TiposDocumentos" :key="optionTipo.tpdId"
                                                                    :value="optionTipo.tpdId">{{ optionTipo.tpdNome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Nº {{ Cliente.tpdId == 1 ? 'CPF' : 'CNPJ'}}</label>
                                                            <input v-model="Cliente.cliDocumento" type="text" v-mask="documentoMask" 
                                                                class="form-control" :placeholder="Cliente.tpdId == 1 ? 'CPF' : 'CNPJ'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Nome</label>
                                                            <input v-model="Cliente.cliNomeEmpresa" type="text"
                                                                class="form-control" :placeholder="Cliente.tpdId == 2 ? 'Nome Fantasia' : 'Nome'" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>E-mail</label>
                                                            <input v-model="Cliente.cliEmail" type="text" class="form-control"
                                                                placeholder="E-mail" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Cliente.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Nome Responsável</label>
                                                            <input v-model="Cliente.cliResponsavel" type="text"
                                                                class="form-control" placeholder="Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Cliente.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>CPF Responsável</label>
                                                            <input v-model="Cliente.cliCampoExtra1" type="text" v-mask="'###.###.###-##'"
                                                                class="form-control" placeholder="CPF Responsável" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5" v-if="Cliente.tpdId == 2">
                                                        <div class="form-group">
                                                            <label>Razão Social</label>
                                                            <input v-model="Cliente.cliRazaoSocial" type="text"
                                                                class="form-control" placeholder="Razão Social" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Telefone</label>
                                                            <input v-model="Cliente.cliTelefone" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Telefone" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Whatsapp</label>
                                                            <input v-model="Cliente.cliWhatsapp" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Whatsapp" />
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-md-2 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Data Nascimento</label>
                                                            <input v-model="Cliente.cliData1" type="date" class="form-control"
                                                                placeholder="Data Nascimento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Cliente.tpdId == 1">
                                                        <div class="form-group">
                                                            <label>Nome do Pai</label>
                                                            <input v-model="Cliente.cliNomePai" type="text" class="form-control"
                                                                placeholder="Nome do Pai" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="Cliente.tpdId == 1">
                                                        <div class="form-group">
                                                            <label>Nome da Mãe</label>
                                                            <input v-model="Cliente.cliNomeMae" type="text" class="form-control"
                                                                placeholder="Nome da Mãe" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereço-->
                                    <v-window-item value="endereco">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Endereço alternativo para contato ou cobrança (não é endereço de contratação) *opcional</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Endereco.cleId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Cliente</label>
                                                            <input v-model="Endereco.cliId" type="text"
                                                                class="form-control" placeholder="Id Cliente" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>CEP</label>
                                                            <input v-model="Endereco.cleCep" v-mask="'#####-###'" type="text"
                                                                class="form-control" placeholder="CEP" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group" style="padding-top: 35px;">
                                                            <button type="button" class="btn btn-info" @click.prevent="Buscacep(Endereco.cleCep)" style="color:#ffffff;">
                                                                Verificar CEP
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Endereço</label>
                                                            <input v-model="Endereco.cleEndereco" type="text"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 col-lg-3" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Número</label>
                                                            <input v-model="Endereco.cleNumero" type="number"
                                                                class="form-control" placeholder="Endereço" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Complemento</label>
                                                            <input v-model="Endereco.cleComplemento" type="text"
                                                                class="form-control" placeholder="Complemento" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Estado</label>
                                                            <input v-model="Endereco.cleEstado" type="text"
                                                                class="form-control" placeholder="Estado" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Cidade</label>
                                                            <input v-model="Endereco.cleCidade" type="text"
                                                                class="form-control" placeholder="Cidade" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-if="showend">
                                                        <div class="form-group">
                                                            <label>Bairro</label>
                                                            <input v-model="Endereco.cleBairro" type="text"
                                                                class="form-control" placeholder="Bairro" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10" v-if="showend">
                                                        <div class="form-group">
                                                            <button v-if="Endereco.cleId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="EnderecoPut()">
                                                                Alterar Endereço
                                                            </button>
                                                            <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="EnderecoAdd()">
                                                                Salvar endereço
                                                            </button>
                                                            <a href="#" @click="EnderecoCancel()" class="btn btn-link">Cancelar Endereço</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--endereco-->
                                     <!--Conjuge-->
                                     <v-window-item value="conjuge">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Dados do Conjuge</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Cliente.cliId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Nome Conjuge</label>
                                                            <input v-model="Cliente.cliNomeConjuge" type="text"
                                                                class="form-control" placeholder="Nome" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>CPF Conjuge</label>
                                                            <input v-model="Cliente.cliCpfConjuge" v-mask="'###.###.###-##'" type="text"
                                                                class="form-control" placeholder="CPF" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Telefone Conjuge</label>
                                                            <input v-model="Cliente.cliTelefoneConjuge" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Telefone" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Whatsapp Conjuge</label>
                                                            <input v-model="Cliente.cliWhatsappConjuge" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Whatsapp" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Email Conjuge</label>
                                                            <input v-model="Cliente.cliEmailConjuge" type="text"
                                                                class="form-control" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Endereço completo: *(logradouro, número, bairro, cidade, estado e cep)</label>
                                                            <ckeditor :editor="editor" @ready="onReady" v-model="Cliente.cliTexto1" :config="$ckconfig"></ckeditor>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10" v-if="showend">
                                                        <div class="form-group">
                                                            <button v-if="Cliente.cliId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="Put()">
                                                                Alterar
                                                            </button>
                                                            <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--Conjuge-->
                                      <!--Corresponsavel-->
                                      <v-window-item value="corresponsavel">
                                        <v-form>
                                            <v-container>
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Dados do Corresponsável</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4" v-show="false">
                                                        <div class="form-group">
                                                            <label>Idenficador</label>
                                                            <input v-model="Cliente.cliId" type="text"
                                                                class="form-control" placeholder="Id" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Nome Corresponsável</label>
                                                            <input v-model="Cliente.cliNomeCorresponsavel" type="text"
                                                                class="form-control" placeholder="Nome" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>CPF Corresponsável</label>
                                                            <input v-model="Cliente.cliCpfCorresponsavel" v-mask="'###.###.###-##'" type="text"
                                                                class="form-control" placeholder="CPF" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Telefone Corresponsável</label>
                                                            <input v-model="Cliente.cliTelefoneCorresponsavel" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Telefone" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Whatsapp Corresponsável</label>
                                                            <input v-model="Cliente.cliWhatsappCorresponsavel" v-mask="'(##) ####-#####'" type="text"
                                                                class="form-control" placeholder="Whatsapp" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Email Corresponsável</label>
                                                            <input v-model="Cliente.cliEmailCorresponsavel" type="text"
                                                                class="form-control" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label>Endereço completo: *(logradouro, número, bairro, cidade, estado e cep)</label>
                                                            <ckeditor :editor="editor" @ready="onReady" v-model="Cliente.cliTexto2" :config="$ckconfig"></ckeditor>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 col-lg-10" v-if="showend">
                                                        <div class="form-group">
                                                            <button v-if="Cliente.cliId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                                                                @click="Put()">
                                                                Alterar
                                                            </button>
                                                            <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-container>
                                        </v-form>
                                    </v-window-item>
                                    <!--Conjuge-->
                                </v-window>
                            </div>
                        </div>
                    </div>
                    <div class="card-action" v-if="tab=='dados'">
                        <button v-if="Cliente.cliId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                            @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de Cliente</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="ListClientes"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-garId="item">
                                        <span> {{ item.garId !== null ? ListGarantidorasGeral.filter(x=> x.garId == item.garId)[0].garNomeEmpresa : null }}</span>
                                    </template>
                                    <template #item-imoId="item">
                                        <span>{{ ListImobiliariasGeral !='' ? ListImobiliariasGeral.filter(x=> x.imoId === item.imoId)[0].imoNomeEmpresa : null }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="client" location="top">Editar</v-tooltip>
                                        </v-btn>
                                        <v-btn icon @click="DeleteGrid(item)" variant="text" v-if="User!='' && User.garid == 0">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="client" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'ClientesView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        EditView: false,
        User:[],
        ListGarantidorasGeral:[],
        ListParceirosGeral:[],
        ListImobiliariasGeral:[],
        ListImobiliarias:[],
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Clientes',
        InfoTela:'Lista de Clientes',
        MostraGrid: true,
        headers: [],
        //dados
        Cliente: {
            cliId: 0,
            cliVisivel: null,
            cliDataCadastro: null,
            cliNomeEmpresa: '',
            cliRazaoSocial: '',
            tpdId: null,
            cliDocumento: '',
            cliTelefone: '',
            cliWhatsapp: '',
            cliResponsavel: '',
            cliEmail: '',
            cliAtivo: null,
            cliDataAniversario: null,
            cliCampoExtra1: '',
            cliCampoExtra2: '',
            cliCampoExtra3: '',
            cliCampoExtra4: '',
            cliCampoExtra5: '',
            cliTexto1: '',
            cliTexto2: '',
            cliTexto3: '',
            cliData1: null,
            cliData2: null,
            garId: null,
            imoId:null,
            cliNomePai:'',
            cliNomeMae: '',
            cliNomeConjuge: '',
            cliCpfConjuge: '',
            cliTelefoneConjuge: '',
            cliWhatsappConjuge: '',
            cliEmailConjuge: '',
            cliNomeCorresponsavel: '',
            cliCpfCorresponsavel: '',
            cliTelefoneCorresponsavel: '',
            cliWhatsappCorresponsavel: '',
            cliEmailCorresponsavel: '',
        },
        ListClientes: [],
        ListClientesGeral:[],
        TiposDocumentos:[],
        //dados
        //imagens
        selectedFile: null,
        //endereco
        Endereco: {
            cleId: 0,
            grtId: 0,
            cdtId: 0,
            cleEndereco: '',
            cleNumero: 0,
            cleComplemento: '',
            cleObsEndereco: '',
            cleCep: '',
            clePais: '',
            cleEstado: '',
            cleCidade: '',
            cleBairro: '',
            cliId: null
        },
        ListaEnderecos:[],
        showend:false,
        Retornocep:null,
        //endereco
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListClientes = this.ListClientesGeral;
        },
        //imagens
        //cliantidora
         //edit
        EditNew(){
        this.EditView = true;
        this.Cliente.cliId = 0;
        this.Cliente.cliVisivel= null;
        this.Cliente.cliDataCadastro= null;
        this.Cliente.cliNomeEmpresa= '';
        this.Cliente.cliRazaoSocial= '';
        this.Cliente.tpdId= null;
        this.Cliente.cliDocumento= '';
        this.Cliente.cliTelefone= '';
        this.Cliente.cliWhatsapp= '';
        this.Cliente.cliResponsavel= '';
        this.Cliente.cliEmail= '';
        this.Cliente.cliAtivo= null;
        this.Cliente.cliDataAniversario= null;
        this.Cliente.cliCampoExtra1= '';
        this.Cliente.cliCampoExtra2= '';
        this.Cliente.cliCampoExtra3= '';
        this.Cliente.cliCampoExtra4= '';
        this.Cliente.cliCampoExtra5= '';
        this.Cliente.cliTexto1= '';
        this.Cliente.cliTexto2= '';
        this.Cliente.cliTexto3= '';
        this.Cliente.cliData1= null;
        this.Cliente.cliData2= null;
        this.Cliente.garId= this.User.garid == 0 ? null : this.User.garid;
        this.Cliente.imoId= this.User.tpeid == 1 ? null : this.User.tpeid == 3 ? this.User.pesid : null;
        this.Cliente.cliNomePai = '';
        this.Cliente.cliNomeMae = '';
        this.Cliente.cliNomeConjuge = '';
        this.Cliente.cliCpfConjuge = '';
        this.Cliente.cliTelefoneConjuge = '';
        this.Cliente.cliWhatsappConjuge = '';
        this.Cliente.cliEmailConjuge = '';
        this.Cliente.cliNomeCorresponsavel = '';
        this.Cliente.cliCpfCorresponsavel = '';
        this.Cliente.cliTelefoneCorresponsavel = '';
        this.Cliente.cliWhatsappCorresponsavel = '';
        this.Cliente.cliEmailCorresponsavel = '';
        //endereco
        this.Endereco.cleId = 0;
        this.Endereco.grtId = 0;
        this.Endereco.cdtId = 0;
        this.Endereco.cleEndereco = '';
        this.Endereco.cleNumero = 0;
        this.Endereco.cleComplemento = '';
        this.Endereco.cleObsEndereco = '';
        this.Endereco.cleCep = '';
        this.Endereco.clePais = '';
        this.Endereco.cleEstado = '';
        this.Endereco.cleCidade = '';
        this.Endereco.cleBairro = '';
        this.showend = false;
        this.Retornocep = null;
        this.ListImobiliarias =[];
        this.ListImobiliariasGeral = [];
        this.tab = 'dados';
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        EditGrid(item) {
            this.EditView = true;
            this.tab = 'dados';
            console.log('item')
            console.log(item)
            if (item.cliId != '') {
                // Lógica clia editar o item
                 axios.get(`/Process/CliClientes/GetById/` + item.cliId) 
                    .then(response => {
                        this.Cliente = response.data;
                        if (this.Cliente.cliData1) {
                           this.Cliente.cliData1 = moment(this.Cliente.cliData1).format("YYYY-MM-DD");
                        }
                        //carrecli endereços
                        this.CarregaEndereco(item.cliId);

                        //carrega imobiliarias
                        this.BuscaImobiliarias();

                    });

              
            }
        },
        async Add() {
          
               if (this.Cliente.garId === '' || this.Cliente.garId === null) {
                    this.$mensagemAviso("Campo Garantidora Obrigatório.")
                }
                else if (this.Cliente.imoId == '' || this.Cliente.imoId == null) {
                    this.$mensagemAviso("Campo Imobiliária Obrigatório.")
                }
                else if (this.Cliente.tpdId === '' || this.Cliente.tpdId === null) {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
                }
                else if (this.Cliente.cliDocumento === '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
                else if (this.Cliente.cliNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
                else if (this.Cliente.cliTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Cliente.cliWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Cliente.cliData1 === '' || this.Cliente.cliData1 === null) {
                    this.$mensagemAviso("Campo Data Nascimento Obrigatório.")
                }
                else if (this.Cliente.cliEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Cliente.cliResponsavel === '' && this.Cliente.tpdId === 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Cliente.cliCampoExtra1 === '' && this.Cliente.tpdId === 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
                else if ((this.Cliente.cliNomePai === '' || this.Cliente.cliNomePai === null) && this.Cliente.tpdId === 1) {
                    this.$mensagemAviso("Campo Nome Pai Obrigatório.")
                }
                else if ((this.Cliente.cliNomeMae === '' || this.Cliente.cliNomeMae === null) && this.Cliente.tpdId === 1) {
                    this.$mensagemAviso("Campo Nome Mãe Obrigatório.")
                }
                else {
                    if (this.Cliente.cliVisivel === "" || this.Cliente.cliVisivel === null) {
                        this.Cliente.cliVisivel = true;
                    }
                    if (this.Cliente.cliAtivo === "" || this.Cliente.cliAtivo === null) {
                        this.Cliente.cliAtivo = true;
                    }
                    this.Cliente.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
                    axios.post(`/Process/CliClientes/Add`, this.Cliente)
                        .then(response => {
                            if (response.data > 0) {
                                this.Cliente.cliId = response.data;
                                this.CarregaEndereco(this.Cliente.cliId);
                                    this.$mensagemAvisoSucesso("Registro Incluído. abas clia informações adicionais liberadas.")
                            }
                        });
                
            }
        },
        Put() {
            console.log(this.Cliente)
            if (this.Cliente.garId === '' || this.Cliente.garId === null) {
                    this.$mensagemAviso("Campo Garantidora Obrigatório.")
                }
                else if (this.Cliente.imoId == '' || this.Cliente.imoId == null) {
                    this.$mensagemAviso("Campo Imobiliária Obrigatório.")
                }
                else if (this.Cliente.tpdId === '' || this.Cliente.tpdId === null) {
                    this.$mensagemAviso("Campo Tipo Pessoa Obrigatório.")
                }
                else if (this.Cliente.cliDocumento === '') {
                    this.$mensagemAviso("Campo Nº Documento Obrigatório.")
                }
                else if (this.Cliente.cliNomeEmpresa == '') {
                    this.$mensagemAviso("Campo Nome Obrigatório.")
                }
                else if (this.Cliente.cliTelefone === '') {
                    this.$mensagemAviso("Campo Telefone Obrigatório.")
                }
                else if (this.Cliente.cliWhatsapp === '') {
                    this.$mensagemAviso("Campo Whatsapp Obrigatório.")
                }
                else if (this.Cliente.cliData1 === '' || this.Cliente.cliData1 === null) {
                    this.$mensagemAviso("Campo Data Nascimento Obrigatório.")
                }
                else if (this.Cliente.cliEmail === '') {
                    this.$mensagemAviso("Campo E-mail Obrigatório.")
                }
                else if (this.Cliente.cliResponsavel === '' && this.Cliente.tpdId === 2) {
                    this.$mensagemAviso("Campo Responsável Obrigatório.")
                }
                else if (this.Cliente.cliCampoExtra1 === '' && this.Cliente.tpdId === 2) {
                    this.$mensagemAviso("Campo CPF Responsável Obrigatório.")
                }
                else if ((this.Cliente.cliNomePai === '' || this.Cliente.cliNomePai === null) && this.Cliente.tpdId === 1) {
                    this.$mensagemAviso("Campo Nome Pai Obrigatório.")
                }
                else if ((this.Cliente.cliNomeMae === '' || this.Cliente.cliNomeMae === null) && this.Cliente.tpdId === 1) {
                    this.$mensagemAviso("Campo Nome Mãe Obrigatório.")
                }
            else {
                if (this.Cliente.cliVisivel === "" || this.Cliente.cliVisivel === null) {
                        this.Cliente.cliVisivel = true;
                    }
                    if (this.Cliente.cliAtivo === "" || this.Cliente.cliAtivo === null) {
                        this.Cliente.cliAtivo = true;
                    }
             
                axios.put(`/Process/CliClientes/Update`, this.Cliente)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        //edit
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica clia excluir o item
                const cliam1 = item.cliId;
                axios.delete(`/Process/CliClientes/Delete/${cliam1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "cliId", sortable: true, });
            if (this.User.garid == 0){
                this.headers.push({ text: "Garantidora", value: "garId", sortable: true, });
            }
            if (this.User.tpeid != 3){
            this.headers.push({ text: "Imobiliaria", value: "imoId", sortable: true, });
            }
            this.headers.push({ text: "Nome", value: "cliNomeEmpresa", sortable: true, });
            this.headers.push({ text: "Documento", value: "cliDocumento", sortable: true, });
            this.headers.push({ text: "Whatsapp", value: "cliWhatsapp", sortable: true, });
            this.headers.push({ text: "Telefone", value: "cliTelefone", sortable: true, });
            this.headers.push({ text: "E-mail", value: "cliEmail", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        //cliantidora
        //endereco
        CarregaEndereco(_cliId){
            axios.get(`/Process/CliClientesEndereco/GetEnderecoByCli/` + _cliId) 
                .then(response => {
                    if (response.data.length > 0){
                        this.Endereco = response.data[0];
                        this.showend = true;
                    }
                    else{
                        this.Endereco.cliId = _cliId;
                    }                                             
                });
        },
        Buscacep(value) {
            if (value == ""){
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else{
                fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                    if (this.Retornocep != null) {
                        this.Endereco.cleEndereco = this.Retornocep.logradouro;
                        this.Endereco.cleNumero = null;
                        this.Endereco.cleComplemento = null;
                        this.Endereco.cleEstado = this.Retornocep.uf;
                        this.Endereco.cleCidade = this.Retornocep.localidade;
                        this.Endereco.cleBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        this.$mensagemAviso("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }
        },
        EnderecoAdd(){
            if (this.Endereco.cleCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.cleEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.cleNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.cleEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.cleCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.cleBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                        axios.post(`/Process/CliClientesEndereco/Add`, this.Endereco)
                             .then(response => {
                                if (response.data > 0) {
                                    this.Endereco.cleId = response.data;
                                    this.$mensagemAvisoSucesso("Endereço Incluído")
                                }
                            });
                }
        },
        EnderecoPut(){
            if (this.Endereco.cleCep == '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Endereco.cleEndereco === '') {
                    this.$mensagemAviso("Campo Endereço Obrigatório.")
                }
                else if (this.Endereco.cleNumero === '') {
                    this.$mensagemAviso("Campo Número Obrigatório.")
                }
                else if (this.Endereco.cleEstado === '') {
                    this.$mensagemAviso("Campo Estado Obrigatório.")
                }
                else if (this.Endereco.cleCidade === '') {
                    this.$mensagemAviso("Campo Cidade Obrigatório.")
                }
                else if (this.Endereco.cleBairro === '') {
                    this.$mensagemAviso("Campo Bairro Obrigatório.")
                }
                else {
                    axios.put(`/Process/CliClientesEndereco/Update`, this.Endereco)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Endereço Alterado.")
                        }
                    });
                }
        },
        EnderecoCancel(){
            /*this.Endereco.grtId = 0;
            this.Endereco.cdtId = 0;
            this.Endereco.gaeEndereco = '';
            this.Endereco.gaeNumero = null;
            this.Endereco.gaeComplemento = '';
            this.Endereco.gaeObsEndereco = '';
            this.Endereco.gaeCep = '';
            this.Endereco.gaePais = '';
            this.Endereco.gaeEstado = '';
            this.Endereco.gaeCidade = '';
            this.Endereco.gaeBairro = '';
            this.showend = false;*/
            this.$router.go(0);
        },
        //endereco
        BuscaImobiliarias(){
            axios.get(`/Process/ImoImobiliarias/GetByGarIdParId/` + this.Cliente.garId + '/0') 
                .then(response => {
                    if (response.data.length > 0){
                        this.ListImobiliariasGeral = response.data;
                        this.ListImobiliarias = this.ListImobiliariasGeral;
                    }                                            
                });
        },
        async Get() {
            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }
            //console.log('this.User')
            //console.log(this.User)

            if (this.User.garid == 0){ //garantepay
                this.EditView = false;
                await axios.get(`/Process/CliClientes/GetAll`)
                .then(response => {
                    this.ListClientesGeral = response.data.filter(x=> x.cliAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else if (this.User.tpeid == 1){ //garantidora
                this.EditView = false;
                await axios.get(`/Process/CliClientes/GetByGarIdImoId/` + this.User.garid + `/0`)
                .then(response => {
                    this.ListClientesGeral = response.data.filter(x=> x.cliAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }
            else if (this.User.tpeid == 3){ //imobiliaria
                await axios.get(`/Process/CliClientes/GetByGarIdImoId/0/` + this.User.pesid)
                .then(response => {
                    this.ListClientesGeral = response.data.filter(x=> x.cliAtivo);
                    this.CarregaLista();
                });
                this.montaGrid();
            }

            axios.get(`/Process/CadTipoDocumento/GetAll`) 
                .then(response => {
                    this.TiposDocumentos = response.data.filter(x=> x.tpdAtivo);                       
                });

                await  axios.get(`/Process/GarGarantidoras/GetAll`)
                .then(response => {
                    this.ListGarantidorasGeral = response.data.filter(x=> x.garAtivo);
                });

                await  axios.get(`/Process/ImoImobiliarias/GetAll`)
                .then(response => {
                    this.ListImobiliariasGeral = response.data.filter(x=> x.imoAtivo);
                });
        }
    },
    created() {
        this.Get();
    },
    computed: {
        documentoMask() {
        return this.Cliente.tpdId === 1 ? '###.###.###-##' : '##.###.###/####-##';
        }
   }
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
