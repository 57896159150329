<template>
    <div class="container" v-if="ListGarantidorasGeral!=''">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Contratações Vigentes</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="ListPedidosGeralFiltro"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-garId="item">
                                        <span> {{ item.garId !== null ? ListGarantidorasGeral.filter(x=> x.garId == item.garId)[0].garNomeEmpresa : null }}</span>
                                    </template>
                                    <template #item-pedDataPedido="item">
                                        <span> {{ item.pedDataPedido !== null ? formattedDate(item.pedDataPedido) : null }}</span>
                                    </template>
                                    <template #item-pedValorTotalPedido="item">
                                        {{ item.pedValorTotalPedido !== null ? formatarValor(item.pedValorTotalPedido) : 0 }}
                                    </template>
                                    <template #item-pedSituacaoPedido="item">
                                        <button v-if="item.pedSituacaoPedido=='Vigente'" class="btn btn-sucess" style="font-size: 12px;">{{ item.pedSituacaoPedido }}</button>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'

export default {
    name: 'ListaContratacoesVigentesView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        User:[],
        searchValue: '',
        NomeTela:'Contratações',
        InfoTela:'Contratações Vigentes',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 28, cdtNome: "Contratações Vigentes" }],
        Pedidos:[],
        ListPedidosGeral:[],
        ListPedidosGeralFiltro:[],
        ListGarantidorasGeral:[],
    }),
    methods: {
        SelecionaSituacao(value){
            if (value > 0){          
                this.ListPedidosGeralFiltro = this.ListPedidosGeral.filter(x=> x.pspId == value);
            }
            else{
                this.ListPedidosGeralFiltro = this.ListPedidosGeral;
            }
          
        },
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                proOrdem: parseInt(item.proOrdem),
            };
            });
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        EditGrid(item) {
            this.$router.push('/contratacaovigentedetalhe/' + item.pedId);
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "pedId", sortable: true, });
            this.headers.push({ text: "Data", value: "pedDataPedido", sortable: true, });
            this.headers.push({ text: "Garantidora", value: "garId", sortable: true, });
            this.headers.push({ text: "Imobiliaria", value: "imobNome", sortable: true, });
            this.headers.push({ text: "Cliente", value: "cliNome", sortable: true, });
            this.headers.push({ text: "Documento", value: "cliDocumento", sortable: true, });
            this.headers.push({ text: "Valor", value: "pedValorTotalPedido", sortable: true, });
            this.headers.push({ text: "Situação", value: "pedSituacaoPedido", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {

            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }

            axios.get(`/Process/GarGarantidoras/GetAll`)
                .then(response => {
                    this.ListGarantidorasGeral = response.data.filter(x=> x.garAtivo);
                });


            axios.get(`/Process/PedPedidos/GetAllValorTotal/` + this.User.garid + '/' + this.User.tpeid + '/' + this.User.pesid) 
                .then(response => {
                    this.ListPedidosGeral = response.data.filter(x=> x.pedSituacaoPedido == "Vigente");     
                    this.ListPedidosGeralFiltro = this.ListPedidosGeral.sort((a, b) => {
                                                                        const valorA = parseInt(a.pedId) || 0;
                                                                        const valorB = parseInt(b.pedId) || 0;
                                                                        return valorA - valorB;
                                                                    }).reverse();
                    
                     console.log('this.ListPedidosGeralFiltro')   
                     console.log(this.ListPedidosGeralFiltro)   
                });
            this.montaGrid();
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
