<template>
    <div>
    <!--Edit-->
    <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Cadastros</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de mensagens padrão</a> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="Mensagem.mpdId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Título</label>
                                            <input v-model="Mensagem.mpdNome"
                                                type="text" class="form-control" placeholder="Nome" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Ativo</label>
                                            <select v-model="Mensagem.mpdAtivo" class="form-select form-control"
                                                id="defaultSelect">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Garantidora</label>
                                            <select  v-model="Mensagem.garId" class="form-select form-control">
                                                <option value="">Selecione</option>
                                                <option v-for="optionTipo in Garantidoras" :key="optionTipo.tpeId"
                                                    :value="optionTipo.tpeId">{{ optionTipo.tpeNome }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Texto</label>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Mensagem.mpdTexto" :config="$ckconfig"></ckeditor>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label for="exampleFormControlFile1">Upload da Imagem</label>
                                            <v-file-input v-model="selectedFile" label="Imagem" variant="underlined"
                                                prepend-icon="mdi-account-circle" accept="image/*"></v-file-input>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2" v-if="Mensagem.mpdUrlImagem !== '' && Mensagem.mpdUrlImagem !== null">
                                        <div class="form-group">
                                            <img :src="$imgURL + Mensagem.mpdUrlImagem" style="width: 100px" />
                                            <v-btn icon density="compact" color="red" class="delete-icon"
                                                variant="text">
                                                <v-icon @click="DeleteImagem()"
                                                    style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="Mensagem.mpdId != 0" type="button" class="btn btn-success"
                            style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()"  class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de mensagens padrão</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="Mensagens" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-mpdAtivo="item">
                                        <span>{{ item.mpdAtivo ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-garId="item">
                                        <span>{{ item.garId != null ? Garantidoras.filter(x=> x.garId == item.garId)[0].garNomeEmpresa : '' }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>

                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
    name: 'CadastroMensagensPadraoView',
    components: {
        EasyDataTable,
    },
    data: () => ({
        EditView: false,
        editor: DecoupledEditor,
        Mensagem: {
            mpdId: 0,
            mpdNome: '',
            mpdTexto: '',
            mpdAtivo: '',
            mpdUrlImagem: '',
            garId: ''
        },
        Mensagens: [],
        StatusAtivo: [{ nome: "Sim", mpdAtivo: true }, { nome: "Não", mpdAtivo: false }],
        headers: [
            { text: "Id", value: "mpdId", sortable: true, },
            { text: "Nome", value: "mpdNome", sortable: true, },
            { text: "Ativo", value: "mpdAtivo", sortable: true, },
            { text: "Garantidora", value: "garId", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
        selectedFile: null,
        Garantidoras:[],
    }),
    methods: {
       onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
          //edit
          EditNew(){
            this.EditView = true;
            this.Mensagem.mpdId = 0;
            this.Mensagem.mpdNome = '';
            this.Mensagem.mpdTexto = '';
            this.Mensagem.mpdAtivo = '';
            this.Mensagem.mpdUrlImagem = '';
            this.Mensagem.garId = '';
            this.selectedFile = null;
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.mpdId != ''){
            axios.get(`/Process/CadMensagensPadrao/GetById/` + item.mpdId)
                .then(response => {
                    this.Mensagem = response.data;
                    this.Garantidoras = this.GarantidorasGeral.filter(x=> x.garId == this.Mensagem.garId)
                });
            }
            else{
                this.GarantidoraSelected();
            }
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async Add() {
            if (this.Mensagem.mpdNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Mensagem.mpdTexto == ''){
                this.$mensagemAviso("Texto Obrigatório.")
            }
            else {
                if (this.Mensagem.garId === "" || this.Mensagem.garId === null) {
                    this.Mensagem.garId = null;
                }
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Mensagem.mpdUrlImagem = uploadSuccess.data.nameFile;
                        if (this.Mensagem.mpdAtivo === "" || this.Mensagem.mpdAtivo === null){
                            this.Mensagem.mpdAtivo = true;
                        }
                        axios.post(`/Process/CadMensagensPadrao/Add`, this.Mensagem)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.Get();
                            }
                        });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.Mensagem.mpdUrlImagem = "";
                        if (this.Mensagem.mpdAtivo === "" || this.Mensagem.mpdAtivo === null){
                            this.Mensagem.mpdAtivo = true;
                        }
                        axios.post(`/Process/CadMensagensPadrao/Add`, this.Mensagem)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.Get();
                            }
                        });
                }
            }
        },
        async Put() {
            if (this.Mensagem.mpdNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Mensagem.mpdTexto == ''){
                this.$mensagemAviso("Texto Obrigatório.")
            }
            else {
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Mensagem.mpdUrlImagem = uploadSuccess.data.nameFile;
                        if (this.Mensagem.mpdAtivo === "" || this.Mensagem.mpdAtivo === null){
                            this.Mensagem.mpdAtivo = true;
                        }
                        axios.put(`/Process/CadMensagensPadrao/Update`, this.Mensagem)
                        .then(response => {
                            if (response.status == 200) {
                                this.$mensagemAvisoSucesso("Registro Alterado.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else{
                    if (this.Mensagem.mpdUrlImagem != "") {
                        this.Mensagem.mpdUrlImagem = "0";
                    }
                    if (this.Mensagem.mpdAtivo === "" || this.Mensagem.mpdAtivo === null){
                            this.Mensagem.mpdAtivo = true;
                    }
                    axios.put(`/Process/CadMensagensPadrao/Update`, this.Mensagem)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
                }              
            }
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        GarantidoraSelected(){
            this.Mensagem.garId = null;
            this.Garantidoras = this.GarantidorasGeral.filter(x=> x.garId == this.Mensagem.garId);
        },
        //edit
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.mpdId;
                axios.delete(`/Process/CadMensagensPadrao/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        DeleteImagem(){
            this.Mensagem.mpdUrlImagem = "";
        },
        Get() {
            this.EditView = false;
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.Mensagens = response.data;
                });

               /* garantidoras
                  axios.get(`/Process/UsrTipoPessoas/GetAll`)
                .then(response => {
                    this.TipoPessoas = response.data;
                });*/
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
