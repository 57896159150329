<template>
    <div>
        <!--Edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Admin</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de Telas</a> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="CadastroTela.cdtId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-lg-8">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input v-model="CadastroTela.cdtNome" :rules="nameRules" :counter="200"
                                                type="text" class="form-control" placeholder="Nome" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Ordem</label>
                                            <input v-model="CadastroTela.cdtOrdem" type="number" class="form-control"
                                                placeholder="Ordem" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Visivel</label>
                                            <select v-model="CadastroTela.cdtVisivel" class="form-select form-control"
                                                id="defaultSelect">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-lg-8">
                                        <div class="form-group">
                                            <label>URL</label>
                                            <input v-model="CadastroTela.cdtUrl" type="text" class="form-control"
                                                placeholder="www..." />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="GrupoTelas!=''">
                                        <div class="form-group">
                                            <label>Grupo de Telas</label>
                                            <select v-model="CadastroTela.admGrupoTelas.grtId" class="form-select form-control"
                                                id="defaultSelect">
                                                <option value="">Selecione</option>
                                                <option v-for="item in GrupoTelas" :key="item.grtId" :value="item.grtId">{{ item.grtNome }}</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="CadastroTela.cdtId != 0" type="button" class="btn btn-success"
                            style="color:#ffffff;" @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--Grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de telas</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="preprocessData(CadastroTelas)"
                                    v-model="itemsSelected" alternating theme-color="#364f66"
                                    table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina"
                                    :search-value="searchValue">
                                    <template #item-cdtVisivel="item">
                                        <span>{{ item.cdtVisivel ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>

                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';

export default {
    name: 'CadastroTelasView',
    components: {
        EasyDataTable
    },
    data: () => ({
        EditView: false,
        CadastroTela: {
            cdtId: 0,
            cdtNome: '',
            cdtOrdem: '',
            cdtVisivel: '',
            cdtUrl: '',
            admGrupoTelas: {grtId: ''}
        },
        CadastroTelas: [],
        GrupoTelas: [],
        selectedVisivel: null,
        selectedGrupo: null,
        nameRules: [
            value => {
                if (value?.length <= 200) return true
                return 'Nome pode conter até 200 caracteres.'
            },
        ],
        headers: [
            { text: "Id", value: "cdtId", sortable: true, },
            { text: "Nome", value: "cdtNome", sortable: true, },
            { text: "Ordem", value: "cdtOrdem", sortable: true, },
            { text: "Visivel", value: "cdtVisivel", sortable: true, },
            { text: "Url", value: "cdtUrl" },
            { text: "Grupo Id", value: 'admGrupoTelas.grtId' },
            { text: "Grupo", value: 'admGrupoTelas.grtNome', sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
                return {
                    ...item,
                    cdtOrdem: parseInt(item.cdtOrdem),
                };
            });
        },
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
                .then((result) => {
                    if (result.isConfirmed) {
                        // Lógica para excluir o item
                        const param1 = item.cdtId;
                        axios.delete(`/Process/AdmCadastroTelas/Delete/${param1}`)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Excluido.")
                                    this.Get();
                                }
                            });
                    }
                });
        },
        //edit
        EditNew(){
            this.EditView = true;
            this.CadastroTela.cdtId = 0;
            this.CadastroTela.cdtNome= '';
            this.CadastroTela.cdtOrdem= '';
            this.CadastroTela.cdtVisivel= '';
            this.CadastroTela.cdtUrl= '';
            this.CadastroTela.admGrupoTelas = {grtId: ''};
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.cdtId != ''){
            axios.get(`Process/AdmCadastroTelas/GetById/` + item.cdtId)
                .then(response => {
                    this.CadastroTela = response.data;
                    console.log(this.CadastroTela)
                });
            }
          
        },
        EditCancel(){
            //window.location.reload();
            this.$router.go(0);
        },
        Add() {
            if (this.CadastroTela.cdtNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.CadastroTela.cdtUrl == '') {
                this.$mensagemAviso("Url Obrigatória.")
            }
            else if (this.CadastroTela.cdtOrdem === '') {
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.CadastroTela.cdtVisivel === '') {
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.CadastroTela.admGrupoTelas.grtId == '' || this.CadastroTela.admGrupoTelas.grtId == null) {
                this.$mensagemAviso("Grupo Obrigatório.")
            }
            else {
                this.CadastroTela.cdtOrdem = parseInt(this.CadastroTela.cdtOrdem)
                axios.post(`Process/AdmCadastroTelas/Add`, this.CadastroTela)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.CadastroTela.cdtNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.CadastroTela.cdtUrl == '') {
                this.$mensagemAviso("Url Obrigatória.")
            }
            else if (this.CadastroTela.cdtOrdem === '') {
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.CadastroTela.cdtVisivel === '') {
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.CadastroTela.admGrupoTelas.grtId == '' || this.CadastroTela.admGrupoTelas.grtId == null) {
                this.$mensagemAviso("Grupo Obrigatório.")
            }
            else {
                this.CadastroTela.cdtOrdem = parseInt(this.CadastroTela.cdtOrdem)
                axios.put(`Process/AdmCadastroTelas/Update`, this.CadastroTela)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        //edit
        Get() {
            this.EditView = false;
            axios.get(`Process/AdmCadastroTelas/GetAll`)
                .then(response => {
                    this.CadastroTelas = response.data;
                });

                axios.get(`/Process/AdmGrupoTelas/GetAll`)
            .then(response => {
                this.GrupoTelas = response.data.filter(x => x.grtVisivel).sort((a, b) => {
                    const nomeA = a.grtNome || "";
                    const nomeB = b.grtNome || "";
                    return nomeA.localeCompare(nomeB);
                });
            });
        }
    },
    created() {
        this.Get();
      
    },
  
}
</script>

<style></style>
