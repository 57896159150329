
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/LoginView.vue'
import ResetSenha from '../views/ResetSenhaView.vue'
import Home from '../views/HomeView.vue'
import GrupoTelas from '../views/Adm/GrupoTelasView.vue'
import CadastroTelas from '../views/Adm/CadastroTelasView.vue'
import ControleSmtp from '../views/Adm/ControleSmtpView.vue'
import CadastroTipoUsuario from '../views/Adm/CadastroTipoUsuarioView.vue'
import CadastroTipoCliente from '../views/Adm/CadastroTipoClienteView.vue'
import CadastroUsuarios from '../views/Usuarios/CadastroUsuariosView.vue'
import ControlePermissoes from '../views/Usuarios/ControlePermissoesView.vue'
import ControlePermissoesPerfil from '../views/Usuarios/ControlePermissoesPerfilView.vue'
import CadastroTipoPessoas from '../views/Usuarios/CadastroTipoPessoasView.vue'
import CadastroTipoDocumento from '../views/Adm/CadastroTipoDocumentoView.vue'
import CadastroListaDocumento from '../views/Adm/CadastroListaDocumentoView.vue'
import CadastroTipoOperadora from '../views/Adm/CadastroTipoOperadoraView.vue'
import CadastroFormasPagamento from '../views/Adm/CadastroFormasPagamentoView.vue'
import CadastroMensagensPadrao from '../views/Cadastros/CadastroMensagensPadraoView.vue'
import CadastroDuvidasFrequentes from '../views/Adm/CadastroDuvidasFrequentesView.vue'
import CadastroPlanosGarantePay from '../views/Adm/CadastroPlanosGarantePayView.vue'
import CadastroModelosContratos from '../views/Adm/CadastroModelosContratosView.vue'
import CadastroDadosContrato from '../views/Adm/CadastroDadosContratoView.vue'
import SegmentosProd from '../views/Produtos/SegmentosProdView.vue'
import Produtos from '../views/Produtos/ProProdutosView.vue'
import Garantidoras from '../views/Garantidoras/GarantidorasView.vue'
import Parceiros from '../views/Parceiros/ParceirosView.vue'
import Imobiliarias from '../views/Imobiliarias/ImobiliariasView.vue'
import Clientes from '../views/Clientes/ClientesView.vue'



import ListaContratacoes from '../views/Pedidos/ListaContratacoesView.vue'
import ListaContratacoesVigentes from '../views/Pedidos/ListaContratacoesVigentesView.vue'
import ContratacaoDetalhe from '../views/Pedidos/ContratacaoDetalheView.vue'
import NovaContratacao from '../views/Pedidos/NovaContratacaoView.vue'
import ContratacoesNegadoCancelado from '../views/Pedidos/ContratacoesNegadoCanceladoView.vue'
import ContratacaoNegadaDetalhe from '../views/Pedidos/ContratacaoNegadaDetalheView.vue'
import ContratacaoVigenteDetalhe from '../views/Pedidos/ContratacaoVigenteDetalheView.vue'


const routes = [
  { path: '/', name: 'login', component: Login },
  { path: '/Home', name: 'Home', component: Home },
  { path: '/ResetSenha/:id/:key', name: 'ResetSenha', component: ResetSenha },
  { path: '/GrupoTelas/', name: 'GrupoTelas', component: GrupoTelas },
  { path: '/CadastroTelas', name: 'CadastroTelas', component: CadastroTelas },
  { path: '/ControleSmtp', name: 'ControleSmtp', component: ControleSmtp },
  { path: '/CadastroTipoUsuario', name: 'CadastroTipoUsuario', component: CadastroTipoUsuario },
  { path: '/CadastroTipoCliente', name: 'CadastroTipoCliente', component: CadastroTipoCliente },
  { path: '/CadastroUsuarios/:id?', name: 'CadastroUsuarios', component: CadastroUsuarios },
  { path: '/ControlePermissoes', name: 'ControlePermissoes', component: ControlePermissoes },
  { path: '/ControlePermissoesPerfil', name: 'ControlePermissoesPerfil', component: ControlePermissoesPerfil },
  { path: '/CadastroTipoPessoas', name: 'CadastroTipoPessoas', component: CadastroTipoPessoas },
  { path: '/CadastroTipoDocumento', name: 'CadastroTipoDocumento', component: CadastroTipoDocumento },
  { path: '/CadastroListaDocumento', name: 'CadastroListaDocumento', component: CadastroListaDocumento },
  { path: '/CadastroTipoOperadora', name: 'CadastroTipoOperadora', component: CadastroTipoOperadora },
  { path: '/CadastroFormasPagamento', name: 'CadastroFormasPagamento', component: CadastroFormasPagamento },
  { path: '/CadastroMensagensPadrao', name: 'CadastroMensagensPadrao', component: CadastroMensagensPadrao },
  { path: '/CadastroDuvidasFrequentes', name: 'CadastroDuvidasFrequentes', component: CadastroDuvidasFrequentes },
  { path: '/CadastroPlanosGarantePay', name: 'CadastroPlanosGarantePay', component: CadastroPlanosGarantePay },
  { path: '/CadastroModelosContratos', name: 'CadastroModelosContratos', component: CadastroModelosContratos },
  { path: '/CadastroDadosContrato', name: 'CadastroDadosContrato', component: CadastroDadosContrato },
  { path: '/SegmentosProdutos', name: 'SegmentosProd', component: SegmentosProd },
  { path: '/Produtos', name: 'Produtos', component: Produtos },
  { path: '/Garantidoras', name: 'Garantidoras', component: Garantidoras },
  { path: '/Parceiros', name: 'Parceiros', component: Parceiros },
  { path: '/Imobiliarias', name: 'Imobiliarias', component: Imobiliarias },
  { path: '/Clientes', name: 'Clientes', component: Clientes },


  
  { path: '/ListaContratacoes', name: 'ListaContratacoes', component: ListaContratacoes },
  { path: '/ListaContratacoesVigentes', name: 'ListaContratacoesVigentes', component: ListaContratacoesVigentes },
  { path: '/ContratacaoDetalhe/:idPed', name: 'ContratacaoDetalhe', component: ContratacaoDetalhe },
  { path: '/NovaContratacao', name: 'NovaContratacao', component: NovaContratacao },
  { path: '/ContratacoesNegadoCancelado', name: 'ContratacoesNegadoCancelado', component: ContratacoesNegadoCancelado },
  { path: '/ContratacaoNegadaDetalhe/:idPed', name: 'ContratacaoNegadaDetalhe', component: ContratacaoNegadaDetalhe },
  { path: '/ContratacaoVigenteDetalhe/:idPed', name: 'ContratacaoVigenteDetalhe', component: ContratacaoVigenteDetalhe },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
